import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import { ModuleHierarchy } from 'core.types/courses';
import { message } from 'antd';

export const useModuleHierarchyParam = () => useParams<{ course: string }>();

export const useModuleHierarchies = () => {
  const moduleParams = useModuleHierarchyParam();
  const [loading, setLoading] = useLoading();
  const [hierarchy, setHierarchy] = useState<ModuleHierarchy[]>([]);
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(`/courses/${moduleParams.course}/modules/herarcial`)
      .then((hierarchy) => {
        setHierarchy(hierarchy);
        return hierarchy;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  const save = () => {
    return apiInternal
      .put(`/courses/${moduleParams.course}/modules/herarcial`, hierarchy)
      .then((hierarchy) => {
        setHierarchy(hierarchy);
        message.success('Rearrange Module Success');
        return hierarchy;
      })
      .catch((e) => {
        message.error('Rearrange Module Failed');
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  return {
    hierarchy,
    loading,
    fetch,
    save,
    setHierarchy,
  };
};
