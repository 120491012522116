import { Row, Col } from 'antd';
import { useUser } from 'hooks/users';

import RoleRadioButton from './role.styled.radio.button';
import './role.styled.radio.button.scss';

const accountRoles = [
  { label: 'Student', value: 'student', description: 'Student descriptions' },
  { label: 'Mentor', value: 'mentor', description: 'Mentor descriptions' },
  {
    label: 'Instructor',
    value: 'instructor',
    description: 'Instructor descriptions',
  },
  { label: 'Speaker', value: 'speaker', description: 'Speaker descriptions' },
  { label: 'Admin', value: 'admin', description: 'Admin descriptions' },
];

const UserRoleOptions = () => {
  const { userRole, setUserRole } = useUser();
  const onRadioChange = ({ target: { value } }) => {
    setUserRole(value);
  };
  return (
    <Row>
      <Col span={24}>
        {accountRoles.map((d, i) => (
          <RoleRadioButton
            key={i}
            name={'role'}
            value={d.value}
            label={d.label}
            checked={d.value === userRole}
            onChange={onRadioChange}
            description={d.description}
          />
        ))}
      </Col>
    </Row>
  );
};

export default UserRoleOptions;
