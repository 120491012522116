import { Button, Card, Form } from 'antd';
import enumToArray from 'common/enumToArray';
import Input from 'components/input';
import NumberInput from 'components/input/number.input';
import Select from 'components/input/select';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Course,
  Module,
  ModuleHierarchy,
  ModuleType,
} from 'core.types/courses';
import { moduleTypeLabel, useModule } from '../../../hooks/module';
import { useCourse } from 'hooks/course';
import { useGlobalState } from 'context';
import RichTextEditor from 'components/input/ckeditor/ckeditor';
import { useModuleHierarchies } from 'hooks/module.hierarchy';

const ModuleDetail: FC = () => {
  const history = useHistory();
  const { course } = useCourse();
  const { hierarchy } = useModuleHierarchies();
  const { module, setModule, loading, save } = useModule();
  const [richEditorValues, setRichEditorValues] = useState<Partial<Module>>({
    description: '',
    overview: '',
    content: '',
  });
  const [, setCurrentStep] = useGlobalState('module.step', 0);
  const [newModuleSet, setNewModuleSet] = useState<Module[]>([]);
  const moduleChange = (v: any, pName: keyof Module) => {
    setModule({
      ...module,
      [pName]: v,
    });
  };
  const ckEditorValueChange = (v: any, pName: keyof Module) => {
    setRichEditorValues({ ...richEditorValues, [pName]: v });
  };
  const _save = async () => {
    const m = await save();
    setCurrentStep(1);
    if (m) {
      history.push(`/courses/${m.course.uuid}/modules/${m.uuid}/form`);
    }
  };

  const cancelButtonClick = () => {
    setCurrentStep(0);
    history.goBack();
  };

  const flattenChildModule = (
    mh: ModuleHierarchy[] = [],
    marker = ''
  ): Module[] => {
    if (mh === null) return [] as Module[];

    let data: Module[] = [];
    for (let i = 0; i < mh.length; i++) {
      const child = mh[i].sub_modules;
      const element = {
        ...mh[i],
        sub_modules: undefined,
        name: `${marker}${mh[i].name}`,
      };
      data.push(element);

      const c = flattenChildModule(child, `${marker}- `);
      data = data.concat(c);
    }
    return data as Module[];
  };

  useEffect(() => {
    setModule({ ...module, course: course as Course });
  }, [course]);

  useEffect(() => {
    const m = flattenChildModule(hierarchy);
    setNewModuleSet(m);
  }, [hierarchy]);

  /**
   * value handling of CKEDITOR component is little bit different
   * due to unrecognized react state bug
   */
  useEffect(() => {
    setModule({
      ...module,
      description: richEditorValues.description as string,
    });
  }, [richEditorValues.description]);
  useEffect(() => {
    setModule({ ...module, overview: richEditorValues.overview });
  }, [richEditorValues.overview]);
  useEffect(() => {
    setModule({ ...module, content: richEditorValues.content as string });
  }, [richEditorValues.content]);

  return (
    <Card loading={loading} title={`Module ${course.name} Course Add Module`}>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Name`}
          value={module.name}
          onChange={(v) => moduleChange(v, 'name')}
        />
        <RichTextEditor
          label={`Description`}
          value={module.description}
          onChange={(v) => ckEditorValueChange(v, 'description')}
        />
        <RichTextEditor
          label={`Overview`}
          value={module.overview}
          onChange={(v) => ckEditorValueChange(v, 'overview')}
        />
        <RichTextEditor
          label={`Content`}
          value={module.content}
          onChange={(v) => ckEditorValueChange(v, 'content')}
        />
        <NumberInput
          label={`Weight`}
          value={module.weight}
          onChange={(v) => moduleChange(v, 'weight')}
        />
        <Select
          items={newModuleSet}
          value={module.parent_module}
          onChange={(v) => moduleChange(v, 'parent_module')}
          itemKeyFind={`uuid`}
          renderItem={(v) => v.name?.toUpperCase() as string}
          label={`Parent Module`}
        />
        <Select
          onChange={(v) => moduleChange(v, 'type')}
          label={`Type`}
          items={enumToArray(ModuleType) as any as ModuleType[]}
          value={module.type}
          renderItem={(item: ModuleType) => moduleTypeLabel[item]}
        />
        <NumberInput
          label={`Duration`}
          value={module.duration}
          onChange={(v) => moduleChange(v, 'duration')}
        />
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Next
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
          Cancel
        </Button>
      </Form>
    </Card>
  );
};

export default ModuleDetail;
