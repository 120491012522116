import { Challenge } from 'core.types/challenge';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setLoading,
  setChallenge,
  setChallenges,
} from 'store/reducers/challenge';

export const useChallengeStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.challenge);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setChallenge: (challenge: Challenge) => dispatch(setChallenge(challenge)),
    setChallenges: (challenges: Challenge[]) =>
      dispatch(setChallenges(challenges)),
  };

  return { ...states, action };
};
