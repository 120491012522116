import { Link, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Breadcrumb } from 'antd';
import { FC, useEffect, useState } from 'react';
import { routeList } from 'routes';
export const PageBreadcrumb: FC = () => {
  const location = useLocation();
  const [pathSnippets, setPathSnippets] = useState<any>([]);

  useEffect(() => {
    const d = routeList
      .map(({ path, name }) => {
        const m = matchPath(location.pathname, {
          path: path,
          strict: false,
          exact: false,
        });
        return { ...m, name };
      })
      .filter((d) => d.path)
      .sort((a, b) => {
        if (!a.path) return 0;
        if (!b.path) return 0;
        return a.path.length > b.path.length
          ? 1
          : b.path.length > a.path.length
          ? -1
          : 0;
      });
    setPathSnippets(d);
  }, [location]);

  const extraBreadcrumbItems = pathSnippets.map(({ path, name, url }) => {
    return (
      <Breadcrumb.Item key={path}>
        <Link to={url}>{name}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <div style={{ margin: 16 }}>
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </div>
  );
};

export default PageBreadcrumb;
