import { Card, Button, Form } from 'antd';
import enumToArray from 'common/enumToArray';
import { useHistory } from 'react-router';
import Input from 'components/input';
import RichTextEditor from 'components/input/ckeditor/ckeditor';
import { useEffect, useState } from 'react';
import Select from 'components/input/select';
import { useChallenge } from 'hooks/challenge';
import {
  ChallengeStatus,
  challengeStatusLabel,
  Challenge,
} from 'core.types/challenge';

export const ChallengeForm = () => {
  const history = useHistory();
  const { challenge, setChallenge, save } = useChallenge();
  const [richEditorValues, setRichEditorValues] = useState<Partial<Challenge>>({
    description: '',
  });
  const challengeStatus = () => {
    return enumToArray(ChallengeStatus) as any as ChallengeStatus[];
  };
  const challengeChange = (v: any, pName: keyof Challenge) => {
    setChallenge({
      ...challenge,
      [pName]: v,
    });
  };
  const ckEditorValueChange = (v: any, pName: keyof Challenge) => {
    setRichEditorValues({ ...richEditorValues, [pName]: v });
  };

  useEffect(() => {
    setChallenge({
      ...challenge,
      description: richEditorValues.description as string,
    });
  }, [richEditorValues.description]);

  const _save = async () => {
    const m = await save();
    if (m) {
      history.push(`/challenges`);
    }
  };

  return (
    <>
      <Card title={`Challenge Detail`}>
        <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Input
            label="Name"
            value={challenge.name}
            onChange={(c) => challengeChange(c, 'name')}
          />
          <RichTextEditor
            label={`Description`}
            value={challenge.description}
            onChange={(c) => ckEditorValueChange(c, 'description')}
          />
          {/* <Input
            label="Description"
            value={challenge.description}
            onChange={(c) => challengeChange(c, 'description')}
          /> */}
          <Select
            label={`Status`}
            items={challengeStatus()}
            onChange={(v) => challengeChange(v, 'status')}
            value={challenge.status}
            renderItem={(item: ChallengeStatus) => challengeStatusLabel[item]}
          />
          <Button type="primary" onClick={_save}>
            Save
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default ChallengeForm;
