import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Pagination, Table } from 'antd';
import Fullname from 'components/user/fullname';
import { Link } from 'react-router-dom';
import { useWebinars } from '../../hooks/course';
import { useHistory } from 'react-router';
import { CourseStatus, CourseType } from 'core.types/courses';
const courseColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (course: string) => course.split('-').join(' ').toUpperCase(),
  },
  {
    title: 'Tutor',
    dataIndex: 'tutor',
    key: 'tutor',
    render: (tutor: string) => <Fullname username={tutor} />,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (type: CourseType) => type,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CourseStatus) => status,
  },
  {
    title: 'Action',
    dataIndex: 'uuid',
    key: 'uuid',
    render: (uuid: string) => (
      <>
        <Link to={`/webinars/${uuid}/form`}>Detail</Link>
      </>
    ),
  },
];

export const WebinarList = () => {
  const {
    webinars,
    pagination: { page, size },
    loading,
    setPagination,
  } = useWebinars();
  const history = useHistory();

  const addCourseButtonClick = () => {
    history.push(`/webinars/new/form`);
  };

  return (
    <>
      <Card
        title={`Webinar List`}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addCourseButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table
          columns={courseColumns}
          dataSource={webinars.data}
          pagination={false}
        />
        <Pagination
          onChange={(page) => {
            setPagination({
              page,
              size,
            });
          }}
          pageSize={size}
          current={page}
          total={webinars.pagination.total_size}
          showSizeChanger
          onShowSizeChange={(_current, _size) => {
            setPagination({
              size: _size,
              page,
            });
          }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSizeOptions={['10']}
        />
      </Card>
    </>
  );
};

export default WebinarList;
