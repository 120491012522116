import { FC } from 'react';
import { Layout } from 'antd';
const { Content } = Layout;
const FullLayout: FC = ({ children }) => (
  <Layout style={{ height: '100vh' }}>
    <Content>{children}</Content>
  </Layout>
);

export default FullLayout;
