import { useEffect } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { WebinarCategory } from 'core.types/webinar.category';
import { useWebinarCategoryStore } from 'store/hooks/webinar.category';

export const initialWebinarCategoryData: WebinarCategory = {
  name: '',
};

export const useWebinarCategories = () => {
  const {
    webinarCategories,
    action: { setWebinarCategories },
  } = useWebinarCategoryStore();
  const [loading, setLoading] = useLoading();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get('/webinar-category')
      .then((_b: WebinarCategory[]) => {
        setWebinarCategories(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetch();
  }, []);
  return { webinarCategories, loading, fetch };
};

export const useWebinarCategory = () => {
  const {
    webinarCategory,
    action: { setWebinarCategory },
  } = useWebinarCategoryStore();
  const [loading, setLoading] = useLoading();
  const get = (id: string) => {
    setLoading(true);
    return apiInternal
      .get(`/webinar-category/${id}`)
      .then((_b: WebinarCategory) => {
        setWebinarCategory(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = () => {
    setLoading(true);
    const req = webinarCategory.id
      ? apiInternal.put(
          `/webinar-category/${webinarCategory.id}`,
          webinarCategory
        )
      : apiInternal.post(`/webinar-category`, webinarCategory);

    return req
      .then((_b: WebinarCategory) => {
        setWebinarCategory(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const remove = (id: string) => {
    setLoading(true);
    return apiInternal
      .delete(`/webinar-category/${id}`)
      .then((_b: WebinarCategory) => {
        setWebinarCategory(_b);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      })
      .finally(() => setLoading(false));
  };
  return { webinarCategory, loading, get, save, setWebinarCategory, remove };
};
