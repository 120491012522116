import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Pagination, Table } from 'antd';
import Fullname from 'components/user/fullname';
import { Link } from 'react-router-dom';
import { CourseStatus, CourseType } from '../../../../src/core/courses/course';
import { useCourses } from '../../hooks/course';
import { useHistory } from 'react-router';
import CourseAtHome from './components/courses.at.home';
const courseColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (course: string) => course.split('-').join(' ').toUpperCase(),
  },
  {
    title: 'Tutor',
    dataIndex: 'tutor',
    key: 'tutor',
    render: (tutor: string) => <Fullname username={tutor} />,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (type: CourseType) => type,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: CourseStatus) => status,
  },
  {
    title: 'Action',
    dataIndex: 'uuid',
    key: 'uuid',
    render: (uuid: string) => (
      <>
        <Link to={`/courses/${uuid}/form`}>Detail</Link>
        <Link to={`/courses/${uuid}/modules`} style={{ marginLeft: 10 }}>
          Modules
        </Link>
        <Link to={`/courses/${uuid}/students`} style={{ marginLeft: 10 }}>
          Subscribed Students
        </Link>
        <Link to={`/courses/${uuid}/certificates`} style={{ marginLeft: 10 }}>
          Certificates
        </Link>
      </>
    ),
  },
];

export const CourseList = () => {
  const {
    courses,
    pagination: { page, size },
    loading,
    setPagination,
  } = useCourses();
  const history = useHistory();

  const addCourseButtonClick = () => {
    history.push(`/courses/new/form`);
  };

  return (
    <>
      <Card
        title={`Course List`}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addCourseButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table
          columns={courseColumns}
          dataSource={courses.data}
          pagination={false}
        />
        <Pagination
          onChange={(page) => {
            setPagination({
              page,
              size,
            });
          }}
          pageSize={size}
          current={page}
          total={courses.pagination.total_size}
          showSizeChanger
          onShowSizeChange={(_current, _size) => {
            setPagination({
              size: _size,
              page,
            });
          }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSizeOptions={['10']}
        />
      </Card>
      <CourseAtHome />
    </>
  );
};

export default CourseList;
