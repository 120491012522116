import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Challenge, ChallengeStatus } from 'core.types/challenge';

export const challengeInitialData: Challenge = {
  name: '',
  description: '',
  status: ChallengeStatus.INACTIVE,
};
export const challenges: Challenge[] = [];

export interface ChallengeStoreIface {
  loading: boolean;
  challenge: Challenge;
  challenges: Challenge[];
}

const initialState: ChallengeStoreIface = {
  loading: false,
  challenge: {} as Challenge,
  challenges: [] as Challenge[],
};

export const slice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setChallenge: (state, action: PayloadAction<Challenge>) => {
      state.challenge = action.payload;
    },
    setChallenges: (state, action: PayloadAction<Challenge[]>) => {
      state.challenges = action.payload;
    },
  },
});

export const { setLoading, setChallenge, setChallenges } = slice.actions;

export default slice.reducer;
