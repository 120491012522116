import { Avatar } from 'antd';
import { Profile } from 'core.types/identity';
import { FC } from 'react';

interface CustomAvatarInterface {
  user: Profile;
}

export const CustomAvatar: FC<CustomAvatarInterface> = ({ user }) => {
  const avatar =
    user && user.avatar ? (
      <Avatar
        size="small"
        src={user.avatar}
        alt={user.fullname}
        className="avatar"
      />
    ) : (
      <Avatar>{user && user.fullname}</Avatar>
    );

  return (
    <div>
      {user && user.fullname} {avatar}
    </div>
  );
};
