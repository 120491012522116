import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Package } from 'core.types/billing';

export const packageInitialData: Package = {
  name: '',
  quota: 0,
  price: 0,
  benefits: [],
  duration: 0,
};
export const packages: Package[] = [];

export interface CouponStoreIface {
  loading: boolean;
  pkg: Package;
  packages: Package[];
}

const initialState: CouponStoreIface = {
  loading: false,
  pkg: packageInitialData,
  packages: packages,
};

export const slice = createSlice({
  name: 'couponn',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPackage: (state, action: PayloadAction<Package>) => {
      state.pkg = action.payload;
    },
    setPackages: (state, action: PayloadAction<Package[]>) => {
      state.packages = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setPackage, setPackages } = slice.actions;

export default slice.reducer;
