import { useGeneratedCertificate } from 'hooks/generated.certificate';
import Modal from 'antd/lib/modal/Modal';
import { useGeneratedCertificateStore } from 'store/hooks/generated.certificate';
import { Image } from 'antd';

export const CertificateResultModal = () => {
  const { certificate } = useGeneratedCertificate();
  const {
    modalCertificateOpen,
    action: { setModalCertificateOpen },
  } = useGeneratedCertificateStore();

  const handleOk = async () => {
    setModalCertificateOpen(false);
  };
  const handleCancel = async () => {
    setModalCertificateOpen(false);
  };

  return (
    <Modal
      title="Preview Certificate"
      visible={modalCertificateOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
    >
      <Image
        width={955}
        src={`/assets/files/certificate/${certificate.uuid}`}
      />
    </Modal>
  );
};

export default CertificateResultModal;
