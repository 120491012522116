import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, message, Pagination, Popconfirm, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { initialBootcampData, useBootcamp, useBootcamps } from 'hooks/bootcamp';
import { Bootcamp } from 'core.types/bootcamp';
import BootcampAtHome from './components/bootcamp.at.home';

export const BootcampList = () => {
  const history = useHistory();
  const {
    bootcamps,
    setPagination,
    pagination: { page, size },
    loading,
    fetch,
  } = useBootcamps();
  const { bootcamp, setBootcamp, remove } = useBootcamp();

  const addCourseButtonClick = () => {
    history.push(`/bootcamp/new/form`);
  };

  const bootcampColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (course: string) => course.split('-').join(' ').toUpperCase(),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => description,
    },
    {
      title: 'Action',
      render: (bootcamp: Bootcamp) => (
        <>
          <Link to={`/bootcamp/${bootcamp.id}/form`}>Detail</Link>
          <Link
            to={`/courses/${bootcamp.uuid}/modules`}
            style={{ marginLeft: 10 }}
          >
            All Modules
          </Link>
          <Link
            to={`/courses/${bootcamp.uuid}/module-hierarchy`}
            style={{ marginLeft: 10 }}
          >
            Hierarchy
          </Link>
          <Link
            to={`/courses/${bootcamp.uuid}/certificates`}
            style={{ marginLeft: 10 }}
          >
            Certificates
          </Link>
          <Popconfirm
            title={`Are you sure to delete bootcamp ${bootcamp.name.toUpperCase()} ?`}
            onConfirm={onDeleteConfirm}
            onCancel={onCancelDeleteConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => setBootcamp(bootcamp)}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const onDeleteConfirm = async () => {
    const r = await remove();
    if (r) {
      message.success(`${bootcamp.name} successfully removed!`);
      setBootcamp(initialBootcampData);
      fetch();
    }
  };

  const onCancelDeleteConfirm = () => {
    setBootcamp(initialBootcampData);
  };

  return (
    <>
      <Card
        title={`Bootcamp List`}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addCourseButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table
          columns={bootcampColumns}
          dataSource={bootcamps.data}
          pagination={false}
        />
        <Pagination
          onChange={(page) => {
            setPagination({
              page,
              size,
            });
          }}
          pageSize={1000}
          current={page}
          total={bootcamps.pagination ? bootcamps.pagination.total_size : 0}
          showSizeChanger
          onShowSizeChange={(_current, _size) => {
            setPagination({
              size: _size,
              page,
            });
          }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSizeOptions={['1000']}
        />
      </Card>
      <BootcampAtHome />
    </>
  );
};

export default BootcampList;
