import { FC } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
export interface RouteWithLayoutProps {
  component: FC<RouteComponentProps>;
  layout: FC;
  path: string | string[];
}
const RouteWithLayout: FC<RouteWithLayoutProps> = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          <Layout>
            <Component {...matchProps} />
          </Layout>
        </>
      )}
    />
  );
};

export default RouteWithLayout;
