import {
  Col,
  Form,
  Modal,
  Row,
  Checkbox,
  Input as AntdInput,
  Button,
} from 'antd';
import Input from 'components/input';
import { CheckBox } from 'components/input/check.box';
import NumberInput from 'components/input/number.input';
import { Package } from 'core.types/billing';
import { usePackage, usePackages } from 'hooks/package';
import { usePackageBenefits } from 'hooks/package.benefit';
import { FC } from 'react';
import { packageInitialData } from 'store/reducers/package';

interface PackageModalFormProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const PackageBanefitFormModal: FC<PackageModalFormProps> = ({
  visible,
  setVisible,
}) => {
  const { packageBenefits } = usePackageBenefits();
  const { save, setPackage, pkg, loading } = usePackage();
  const { fetch } = usePackages();

  const handleOk = async () => {
    const s = await save();
    if (s) {
      fetch();
      setVisible(false);
    }
  };
  const handleCancel = () => {
    setPackage(packageInitialData);
    setVisible(false);
  };
  const onFieldChanges = (v: any, pName: keyof Package) => {
    setPackage({ ...pkg, [pName]: v });
  };
  const onCheckboxChanged = (v) => {
    const p = packageBenefits.filter((d) => v.includes(d.uuid));
    onFieldChanges(p, 'benefits');
  };
  const onComparisonValueChanged = (v, benefitUUID) => {
    const idx = pkg.benefits.findIndex((d) => d.uuid === benefitUUID);
    const benefits = pkg.benefits;
    if (idx !== undefined && benefits[idx]) {
      benefits[idx].comparison_value = v;
      onFieldChanges(benefits, 'benefits');
    }
  };
  const benefitComparison = (benefitUUID) => {
    const idx = pkg.benefits.findIndex((d) => d.uuid === benefitUUID);
    const benefits = pkg.benefits;
    console.log(benefits[idx]);
    console.log(benefits[idx] && benefits[idx].comparison_value);
    return benefits[idx] ? benefits[idx].comparison_value : '';
  };

  return (
    <Modal
      title="Create Package"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={640}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
    >
      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
        <Input
          label={`Name`}
          value={pkg.name}
          onChange={(v) => onFieldChanges(v, 'name')}
        />
        <Input
          label={`Subtitle`}
          value={pkg.extras?.subtitle}
          onChange={(v) =>
            onFieldChanges({ ...pkg.extras, subtitle: v }, 'extras')
          }
        />
        <NumberInput
          label={`Quota`}
          value={pkg.quota}
          onChange={(v) => onFieldChanges(v, 'quota')}
        />
        <NumberInput
          label={`Price`}
          value={pkg.price}
          onChange={(v) => onFieldChanges(v, 'price')}
        />
        <NumberInput
          label={`Duration`}
          value={pkg.duration}
          onChange={(v) => onFieldChanges(v, 'duration')}
        />
        <CheckBox
          label={`Contact Only`}
          value={pkg.extras?.contact_only}
          onChange={(v) =>
            onFieldChanges({ ...pkg.extras, contact_only: v }, 'extras')
          }
        />
        {pkg.extras?.contact_only && (
          <Input
            label={`Contact Number`}
            value={pkg.extras?.contact_number}
            onChange={(v) =>
              onFieldChanges({ ...pkg.extras, contact_number: v }, 'extras')
            }
          />
        )}
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(v) => onCheckboxChanged(v)}
          value={pkg.benefits ? pkg.benefits.map((d) => d.uuid || '') : []}
        >
          <Row>
            <Col xl={6} style={{ textAlign: 'right', paddingRight: 10 }}>
              Benefits:
            </Col>
            <Col xl={28}>
              {packageBenefits.map((d) => (
                <Row style={{ marginBottom: 15 }}>
                  <Col xl={16}>
                    <Checkbox value={d.uuid}>{d.benefit}</Checkbox>
                  </Col>
                  <Col xl={8}>
                    <AntdInput
                      placeholder="comparison value"
                      value={benefitComparison(d.uuid)}
                      onChange={({ target: { value } }) =>
                        onComparisonValueChanged(value, d.uuid)
                      }
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Checkbox.Group>
      </Form>
    </Modal>
  );
};

export default PackageBanefitFormModal;
