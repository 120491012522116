import { message } from 'antd';
import { Quiz } from 'core.types/courses';
import { Student } from 'core.types/enrollment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { apiInternal } from 'service/api.internal';
import { useQuiz } from './quiz';

export const useStudentParam = () =>
  useParams<{
    course: string;
    student: string;
    module: string;
    quiz: string;
    question: string;
  }>();

export interface SessionStudent extends Student {
  session: string;
}

export const useStudent = () => {
  const { course } = useStudentParam();
  const [student, setStudent] = useState<SessionStudent>();
  const [session, setSession] = useState<string>();
  const [loading, setLoading] = useState<boolean>();

  const saveSession = () => {
    setLoading(true);
    const data = { session: session };
    return apiInternal
      .put(`/students/${student?.username}/courses/${course}/sessions`, data)
      .then((d) => {
        message.success('Student Session Updated');
        return d;
      })
      .catch((e) => {
        message.error('Student Session Failed to Updated');
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  return {
    student,
    session,
    setStudent,
    setSession,
    saveSession,
    loading,
  };
};

export const useStudents = () => {
  const studentParams = useStudentParam();
  const [students, setStudents] = useState<SessionStudent[]>();
  const [loading, setLoading] = useState<boolean>();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(`/courses/${studentParams.course}/students`)
      .then((_students) => {
        setStudents(_students);
        return _students;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);

  return { loading, students, setStudents, fetch };
};

export const useStudentCourseModules = () => {
  const { student, course } = useStudentParam();
  const [loading, setLoading] = useState<boolean>();
  const [modules, setModules] = useState([]);

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(`/students/${student}/courses/${course}/modules`)
      .then((_modules) => {
        setModules(_modules);
        return _modules;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);

  return { modules, loading };
};

export const useStudentAnswers = () => {
  const { student, course, module } = useStudentParam();
  const [loading, setLoading] = useState<boolean>();
  const [answer, setAnswer] = useState<Quiz>();
  const { quiz: quizHook } = useQuiz();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/students/${student}/courses/${course}/modules/${module}/quizes/${quizHook.uuid}/result`
      )
      .then((_quiz) => {
        setAnswer(_quiz);
        return _quiz;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    if (quizHook?.uuid !== '') {
      fetch();
    }
  }, [quizHook]);

  return { answer, loading };
};
