import { Button, Row, Col } from 'antd';
import Input, {
  TextArea,
  CurrencyInput as InputNumber,
} from 'components/input';
import { CheckBox } from 'components/input/check.box';
import { Price } from 'core.types/bootcamp';
import { FC, useEffect, useState } from 'react';

export interface BootcampPricesProps {
  value: any;
  onChange?: (v) => void;
}

export const BootcampPrices: FC<BootcampPricesProps> = ({
  value,
  onChange,
}) => {
  const [prices, setPrices] = useState<Price[]>([]);
  const onFieldChange = (v: any, pName: keyof Price, i: number) => {
    const newArr = [...prices];
    const m = { ...newArr[i], [pName]: v };
    newArr[i] = m;
    setPrices(newArr);
  };

  useEffect(() => {
    onChange && onChange(prices);
  }, [prices]);

  useEffect(() => {
    setPrices(value);
  }, []);
  /**
   * => prices -> array of object { description, highlighted, name, price }
   */

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={4} style={{ display: 'flex' }}>
          <span style={{ marginLeft: 'auto', marginRight: 10 }}>Prices:</span>
        </Col>
        <Col span={20} style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              setPrices([
                ...prices,
                {
                  name: '',
                  price: 0,
                  origin_price: 0,
                  highlighted: false,
                  description: '',
                },
              ]);
            }}
          >
            Add Price
          </Button>
        </Col>
      </Row>
      {prices.map((value, i) => {
        return (
          <div key={i}>
            <TextArea
              label={`Description`}
              value={value.description}
              onChange={(v) => onFieldChange(v, 'description', i)}
            />
            <Input
              label={`Name`}
              value={value.name}
              onChange={(v) => onFieldChange(v, 'name', i)}
            />
            <InputNumber
              label={`Price`}
              value={value.price}
              onChange={(v) => onFieldChange(v, 'price', i)}
            />
            <InputNumber
              label={`Origin Price`}
              value={value.origin_price}
              onChange={(v) => onFieldChange(v, 'origin_price', i)}
            />
            <CheckBox
              label="Highlighted"
              value={value.highlighted}
              onChange={(v) => onFieldChange(v, 'highlighted', i)}
            />
            <Row>
              <Col offset={4} span={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      const m = [...prices];
                      m.splice(i, 1);
                      setPrices(m);
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default BootcampPrices;
