import qs from 'qs';
import { useEffect, useState } from 'react';
import { Paginated } from '../../../src/core/core.types';
import { Profile, User, UserParam } from '../../../src/core/identity';
import { apiInternal } from '../service/api.internal';
import { useUser } from './auth';

import { useLoading, usePageSize } from './common';

export const useProfile = (username?: string) => {
  const user = useUser();
  const [profile, setProfile] = useState<Profile>({} as Profile);
  const [loading, setLoading] = useLoading();
  const getProfile = () => {
    return apiInternal
      .get(`/users/${username || user.username}/profile`)
      .then((profile: Profile) => {
        setProfile(profile);
        return profile;
      })
      .catch((e) => {
        console.log(e);
        return null;
      })
      .finally(() => setLoading(false));
  };

  const update = async () => {
    setLoading(true);
    return apiInternal
      .put(`/users/:${user.username}/profile`)
      .then((_identity: Profile) => {
        setProfile(_identity);
        return _identity;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getProfile();
  }, []);
  return { update, loading, profile, setProfile };
};

export const useIdentities = () => {
  const [user, setUser] = useState<Paginated<User>>({
    data: [],
    pagination: {
      page: 1,
      size: 0,
      total_page: 0,
      total_size: 0,
    },
  });
  const [{ page, size }, setPageSize] = usePageSize();
  const [loading, setLoading] = useLoading();
  const [param, setParams] = useState<UserParam>({
    email: '',
    info: '',
    username: '',
  });
  const fetch = async (_param?: UserParam) => {
    setLoading(true);
    return apiInternal
      .get(`/users?${qs.stringify(_param || param)}`)
      .then((_users: Paginated<User>) => {
        setUser(_users);
        return _users;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetch();
  }, []);
  return {
    user,
    param,
    setParams,
    page,
    size,
    setPageSize,
    fetch,
    loading,
  };
};
