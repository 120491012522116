import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm, Table } from 'antd';
import {
  initialWebinarCategoryData,
  useWebinarCategory,
  useWebinarCategories,
} from 'hooks/webinar.category';
import WebinarCategoryFormModal from './components/modal.form';
import { useState } from 'react';

export const WebinarCategoryList = () => {
  const { webinarCategories, loading, fetch } = useWebinarCategories();
  const { webinarCategory, get, remove, setWebinarCategory } =
    useWebinarCategory();
  const [modalVisible, setModalVisible] = useState(false);

  const webinarCategoryColumns = [
    {
      title: 'Webinar Category',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => (
        <>
          <Button onClick={() => onDetailClicked(id)}>Detail</Button>
          <Popconfirm
            title={`Are you sure to delete category?`}
            onConfirm={onDeleteCertificateConfirm}
            onCancel={onCancelDeleteCertificateConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onModuleDeletePrompt(id)}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const addWebinarCategoryButtonClick = () => {
    setWebinarCategory(initialWebinarCategoryData);
    setModalVisible(true);
  };

  const onDetailClicked = (categoryUUID: string) => {
    get(categoryUUID);
    setModalVisible(true);
  };
  const onModuleDeletePrompt = (categoryUUID: string) => {
    get(categoryUUID);
  };

  const onDeleteCertificateConfirm = async () => {
    const r = await remove(webinarCategory?.id as string);
    if (r) {
      message.success(`Webinar Category successfully removed!`);
      setWebinarCategory(initialWebinarCategoryData);
      fetch();
    }
  };

  const onCancelDeleteCertificateConfirm = () => {
    setWebinarCategory(initialWebinarCategoryData);
  };

  return (
    <>
      <Card
        title={`Webinar Category List`}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addWebinarCategoryButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table
          columns={webinarCategoryColumns}
          dataSource={webinarCategories}
          pagination={false}
        />
      </Card>
      <WebinarCategoryFormModal
        visible={modalVisible}
        setVisible={(v) => setModalVisible(v)}
      />
    </>
  );
};

export default WebinarCategoryList;
