import { Button, Card, Pagination, Table } from 'antd';
import { ManualCertificate } from 'core.types/certificate';
import {
  useGeneratedCertificate,
  useGeneratedCertificates,
} from 'hooks/generated.certificate';
import CertificateResultModal from './certificate.result';
import CertificateOwnerForm from './owner.form';

export const GeneratedCertificateList = () => {
  const {
    certificates,
    loading,
    pagination: { page, size },
    setPagination,
  } = useGeneratedCertificates();
  const { setCertificate, setModalOwnerOpen, setModalCertificateOpen } =
    useGeneratedCertificate();

  const handleGetOwner = (c: ManualCertificate) => {
    setCertificate(c);
    setModalOwnerOpen(true);
  };

  const showGeneratedCertificate = (c: ManualCertificate) => {
    setCertificate(c);
    setModalCertificateOpen(true);
  };

  const columns = [
    {
      title: 'UUID',
      render: (cert: ManualCertificate) => (
        <>
          <Button onClick={() => showGeneratedCertificate(cert)} type="link">
            {cert.uuid}
          </Button>
        </>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: 'Issuer Role',
      render: (cert: Partial<ManualCertificate>) => cert.data?.issuer_name,
    },
    {
      title: 'Issued Certificate Name',
      render: (cert: Partial<ManualCertificate>) =>
        cert.data?.issued_certificate_name,
    },
    {
      title: 'Action',
      render: (cert: ManualCertificate) => (
        <>
          <Button onClick={() => handleGetOwner(cert)}>Update Owner</Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Card title={`Generated Certificate List`} loading={loading}>
        <Table
          columns={columns}
          dataSource={certificates.data}
          pagination={false}
        />
        <Pagination
          onChange={(page) => {
            setPagination({
              page,
              size,
            });
          }}
          pageSize={size}
          current={page}
          total={certificates.pagination.total_size}
          showSizeChanger
          onShowSizeChange={(_current, _size) => {
            setPagination({
              size: _size,
              page,
            });
          }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSizeOptions={['10']}
        />
      </Card>
      <CertificateOwnerForm />
      <CertificateResultModal />
    </>
  );
};

export default GeneratedCertificateList;
