import { Card, Typography, Button, Row, Col, Upload } from 'antd';
import { CheckBox } from 'components/input/check.box';
import NumberInput from 'components/input/number.input';
import { Answer } from 'core.types/enrollment';
import { useStudentParam } from 'hooks/student';
import { useStudentResultAnswers } from 'hooks/student.result';
import { v4 as uuidv4 } from 'uuid';
const { Title } = Typography;

export const StudentAnswer = () => {
  const { answers, setAnswer, savePoint, loading } = useStudentResultAnswers();
  const studentParam = useStudentParam();

  const onFieldChange = (v: any, pName: keyof Answer, i: number) => {
    const newArr = [...answers];
    const m = { ...newArr[i], [pName]: v };
    newArr[i] = m;
    setAnswer(newArr);
  };

  const StudentAnswer = ({ data }: { data: Answer }) => {
    if (data.question?.type === 'file') {
      return (
        <Upload
          listType="text"
          beforeUpload={() => false}
          defaultFileList={[
            {
              uid: uuidv4(),
              name: data.answer,
              status: 'done',
              url: `/assets/files/enrollment/${studentParam.student}/courses/${studentParam.course}/modules/${studentParam.module}/quizes/${studentParam.quiz}/questions/${data.question.uuid}/${data.answer}`,
            },
          ]}
        ></Upload>
      );
    } else {
      return <p>{data.answer}</p>;
    }
  };

  return (
    <>
      <Card
        title={`${studentParam.student}'s Answer`}
        style={{ marginBottom: 20 }}
      >
        {answers &&
          answers.map((d, i: any) => {
            return (
              <div style={{ marginBottom: 30 }} key={i}>
                <Row>
                  <Col xl={20}>
                    <Title level={4}>
                      {i + 1}. {d.question?.question}
                    </Title>
                    <Title level={5}>Answer:</Title>
                    <StudentAnswer data={d} />
                  </Col>
                  <Col xl={4}>
                    {d.question?.type !== 'multiple_choise' && (
                      <>
                        <CheckBox
                          label="Is Answer True"
                          value={d.is_true}
                          onChange={(v) => onFieldChange(v, 'is_true', i)}
                        />
                        <CheckBox
                          label="Set Checked"
                          value={d.checked}
                          onChange={(v) => onFieldChange(v, 'checked', i)}
                        />
                        <NumberInput
                          label={`Answer Points`}
                          value={d.point * 100}
                          onChange={(v) => {
                            onFieldChange(v / 100, 'point', i);
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            width: '100%',
                            marginBottom: 20,
                          }}
                        >
                          <Button
                            type="primary"
                            onClick={() => savePoint(i)}
                            loading={loading}
                          >
                            Save
                          </Button>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            );
          })}
      </Card>
    </>
  );
};

export default StudentAnswer;
