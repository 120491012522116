import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { Indicator } from '../../../src/core/achievement/achievement';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import { useModuleParam } from './module';
import to from 'await-to-js';
import { message } from 'antd';
import * as UUID from 'uuid';
import delay from 'common/delay';

export const useIndicatorParam = () => useParams<{ indicator: string }>();

export const useIndicators = () => {
  const indicatorList: Indicator[] = [
    { name: 'Bussiness', points: 0, uuid: UUID.v4() },
    { name: 'Graphic Design', points: 0, uuid: UUID.v4() },
    { name: 'Back-end', points: 0, uuid: UUID.v4() },
    { name: 'Front-end', points: 0, uuid: UUID.v4() },
    { name: 'User Experience', points: 0, uuid: UUID.v4() },
    { name: 'User Interface', points: 0, uuid: UUID.v4() },
    { name: 'Softskills', points: 0, uuid: UUID.v4() },
  ];
  const [indicators, setIndicators] = useState<Indicator[]>(indicatorList);
  const [loading, setLoading] = useLoading();
  const { module } = useModuleParam();
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/modules/${module}/indicators`)
      .then((_indicators: Indicator[]) => {
        if (_indicators.length > 0) setIndicators(_indicators);
        return _indicators;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = async () => {
    setLoading(true);
    try {
      for (const indicator of indicators) {
        const [e] = await to(
          apiInternal.put(
            `/modules/${module}/indicators/${indicator.uuid}`,
            indicator
          )
        );
        if (e) {
          const m = `Error while insert ${indicator.name}`;
          message.error(m);
          throw new Error(m);
        }
        delay(200);
      }
      setLoading(false);
      return indicatorList;
    } catch (e) {
      console.log(e);
      setLoading(false);
      return undefined;
    }
  };
  useEffect(() => {
    get();
  }, []);
  return { indicatorList, indicators, loading, save, setIndicators };
};
