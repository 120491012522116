import { useEffect, useState } from 'react';
import { All, useLoading, usePageSize } from './common';
import qs from 'qs';
import { apiInternal } from '../service/api.internal';
import { useParams, useHistory } from 'react-router-dom';
import { Paginated } from '../../../src/core/core.types';
import { Course } from 'core.types/courses';
import { message } from 'antd';
import { useCourseStore } from 'store/hooks/course';

export enum CourseStatus {
  WAITING = 0,
  PUBLISHED = 1,
  RETURNED = 2,
  DEACTIVATED = 3,
}

export enum CourseType {
  VIDEO_COURSE = 'video_course',
  BOOTCAMP = 'bootcamp',
  WEBINAR = 'webinar',
}

export const useCourseParam = () => useParams<{ course: string }>();
export type CourseTypeParam = CourseType | All;
export type CourseStatusParam = CourseStatus | All;

export const courseStatusLabel = {
  [CourseStatus.DEACTIVATED]: 'Deactivated',
  [CourseStatus.PUBLISHED]: 'Published',
  [CourseStatus.RETURNED]: 'Returned',
  [CourseStatus.WAITING]: 'Waiting',
};
export const useCourses = () => {
  const [loading, setLoading] = useLoading();
  const [pagination, setPagination] = usePageSize();
  const { page, size } = pagination;
  const {
    courses,
    action: { setCourses },
  } = useCourseStore();
  const [fParam, setParam] = useState<{
    tutor?: string;
    status?: CourseStatusParam;
    type?: CourseTypeParam;
    keyword?: string | undefined;
  }>({
    tutor: '',
    type: CourseType.VIDEO_COURSE,
    status: All.ALL,
    keyword: undefined,
  });
  const fetch = () => {
    setLoading(true);
    const p = {
      page,
      size,
      tutor: fParam.tutor,
      status: fParam.status,
      type: fParam.type,
      keyword: fParam.keyword,
    };
    return apiInternal
      .get(`/courses?${qs.stringify(p)}`)
      .then((_courses: Paginated<Course>) => {
        setCourses(_courses);
        return _courses;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    fetch();
  }, [pagination, fParam]);
  return {
    fParam,
    courses,
    loading,
    fetch,
    pagination: {
      page,
      size,
    },
    setPagination,
    setParam,
  };
};

export const useWebinars = () => {
  const [loading, setLoading] = useLoading();
  const [pagination, setPagination] = usePageSize();
  const { page, size } = pagination;
  const [fParam, setParam] = useState<{
    tutor?: string;
    status?: CourseStatusParam;
    type?: CourseTypeParam;
    keyword?: string | undefined;
  }>({
    tutor: '',
    type: CourseType.WEBINAR,
    status: All.ALL,
    keyword: undefined,
  });
  const {
    webinars,
    action: { setWebinars },
  } = useCourseStore();
  const fetch = () => {
    setLoading(true);
    const p = {
      page,
      size,
      tutor: fParam.tutor,
      status: fParam.status,
      type: fParam.type,
      keyword: fParam.keyword,
    };
    return apiInternal
      .get(`/courses?${qs.stringify(p)}`)
      .then((_courses: Paginated<Course>) => {
        setWebinars(_courses);
        return _courses;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    fetch();
  }, [pagination, fParam]);
  return {
    fParam,
    webinars,
    loading,
    fetch,
    pagination: {
      page,
      size,
    },
    setPagination,
    setParam,
  };
};

export const useCourse = () => {
  const {
    course,
    action: { setCourse },
  } = useCourseStore();
  const [coverData, setCoverData] = useState<any>();
  const [loading, setLoading] = useLoading();
  const params = useCourseParam();
  const history = useHistory();

  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/courses/${params.course}`)
      .then((_courses: Course) => {
        setCourse(_courses);
        return _courses;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = (): Promise<Course | undefined> => {
    // get course type from url
    const courseType = history.location.pathname.split('/')[1];
    const courseBody: Course = {
      ...course,
      type:
        courseType === 'webinars'
          ? CourseType.WEBINAR
          : CourseType.VIDEO_COURSE,
    };
    console.log(courseBody);
    setLoading(true);

    const fn =
      params.course === 'new'
        ? apiInternal.post(`/tutor/${course.tutor}/courses`, courseBody)
        : apiInternal.put(
            `/tutor/${course.tutor}/courses/${params.course}`,
            course
          );
    console.log(courseBody);
    return fn
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  const saveCover = (): Promise<Course | undefined> => {
    if (!coverData) {
      // if the cover data is not set / user not choose image
      return new Promise((resolve) => {
        resolve({} as Course);
      });
    }
    setLoading(true);
    message.info('Uploading Cover');

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('image', coverData);

    const fn = apiInternal.put(
      `/tutor/${course.tutor}/courses/${params.course}/cover`,
      formData
    );
    return fn
      .then(() => {
        message.success('Cover Uploaded');
        return {} as Course;
      })
      .catch((e) => {
        console.log(e);
        message.error('Cover Upload Failed');
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return {
    course,
    loading,
    get,
    save,
    setLoading,
    setCourse,
    setCoverData,
    saveCover,
  };
};
