/* eslint-disable camelcase */
import { WebinarCategory } from 'core.types/webinar.category';
import { Level } from '../careers';
import { General, Paginated } from '../core.types';

export enum CourseStatus {
  WAITING = 0,
  PUBLISHED = 1,
  RETURNED = 2,
  DEACTIVATED = 3,
}

export enum CourseType {
  VIDEO_COURSE = 'video_course',
  BOOTCAMP = 'bootcamp',
  WEBINAR = 'webinar',
}

export interface CourseCareer {
  level: Level;
  number: number;
}

export interface Extras {
  price: number;
  active_price: number;
  [p: string]: any;
}

export interface Course extends General {
  tutor: string;
  open?: Date;
  close?: Date;
  number?: number;
  status: CourseStatus;
  cover?: string;
  extras: any;
  career: CourseCareer[];
  webinar_categories?: WebinarCategory[];
  sessions?: string[];
  type: CourseType;
  start_date?: Date;
  end_date?: Date;
}
export interface Metadata {
  issuer_role: string;
  title: string;
  event_label: string;
  event_link: string;
  start_date: Date;
  end_date: Date;
}

export interface CourseQueryParam {
  tutor?: string;
  level?: string;
  page?: number;
  size?: number;
  career?: string;
  keyword?: string;
  type?: CourseType;
  isPopular?: boolean;
  pricing?: 'paid' | 'free';
  isRelated?: {
    uuid: string;
  };
  category?: string;
  sortBy?: string;
  sortOrder?: string;
}

export interface CareerCourse {
  level?: Level;
  courses?: Paginated<Course>;
}

export default Course;
