import { useParams } from 'react-router-dom';
import { apiInternal } from 'service/api.internal';
import { useChallengeStore } from 'store/hooks/challange';
import { challengeInitialData } from 'store/reducers/challenge';
import { Challenge } from '../../../src/core/challenge';

export const useChalangeParam = () =>
  useParams<{
    challenge: string;
  }>();

export const useChallenges = () => {
  const {
    loading,
    challenges,
    action: { setChallenges, setLoading },
  } = useChallengeStore();
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/challenges`)
      .then((_challenges: Challenge[]) => {
        setChallenges(_challenges);
        return _challenges;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  return { setChallenges, challenges, setLoading, loading, get };
};

export const useChallenge = () => {
  const {
    loading,
    challenge,
    action: { setChallenge, setLoading },
  } = useChallengeStore();
  const { challenge: ch } = useChalangeParam();
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/challenges/name/${ch}`)
      .then((_challenge: Challenge) => {
        setChallenge(_challenge);
        return _challenge;
      })
      .catch((e) => {
        setChallenge(challengeInitialData);
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = () => {
    setLoading(true);
    // const name = challenge.name;
    return apiInternal
      .post(`/challenges`, challenge)
      .then((_challenge: Challenge) => {
        setChallenge(_challenge);
        return _challenge;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  return { setChallenge, challenge, setLoading, loading, get, save };
};
