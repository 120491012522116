import { FC, useEffect } from 'react';
import { Layout } from 'antd';
import Sider from './sider';
import Navigation from './navigation';
import { useUser } from 'hooks/auth';
import { useHistory } from 'react-router';
import PageBreadcrumb from 'components/page/breadcrumb';
const { Content } = Layout;
const DashboardLayout: FC = ({ children }) => {
  const user = useUser();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, []);

  return (
    <Layout>
      <Navigation />
      <Layout>
        <Sider />
        <Layout>
          <PageBreadcrumb />
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
