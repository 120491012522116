import { Package } from 'core.types/billing';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLoading, setPackage, setPackages } from 'store/reducers/package';

export const usePackageStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.pkg);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setPackage: (pkg: Package) => dispatch(setPackage(pkg)),
    setPackages: (pkgs: Package[]) => dispatch(setPackages(pkgs)),
  };

  return { ...states, action };
};
