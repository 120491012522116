import { Module, Question, Quiz } from 'core.types/courses';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { apiInternal } from 'service/api.internal';
import { useLoading } from './common';
import { useCourse } from './course';

export const useQuizParam = () =>
  useParams<{ course: string; module: string }>();

export const useQuiz = () => {
  const { course } = useCourse();
  const quizparams = useQuizParam();
  const [loading, setLoading] = useLoading();
  const [quiz, setQuiz] = useState<Quiz>({
    name: '',
    description: '',
    duration: 0,
    max_attempt: 0,
    min_weight: 0,
    number: 0,
    questions: [] as Question[],
    open: undefined,
    module: <Module>{},
  });
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/tutor/${course.tutor}/courses/${quizparams.course}/modules/${quizparams.module}/quizes`
      )
      .then((_quiz) => {
        setQuiz(_quiz);
        return _quiz;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const save = () => {
    setLoading(true);

    const fn =
      quiz.uuid !== undefined
        ? apiInternal.put(
            `/tutor/${course.tutor}/courses/${quizparams.course}/modules/${quizparams.module}/quizes`,
            quiz
          )
        : apiInternal.post(
            `/tutor/${course.tutor}/courses/${quizparams.course}/modules/${quizparams.module}/quizes`,
            quiz
          );

    return fn
      .then((d) => {
        setQuiz(d);
        return d;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    if (course.tutor !== '') {
      fetch();
    }
  }, [course]);

  return {
    save,
    quiz,
    loading,
    fetch,
    setQuiz,
  };
};
