import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Upload, Button } from 'antd';
import enumToArray from 'common/enumToArray';
import toTitleCase from 'common/toTitleCase';
import Input, { TextArea } from 'components/input';
import { SocialType, User } from 'core.types/identity';
import { useUser, useUsers } from 'hooks/users';
import { useEffect, useState } from 'react';

const initialUniqueCredentials = {
  username: '',
  email: '',
};

const UserInfoForm = () => {
  const [form] = Form.useForm();
  const {
    user,
    setUser,
    loading,
    credentialsCheck,
    save,
    modalOpen,
    setModalOpen,
  } = useUser();
  const { fetch } = useUsers();
  const [uniqueCredentials, setUniqueCredentials] = useState(
    initialUniqueCredentials
  );
  const [firstUnique, setFirstUnique] = useState(false);
  const [avatarChange, setAvatarChange] = useState(false);
  useEffect(
    () =>
      form.setFieldsValue({
        name: user?.info.fullname,
        username: user?.username,
        email: user?.info.email,
        phone_number: user?.info.phone_number,
      }),
    [form, user]
  );
  useEffect(() => {
    if (!firstUnique) {
      setUniqueCredentials({
        username: user?.username,
        email: user?.info.email,
      });
      setFirstUnique(true);
    }
    if (!modalOpen) {
      setUniqueCredentials(initialUniqueCredentials);
      setFirstUnique(false);
    }
  }, [user]);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const onFieldChange = (v: any, pName: keyof User) => {
    setUser({
      ...user,
      [pName]: v,
    });
  };

  const handleChange = ({ file }) => {
    if (file.status === 'uploading') {
      setAvatarChange(false);
    } else if (file.status === 'done') {
      setAvatarChange(true);
    }
  };

  const onFinish = async () => {
    const s = await save();
    if (s) {
      setModalOpen(false);
      fetch();
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          name={`user_form`}
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            name: user?.info.fullname,
            username: user?.username,
            email: user?.info.email,
            phone_number: user?.info.phone_number,
          }}
        >
          <Form.Item label={`Avatar`}>
            <Upload
              name={'file'}
              action={`/api/v1/users/${user?.username}/avatar`}
              listType="picture-card"
              method={'POST'}
              openFileDialogOnClick={true}
              showUploadList={false}
              multiple={false}
              maxCount={1}
              onChange={handleChange}
            >
              {user?.info.avatar ? (
                <img
                  src={`${user?.info.avatar}?t=${
                    avatarChange ? Date.now() : ''
                  }`}
                  alt="avatar"
                  style={{ width: '100%' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Input
            value={user?.info.fullname}
            label={`Full Name`}
            placeholder={`Full Name`}
            name={`name`}
            onChange={(v) =>
              onFieldChange({ ...user.info, fullname: v }, 'info')
            }
            rules={[{ required: true, message: 'Please input name!' }]}
          />
          <Input
            value={user?.username}
            label={`Username`}
            placeholder={`Username`}
            name={`username`}
            onChange={async (v) => {
              onFieldChange(v, 'username');
            }}
            rules={[
              { required: true, message: 'Please input username!' },
              {
                async validator(_, value) {
                  const isExists = await credentialsCheck('username', value);
                  if (!isExists) {
                    return Promise.resolve();
                  } else if (isExists && value === uniqueCredentials.username) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`Username exists for ${value}`)
                  );
                },
              },
            ]}
          />
          <Input
            value={user?.info.email}
            label={`Email`}
            placeholder={`Email`}
            name={`email`}
            onChange={async (v) => {
              onFieldChange({ ...user.info, email: v }, 'info');
            }}
            rules={[
              { required: true, message: 'Please input email!' },
              {
                async validator(_, value) {
                  const isExists = await credentialsCheck('email', value);

                  if (!isExists) {
                    return Promise.resolve();
                  } else if (isExists && value === uniqueCredentials.email) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`Email already used!`));
                },
              },
            ]}
          />
          <Input
            value={user?.info.phone_number}
            label={`Phone Number`}
            placeholder={`Phone Number`}
            name={`phone_number`}
            onChange={(v) =>
              onFieldChange({ ...user.info, phone_number: v }, 'info')
            }
            rules={[{ required: true, message: 'Please input Phone Number!' }]}
          />
          <TextArea
            value={user?.info.job}
            label={`Job`}
            placeholder={`Job`}
            onChange={(v) => onFieldChange({ ...user.info, job: v }, 'info')}
          />
          {enumToArray(SocialType)
            .filter(
              // show social except google and facebook
              (s) => s.toString() !== 'google' && s.toString() !== 'facebook'
            )
            .map((s, i) => (
              <Input
                key={i}
                value={user.info.social && user.info.social[s.toString()]}
                label={`${toTitleCase(s.toString())} Link`}
                placeholder={`${toTitleCase(s.toString())} Account Link`}
                onChange={(v) => {
                  onFieldChange(
                    {
                      ...user.info,
                      social: { ...user.info.social, [s.toString()]: v },
                    },
                    'info'
                  );
                }}
              />
            ))}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default UserInfoForm;
