import { Card, Button, Form } from 'antd';
import enumToArray from 'common/enumToArray';
import Input from 'components/input';
import Datepicker from 'components/input/date.time.picker';
import NumberInput from 'components/input/number.input';
import Select from 'components/input/select';
import { useCoupon } from 'hooks/coupon';
import CourseAutocomplete from './components/course.autocomplete';
import { useHistory } from 'react-router';
import { Coupon, CouponType, couponTypeLabel } from '../../core.types/coupon';
import UserAutocomplete from './components/user.autocomplete';

export const CouponForm = () => {
  const history = useHistory();
  const { coupon, setCoupon, save } = useCoupon();
  const onFieldChanged = (v: any, pName: keyof Coupon) => {
    setCoupon({
      ...coupon,
      [pName]: v,
    });
  };

  const _save = async () => {
    const c = await save();
    if (c) {
      history.push(`/coupons/${c.code}/form`);
    }
  };

  return (
    <>
      <Card title={`Coupon Detail`} loading={false}>
        <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Input
            label="Code"
            value={coupon?.code}
            onChange={(v) => onFieldChanged(v, 'code')}
          />
          <NumberInput
            label="Quota"
            value={coupon?.quota}
            onChange={(v) => onFieldChanged(v, 'quota')}
          />
          <NumberInput
            label={`Value`}
            value={coupon?.value}
            onChange={(v) => onFieldChanged(v, 'value')}
          />
          <Select
            onChange={(v) => onFieldChanged(v, 'type')}
            label={`Type`}
            items={enumToArray(CouponType) as any as CouponType[]}
            value={coupon?.type}
            renderItem={(item: CouponType) => couponTypeLabel[item]}
          />
          <Datepicker
            label="Expired"
            value={coupon?.expired}
            onChange={(v) => onFieldChanged(v, 'expired')}
          />
          <UserAutocomplete
            label={`Coupon For Users`}
            value={coupon?.conditions?.username}
            onChange={(v) =>
              onFieldChanged(
                { ...coupon.conditions, username: v },
                'conditions'
              )
            }
          />
          <CourseAutocomplete
            label={`Coupon For Course`}
            value={coupon?.conditions?.course}
            onChange={(v) =>
              onFieldChanged({ ...coupon.conditions, course: v }, 'conditions')
            }
          />
          <Button type="primary" onClick={() => _save()} loading={false}>
            Save
          </Button>
        </Form>
      </Card>
    </>
  );
};

export default CouponForm;
