/* eslint-disable camelcase */
export interface Address {
  locality: string;
  region: string;
  postal_code: string;
  country: string;
}
export interface Education {
  is_graduated?: boolean;
  grade?: string;
  school_name?: string;
  majors?: string;
}
export interface Job {
  name?: string;
  kind?: string;
  company_name?: string;
  city?: string;
}

export enum SocialType {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  INSTAGRAM = 'instagram',
  DRIBBBLE = 'dribbble',
  GITHUB = 'github',
}
export interface Profile {
  fullname: string;
  birthdate: string;
  family_name: string;
  gender: string;
  given_name: string;
  locale: string;
  middle_name: string;
  name: string;
  nickname: string;
  phone_number: string;
  phone_number_verified: boolean;
  email: string;
  email_verified: boolean;
  picture: string;
  preferred_username: string;
  website: string;
  zoneinfo: string;
  address: Address;
  avatar: string;
  description: string;
  education?: Education;
  job?: string;
  social?: { [key in SocialType]: string };
}
export interface User {
  username: string;
  email: string;
  password?: string;
  created: Date;
  updated: Date;
  info: Profile;
}

export interface UserWithRole extends User {
  role: string;
}

export interface Otp {
  otp: string;
  user: User;
}
