import { HomePage } from 'core.types/homepage';
import { useEffect, useState } from 'react';
import { apiInternal } from 'service/api.internal';

export const useHomeCourses = () => {
  const [loading, setLoading] = useState(false);
  const [homeCourses, setHomeCourses] = useState<HomePage>({
    courses: [],
    bootcamps: [],
  });

  const onDataChange = (v: any, pName: keyof HomePage) => {
    setHomeCourses({
      ...homeCourses,
      [pName]: v,
    });
  };

  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/homepage`)
      .then((_homeCourses: HomePage) => {
        setHomeCourses(_homeCourses);
        return _homeCourses;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const save = (): Promise<HomePage | undefined> => {
    setLoading(true);

    return apiInternal
      .put(`/homepage`, homeCourses)
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);

  return {
    homeCourses,
    onDataChange,
    save,
    loading,
  };
};
