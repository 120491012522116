import { Button, Card, Form } from 'antd';
import enumToArray from 'common/enumToArray';
import Input, { CurrencyInput } from 'components/input';
import { CheckBox } from 'components/input/check.box';
import Datepicker from 'components/input/date.time.picker';
import RichTextEditor from 'components/input/ckeditor/ckeditor';
import Select from 'components/input/select';
import SelectTag from 'components/input/select.tag';
import { useGlobalState } from 'context';
import { courseStatusLabel, useCourse, CourseStatus } from 'hooks/course';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Course } from '../../../../../src/core/courses';
import TutorAutocomplete from '../components/tutor.autocomplete';
import { useCertificates } from 'hooks/certificate';
import { CourseType } from 'core.types/courses';
import { useWebinarCategories } from 'hooks/webinar.category';
import { WebinarCategory } from 'core.types/webinar.category';

export const CourseStepDetail: FC = () => {
  const { course, setCourse, loading, save } = useCourse();
  const [enableTime, setEnableTime] = useState(false);
  const [richEditorValues, setRichEditorValues] = useState<Partial<Course>>({
    overview: '',
    description: '',
  });
  const [, stepDispatch] = useGlobalState('course.step', 0);
  const { certificates } = useCertificates();
  const { webinarCategories } = useWebinarCategories();
  const [courseType, setCourseType] = useState<CourseType>(
    CourseType.VIDEO_COURSE
  );
  const courseChange = (v: any, pName: keyof Course) => {
    setCourse({ ...course, [pName]: v });
  };
  const ckEditorValueChange = (v: any, pName: keyof Course) => {
    setRichEditorValues({ ...richEditorValues, [pName]: v });
  };
  const history = useHistory();
  useEffect(() => {
    courseChange(undefined, 'open');
  }, [enableTime]);
  useEffect(() => {
    const courseType = history.location.pathname
      .split('/')[1]
      .slice(0, -1) as CourseType;
    setCourseType(courseType);
  }, [history]);
  useEffect(() => {
    // keep career level on submit
    setCourse({
      ...course,
      career: course.career,
    });
    renderCourseStatuses();
  }, []);

  /**
   * value handling of CKEDITOR component is little bit different
   * due to unrecognized react state bug
   */
  useEffect(() => {
    setCourse({ ...course, overview: richEditorValues.overview });
  }, [richEditorValues.overview]);
  useEffect(() => {
    setCourse({
      ...course,
      description: richEditorValues.description as string,
    });
  }, [richEditorValues.description]);

  const _save = async () => {
    const c = await save();
    if (c) {
      history.push(
        `/${courseType === CourseType.WEBINAR ? 'webinars' : 'courses'}/${
          c.uuid
        }/form`
      );
      stepDispatch(1);
    }
  };

  const cancelButtonClick = () => {
    stepDispatch(0);
    history.goBack();
  };

  const renderCourseStatuses = () => {
    const statuses = enumToArray(CourseStatus) as any as CourseStatus[];
    if (courseType === CourseType.WEBINAR) {
      return statuses;
    }
    if (certificates.length === 0 || course.extras.metadata === undefined) {
      return statuses.filter((s) => s !== 1); // 1 is published status
    }
    return statuses;
  };

  return (
    <Card loading={loading}>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Name`}
          value={course.name}
          onChange={(v) => courseChange(v, 'name')}
        />
        <RichTextEditor
          label={`Description`}
          value={course.description}
          onChange={(v) => ckEditorValueChange(v, 'description')}
        />
        <RichTextEditor
          label={`Overview`}
          value={course.overview}
          onChange={(v) => ckEditorValueChange(v, 'overview')}
        />
        <Select
          onChange={(v) => courseChange(v, 'status')}
          label={`Status`}
          items={renderCourseStatuses()}
          value={course.status}
          renderItem={(item: CourseStatus) => courseStatusLabel[item]}
        />
        {courseType === CourseType.WEBINAR && (
          <SelectTag
            onChange={(v) => {
              const values = v.map((d) => webinarCategories[d].name);
              courseChange(values, 'webinar_categories');
            }}
            label={`Webinar Category`}
            items={webinarCategories}
            value={webinarCategories
              ?.map((d, i) =>
                course.webinar_categories?.includes(d.name as any)
                  ? i
                  : undefined
              )
              .filter((element) => element !== undefined)}
            renderItem={(item: WebinarCategory) => item.name}
          />
        )}
        {courseType !== CourseType.WEBINAR && (
          <SelectTag
            onChange={(v) => courseChange(v, 'sessions')}
            label={`Clasess`}
            value={course.sessions || []}
          />
        )}
        <TutorAutocomplete
          label={`Tutor`}
          value={course.tutor}
          onChange={(v) => courseChange(v, 'tutor')}
        />
        <CurrencyInput
          label={`Price`}
          value={course.extras && course.extras.price}
          onChange={(v) =>
            courseChange({ ...course.extras, price: v }, 'extras')
          }
        />
        <CurrencyInput
          label={`Active Price`}
          value={course.extras && course.extras.active_price}
          onChange={(v) =>
            courseChange({ ...course.extras, active_price: v }, 'extras')
          }
        />
        {courseType === CourseType.WEBINAR ? (
          <>
            <Datepicker
              showTime={true}
              label="Open"
              value={course.open}
              onChange={(open) => courseChange(open, 'open')}
            />
            <Datepicker
              showTime={true}
              label="Close"
              value={course.close}
              onChange={(close) => courseChange(close, 'close')}
            />
          </>
        ) : (
          <>
            <CheckBox
              label="Enable Open Time"
              value={enableTime}
              onChange={() => setEnableTime(!enableTime)}
            />
            {enableTime && (
              <Datepicker
                label="Enable Open Time"
                value={course.open}
                onChange={(open) => courseChange(open, 'open')}
              />
            )}
          </>
        )}
        <Datepicker
          label="Start Date"
          value={course.start_date}
          onChange={(v) => courseChange(v, 'start_date')}
        />
        <Datepicker
          label="End Date"
          value={course.end_date}
          onChange={(v) => courseChange(v, 'end_date')}
        />
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Next
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
          Cancel
        </Button>
      </Form>
    </Card>
  );
};

export default CourseStepDetail;
