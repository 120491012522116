import { Card, Table } from 'antd';
import { Question } from 'core.types/courses';
import { useQuiz } from 'hooks/quiz';
import { useStudentParam } from 'hooks/student';
import { Link } from 'react-router-dom';
export const StudentAnswerList = () => {
  const { student: studentParam, course, module } = useStudentParam();
  const { quiz, loading } = useQuiz();

  const questionColumn = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => type.split('_').join(' ').toUpperCase(),
    },
    {
      title: 'Action',
      render: (q: Question) => (
        <>
          <Link
            to={`/students/${studentParam}/courses/${course}/modules/${module}/questions/${q.uuid}/answers`}
            style={{ marginLeft: 10 }}
          >
            Show Answer
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Card title={`${studentParam}'s Answer List`} loading={loading}>
        <Table
          columns={questionColumn}
          dataSource={quiz?.questions}
          pagination={false}
        />
      </Card>
    </>
  );
};

export default StudentAnswerList;
