import { Button, Card, Form, message } from 'antd';
import enumToArray from 'common/enumToArray';
import Input from 'components/input';
import NumberInput from 'components/input/number.input';
import Select from 'components/input/select';
import { Question, QuestionType } from 'core.types/courses';
import { useQuestion, useQuestionParam } from 'hooks/question';
import { FC } from 'react';
import { useHistory } from 'react-router';
import AnswerForm from './form.question.answer';

const QuestionFormDetail: FC = () => {
  const history = useHistory();
  const { question, setQuestion, save } = useQuestion();
  const questionParams = useQuestionParam();
  const questionChange = (v: any, pName: keyof Question) => {
    setQuestion({
      ...question,
      [pName]: v,
    });
  };

  const saveQuestion = async () => {
    const q = await save();
    if (q) {
      message.success('Question Saved');
    }
    setQuestion(q);
    history.push(
      `/courses/${questionParams.course}/modules/${questionParams.module}/quizes/${questionParams.quiz}/question/${q.uuid}/form`
    );
  };

  const cancelButtonClick = () => {
    history.goBack();
  };

  return (
    <>
      <Card>
        <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Input
            label={`Question`}
            value={question.question}
            onChange={(v) => questionChange(v, 'question')}
          />

          <NumberInput
            label={`Weight`}
            value={question.weight}
            onChange={(v) => questionChange(v, 'weight')}
          />

          <Select
            value={question.type}
            onChange={(v) => questionChange(v, 'type')}
            label={`Type`}
            items={enumToArray(QuestionType) as any as QuestionType[]}
            renderItem={(item: QuestionType) =>
              item.split('_').join(' ').toUpperCase()
            }
          />
        </Form>
        <Button type="primary" onClick={() => saveQuestion()}>
          Save
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
          Cancel
        </Button>
      </Card>
      {question.type === QuestionType.MULTIPLE_CHOISE &&
        questionParams.question !== 'new' && <AnswerForm />}
    </>
  );
};

export default QuestionFormDetail;
