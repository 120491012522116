import { Button, Row, Col } from 'antd';
import { TextArea } from 'components/input';
import { FC, useEffect, useState } from 'react';

export interface BootcampLearningMethodsProps {
  value: any;
  onChange?: (v: any) => void;
}

export const BootcampLearningMethods: FC<BootcampLearningMethodsProps> = ({
  value,
  onChange,
}) => {
  const [learningMethod, setLearningMethod] = useState<string[]>([]);
  const onFieldChange = (v: any, i: number) => {
    const newArr = [...learningMethod];
    newArr[i] = v;
    setLearningMethod(newArr);
  };

  useEffect(() => {
    onChange && onChange(learningMethod);
  }, [learningMethod]);

  useEffect(() => {
    setLearningMethod(value);
  }, [value]);

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={4} style={{ display: 'flex' }}>
          <span style={{ marginLeft: 'auto', marginRight: 10 }}>
            Learning Methods:
          </span>
        </Col>
        <Col span={20} style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              setLearningMethod([...learningMethod, '']);
            }}
          >
            Add Methods
          </Button>
        </Col>
      </Row>
      {learningMethod.map((value, i) => {
        return (
          <div key={i}>
            <TextArea
              label={`Method`}
              value={value}
              onChange={(v) => onFieldChange(v, i)}
            />
            <Row>
              <Col offset={4} span={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      const m = [...learningMethod];
                      m.splice(i, 1);
                      setLearningMethod(m);
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default BootcampLearningMethods;
