import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button, Card, Form, message } from 'antd';
import Input from 'components/input';
import { CheckBox } from 'components/input/check.box';
import { Options } from 'core.types/courses';
import { useOptions } from 'hooks/options';

export const AnswerForm = () => {
  const { options, setOptions, save, fetch } = useOptions();

  const onOptionChanges = (k: keyof Options, v: any, i: number) => {
    const newArr = [...options];
    newArr[i] = { ...newArr[i], [k]: v };
    setOptions(newArr);
  };

  const onOptionDeleted = (i: number) => {
    const item = [...options];
    item.splice(i, 1);
    setOptions(item);
  };

  const onSave = async () => {
    const s = await save();
    if (s) {
      message.success('Question Answer Saved');
      fetch();
    }
  };

  return (
    <>
      <Card style={{ marginTop: 20 }} title="Multiple Choice Options">
        <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          {options.map((option, i) => (
            <Row key={i}>
              <Col xl={18}>
                <Input
                  label={`Value`}
                  value={option.value}
                  onChange={(v) => onOptionChanges('value', v, i)}
                />

                <CheckBox
                  label="Is Answer"
                  value={option.is_answer}
                  onChange={(v) => onOptionChanges('is_answer', v, i)}
                />
              </Col>
              <Col xl={6}>
                <Button
                  type={`primary`}
                  danger
                  block
                  icon={<DeleteOutlined />}
                  onClick={() => onOptionDeleted(i)}
                />
              </Col>
            </Row>
          ))}
          <Button
            onClick={() => {
              setOptions([
                ...options,
                {
                  value: '',
                  label: '',
                  is_answer: false,
                },
              ]);
            }}
            style={{ marginLeft: 190 }}
          >
            Add Options
          </Button>
        </Form>
        <Button type="primary" onClick={onSave} style={{ marginTop: 20 }}>
          Save
        </Button>
      </Card>
    </>
  );
};

export default AnswerForm;
