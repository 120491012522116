import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import { Module, ModuleType, Course } from 'core.types/courses';

export const moduleTypeLabel = {
  [ModuleType.ASSESSMENT]: 'Assessment',
  [ModuleType.MODULE]: 'Module',
  [ModuleType.CERTIFICATE]: 'Certificate',
};

export const initialModuleData: Module = {
  name: '',
  description: '',
  type: ModuleType.MODULE,
  content: '',
  overview: '',
  weight: 0,
  number: 0,
  course: <Course>{},
  parent_module: undefined,
  sessions: [],
  duration: 0,
};

export const useModuleParam = () =>
  useParams<{ course: string; module: string }>();

export const useModules = () => {
  const moduleParams = useModuleParam();
  const [loading, setLoading] = useLoading();
  const [modules, setModules] = useState<Module[]>([]);
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(`/courses/${moduleParams.course}/modules`)
      .then((_modules) => {
        setModules(_modules);
        return _modules;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  return {
    modules,
    loading,
    fetch,
  };
};

export const useModule = () => {
  const [module, setModule] = useState<Module>(initialModuleData);
  const [loading, setLoading] = useLoading();
  const moduleParams = useModuleParam();

  const initParams = { courseUUID: '', moduleUUID: '' };
  const get = (params = initParams) => {
    setLoading(true);

    const url =
      params !== initParams
        ? `/courses/${params.courseUUID}/modules/${params.moduleUUID}`
        : `/courses/${moduleParams.course}/modules/${moduleParams.module}`;

    return apiInternal
      .get(url)
      .then((_module) => {
        setModule(_module);
        return _module;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = (): Promise<Module | undefined> => {
    setLoading(true);

    const fn =
      moduleParams.module === 'new'
        ? apiInternal.post(
            `/tutor/${module.course.tutor}/courses/${moduleParams.course}/modules`,
            module
          )
        : apiInternal.put(
            `/tutor/${module.course.tutor}/courses/${moduleParams.course}/modules/${module.uuid}`,
            module
          );

    return fn
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  const remove = () => {
    setLoading(true);

    return apiInternal
      .delete(
        `tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${module.uuid}`
      )
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return {
    module,
    loading,
    get,
    save,
    remove,
    setLoading,
    setModule,
  };
};
