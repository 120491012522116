import { Form, InputNumber } from 'antd';
import { FC } from 'react';

export interface CurrencyInputProps {
  value: any;
  label?: string;
  onChange?: (value: any) => void;
  readonly?: boolean;
}
export const CurrencyInput: FC<CurrencyInputProps> = ({
  value,
  onChange,
  readonly,
  label,
}: CurrencyInputProps) => {
  return (
    <Form.Item label={label}>
      <InputNumber
        style={{ width: 'auto' }}
        readOnly={readonly}
        value={value}
        onChange={(value) => onChange && onChange(value)}
      />
    </Form.Item>
  );
};

export default CurrencyInput;
