import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';

export const useAssetsParam = () =>
  useParams<{ course: string; certificate: string }>();

export const useAssets = () => {
  const [loading, setLoading] = useLoading();
  const certificateParams = useAssetsParam();

  const uploadImageAsset = (image: any, bucket: string): Promise<any> => {
    setLoading(true);

    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('image', image);

    const fn = apiInternal.post(bucket, formData);

    return fn
      .then((d) => {
        return d;
      })
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  const fetchImageAsset = (bucket: string): Promise<any> => {
    setLoading(true);

    const fn = apiInternal.get(bucket);

    return fn
      .then((d) => {
        return d;
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => setLoading(false));
  };
  return {
    loading,
    setLoading,
    uploadImageAsset,
    fetchImageAsset,
    certificateParams,
  };
};
