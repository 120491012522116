import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import { Module, ModuleType, Material } from 'core.types/courses';
import { initialModuleData } from './module';

export const moduleTypeLabel = {
  [ModuleType.ASSESSMENT]: 'Assessment',
  [ModuleType.MODULE]: 'Module',
  [ModuleType.CERTIFICATE]: 'Certificate',
};
export const useModuleAssetParam = () =>
  useParams<{ course: string; module: string }>();

export const useModuleAsset = () => {
  const [module, setModule] = useState<Module>(initialModuleData);
  const [loading, setLoading] = useLoading();
  const [material, setMaterial] = useState<Material>();
  const moduleParams = useModuleAssetParam();

  const get = () => {
    setLoading(true);

    return apiInternal
      .get(`/courses/${moduleParams.course}/modules/${moduleParams.module}`)
      .then((_module) => {
        setModule(_module);
        return _module;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };

  const save = (): Promise<Material | undefined> => {
    setLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData.append('files', material?.data);

    const fn = apiInternal.post(
      `/tutor/${module.course.tutor}/courses/${moduleParams.course}/modules/${module.uuid}/files`,
      formData
    );
    return fn
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return {
    module,
    material,
    loading,
    get,
    save,
    setLoading,
    setMaterial,
    setModule,
  };
};
