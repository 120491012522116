import { combineReducers } from 'redux';
import coupon from './coupon';
import challenge from './challenge';
import course from './course';
import generatedCertificate from './generated.certificate';
import packageBenefit from './package.benefit';
import pkg from './package';
import user from './user';
import webinarCategory from './webinar.category';

const rootReducer = combineReducers({
  challenge,
  coupon,
  course,
  generatedCertificate,
  packageBenefit,
  pkg,
  user,
  webinarCategory,
});

export default rootReducer;
