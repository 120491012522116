import { useEffect } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { Package } from 'core.types/billing';
import { usePackageStore } from 'store/hooks/package';

export const initialPackageData: Package = {
  name: '',
  quota: 0,
  price: 0,
  benefits: [],
  duration: 0,
};

export const usePackages = () => {
  const {
    packages,
    action: { setPackages },
  } = usePackageStore();
  const [loading, setLoading] = useLoading();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get('/billing/packages')
      .then((_b: Package[]) => {
        setPackages(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetch();
  }, []);
  return { packages, loading, fetch };
};

export const usePackage = () => {
  const {
    pkg,
    action: { setPackage },
  } = usePackageStore();
  const [loading, setLoading] = useLoading();
  const get = (packageUUID: string) => {
    setLoading(true);
    return apiInternal
      .get(`/billing/packages/${packageUUID}`)
      .then((_b: Package) => {
        setPackage(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = () => {
    setLoading(true);
    const req = pkg.uuid
      ? apiInternal.put(`/billing/packages/${pkg.uuid}`, pkg)
      : apiInternal.post(`/billing/packages`, pkg);

    return req
      .then((_b: Package) => {
        setPackage(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const remove = (packageUUID: string) => {
    setLoading(true);
    return apiInternal
      .delete(`/billing/packages/${packageUUID}`)
      .then((_b: Package) => {
        setPackage(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  return { pkg, loading, get, save, setPackage, remove };
};
