import { InputProps } from 'components/input';
import SelectAutocomplete, {
  SelectItem,
} from 'components/input/select.autocomplete';
import { useIdentities } from 'hooks/identity';
import { FC } from 'react';
import { Paginated } from '../../../../../src/core/core.types';
import { User } from '../../../../../src/core/identity';

const TutorAutocomplete: FC<InputProps> = (props) => {
  const { fetch, loading } = useIdentities();
  const onSearch = async (value: string): Promise<SelectItem[]> => {
    const userData = (await fetch({
      info: value,
    })) as Paginated<User>;
    const data = userData.data.map(
      (user): SelectItem => ({
        label: `${user.info.fullname} - ${user.email} `,
        value: user.username,
      })
    );
    return data;
  };
  return (
    <SelectAutocomplete onSearch={onSearch} {...props} loading={loading} />
  );
};

export default TutorAutocomplete;
