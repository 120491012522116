import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm, Table } from 'antd';
import PackageBanefitFormModal from './components/modal.form';
import { useState } from 'react';
import { initialPackageData, usePackage, usePackages } from 'hooks/package';

export const PackageList = () => {
  const { packages, loading, fetch } = usePackages();
  const { pkg, get, remove, setPackage } = usePackage();
  const [modalVisible, setModalVisible] = useState(false);

  const packageColumns = [
    {
      title: 'Package Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Package Quota',
      dataIndex: 'quota',
      key: 'quota',
    },
    {
      title: 'Package Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Package Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (duration: string) => `${duration} days`,
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string) => (
        <>
          <Button onClick={() => onDetailClicked(uuid)}>Detail</Button>
          <Popconfirm
            title={`Are you sure to delete benefit?`}
            onConfirm={onDeleteCertificateConfirm}
            onCancel={onCancelDeleteCertificateConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onModuleDeletePrompt(uuid)}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const addBenefitButtonClick = () => {
    setPackage(initialPackageData);
    setModalVisible(true);
  };

  const onDetailClicked = (benefitUUID: string) => {
    get(benefitUUID);
    setModalVisible(true);
  };
  const onModuleDeletePrompt = (benefitUUID: string) => {
    get(benefitUUID);
  };

  const onDeleteCertificateConfirm = async () => {
    const r = await remove(pkg?.uuid as string);
    if (r) {
      message.success(`Package successfully removed!`);
      setPackage(initialPackageData);
      fetch();
    }
  };

  const onCancelDeleteCertificateConfirm = () => {
    setPackage(initialPackageData);
  };

  return (
    <>
      <Card
        title={`Package List`}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addBenefitButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table
          columns={packageColumns}
          dataSource={packages}
          pagination={false}
        />
      </Card>
      <PackageBanefitFormModal
        visible={modalVisible}
        setVisible={(v) => setModalVisible(v)}
      />
    </>
  );
};

export default PackageList;
