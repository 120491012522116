import { Form, TimePicker as AntdTimePicker } from 'antd';
import { FC, useEffect, useState } from 'react';
import { InputProps } from './input';
import moment from 'moment';
export const TimePicker: FC<InputProps> = ({
  value,
  onChange,
  readonly,
  label,
}) => {
  const [time, setTime] = useState<moment.Moment>();
  useEffect(() => {
    if (!value) return;
    const changedValue = moment(value, 'HH:mm:ss');
    if (changedValue !== time) {
      setTime(changedValue);
    }
  }, []);
  useEffect(() => {
    if (time !== value) {
      onChange && onChange(time);
    }
  }, [time]);
  return (
    <Form.Item label={label}>
      <AntdTimePicker
        disabled={readonly}
        value={time}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onChange={(_date) => setTime(_date!)}
      />
    </Form.Item>
  );
};

export default TimePicker;
