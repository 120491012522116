import { Options, Question, QuestionType } from 'core.types/courses';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { apiInternal } from 'service/api.internal';
import { useLoading } from './common';
import { useModule } from './module';
import { useQuiz } from './quiz';

export const useQuestionParam = () =>
  useParams<{
    course: string;
    module: string;
    quiz: string;
    question: string;
  }>();

export const questionInitVal: Question = {
  type: QuestionType.MULTIPLE_CHOISE,
  question: '',
  weight: 0,
  options: [],
};

const initData: Options = {
  label: '',
  value: '',
  is_answer: false,
};

export const useQuestions = () => {
  const { module } = useModule();
  const { quiz } = useQuiz();
  const [loading, setLoading] = useLoading();
  const [questions, setQuestions] = useState<Question[]>([]);
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${module.uuid}/quizes/${quiz.uuid}/questions`
      )
      .then((_questions) => {
        setQuestions(_questions);
        return _questions;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    if (module.uuid !== '' && quiz.name !== '') {
      fetch();
    }
  }, [module, quiz]);

  return {
    questions,
    loading,
    setQuestions,
    fetch,
  };
};

export const useQuestion = () => {
  const { module } = useModule();
  const { quiz } = useQuiz();
  const questionParam = useQuestionParam();
  const [loading, setLoading] = useLoading();
  const [question, setQuestion] = useState<Question>(questionInitVal);
  const [options, setOptions] = useState<Options[]>([initData]);

  const get = ({ question }) => {
    setLoading(true);
    return apiInternal
      .get(
        `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${module.uuid}/quizes/${quiz.uuid}/questions/${question}`
      )
      .then((_questions) => {
        setQuestion(_questions);
        return _questions;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const save = () => {
    setLoading(true);

    const fn =
      questionParam.question !== 'new'
        ? apiInternal.put(
            `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${questionParam.module}/quizes/${questionParam.quiz}/questions/${questionParam.question}`,
            question
          )
        : apiInternal.post(
            `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${questionParam.module}/quizes/${questionParam.quiz}/questions`,
            question
          );

    return fn
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 500);
        setQuestion(questionInitVal);
      });
  };

  const saveOptions = () => {
    setLoading(true);

    const newOption = options.map((l) => ({
      ...l,
      is_answer: l.is_answer !== undefined ? l.is_answer : false,
    }));

    const fn = apiInternal.put(
      `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${questionParam.module}/quizes/${questionParam.quiz}/questions/${questionParam.question}/options`,
      newOption
    );

    return fn
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const remove = ({ question }) => {
    setLoading(true);
    const fn = apiInternal.delete(
      `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${questionParam.module}/quizes/${quiz.uuid}/questions/${question}`
    );

    return fn
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    if (module.uuid !== '' && quiz.name !== '') {
      get({ question: questionParam.quiz });
    }
  }, [module, quiz]);

  return {
    get,
    save,
    saveOptions,
    question,
    loading,
    options,
    setQuestion,
    setOptions,
    remove,
  };
};
