import { useState } from 'react';

export const useLoading = () => useState(false);

export const usePageSize = () =>
  useState({
    page: 1,
    size: 10,
  });

export enum All {
  ALL = 'all',
}

export const allLabel = {
  [All.ALL]: 'All',
};
