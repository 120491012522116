import { Button, Form, Modal } from 'antd';
import Input from 'components/input';
import {
  useWebinarCategory,
  useWebinarCategories,
} from 'hooks/webinar.category';
import { FC } from 'react';
import { webinarCategoryInitialData } from 'store/reducers/webinar.category';

interface WebinarCategoryModalFormProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const WebinarCategoryFormModal: FC<WebinarCategoryModalFormProps> = ({
  visible,
  setVisible,
}) => {
  const { save, setWebinarCategory, webinarCategory, loading } =
    useWebinarCategory();
  const { fetch } = useWebinarCategories();
  const handleOk = async () => {
    const s = await save();
    if (s) {
      fetch();
      setVisible(false);
    }
  };
  const handleCancel = () => {
    setWebinarCategory(webinarCategoryInitialData);
    setVisible(false);
  };

  return (
    <Modal
      title="Create Webinar Category"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={640}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
    >
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Category`}
          value={webinarCategory.name}
          onChange={(v) => setWebinarCategory({ ...webinarCategory, name: v })}
        />
      </Form>
    </Modal>
  );
};

export default WebinarCategoryFormModal;
