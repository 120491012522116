import { InputProps } from 'components/input';
import SelectAutocomplete, {
  SelectItem,
} from 'components/input/select.autocomplete';
import { useCourses } from 'hooks/course';
import { FC } from 'react';

const CourseAutocomplete: FC<InputProps> = (props) => {
  const { courses, loading, setParam } = useCourses();
  const onSearch = async (value: string): Promise<SelectItem[]> => {
    setParam({
      keyword: value,
    });
    const data = courses.data.map(
      (courses): SelectItem => ({
        label: `${courses.name}`,
        value: courses.uuid as string,
      })
    );
    return data;
  };
  return (
    <SelectAutocomplete onSearch={onSearch} {...props} loading={loading} />
  );
};

export default CourseAutocomplete;
