import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button, Card, Row, Col } from 'antd';
import { LoginField, useAuth, useUser } from 'hooks/auth';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const Login = () => {
  const user = useUser();
  const { credentials, setCredentials, login, loading } = useAuth();
  const history = useHistory();

  const onFieldChange = (v: any, pName: keyof LoginField) => {
    setCredentials({
      ...credentials,
      [pName]: v,
    });
  };

  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, []);

  const onFinish = async () => {
    const l = await login();
    if (l) {
      history.push('/');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row style={{ marginTop: 300 }}>
      <Col span={6} offset={9}>
        <Card>
          <Form
            name="user-login"
            className="login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Please input your Username!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                value={credentials.username}
                onChange={({ target: { value } }) =>
                  onFieldChange(value, 'username')
                }
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                value={credentials.password}
                onChange={({ target: { value } }) =>
                  onFieldChange(value, 'password')
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
