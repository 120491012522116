import { Form, DatePicker as AntdDatePicker } from 'antd';
import { FC, useEffect, useState } from 'react';
import { InputProps } from './input';
import moment from 'moment';

export interface DateTimeTypes extends InputProps {
  showTime?: boolean;
}

export const Datepicker: FC<DateTimeTypes> = ({
  value,
  onChange,
  readonly,
  label,
  showTime,
}) => {
  const [date, setDate] = useState<moment.Moment>();
  useEffect(() => {
    if (!value) return;
    const changedValue = moment(value);
    if (changedValue !== date) {
      setDate(changedValue);
    }
  }, []);
  useEffect(() => {
    const changedDate = date?.toDate();
    if (changedDate !== value) {
      onChange && onChange(changedDate);
    }
  }, [date]);
  return (
    <Form.Item label={label}>
      <AntdDatePicker
        disabled={readonly}
        value={date}
        showTime={showTime}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onChange={(_date) => setDate(_date!)}
      />
    </Form.Item>
  );
};

export default Datepicker;
