import { Button, Card, Row, Col } from 'antd';
import NumberInput from 'components/input/number.input';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { useCourse } from 'hooks/course';
import { useIndicators } from 'hooks/indicator';
import { useGlobalState } from 'context';
import { Indicator } from 'core.types/achievement/achievement';

const ModuleAchievement: FC = () => {
  const history = useHistory();
  const { course } = useCourse();
  const { indicators, setIndicators, save, loading } = useIndicators();
  const [, setCurrentStep] = useGlobalState('module.step', 0);

  const onIndicatorChanges = (v: any, pName: keyof Indicator, idx: number) => {
    const newArr = [...indicators];
    const m = { ...newArr[idx], [pName]: v };
    newArr[idx] = m;
    setIndicators(newArr);
  };

  const _save = async () => {
    const i = await save();
    if (i) {
      setCurrentStep(3);
    }
  };

  const cancelButtonClick = () => {
    setCurrentStep(1);
    history.goBack();
  };

  return (
    <Card loading={loading} title={`Module ${course.name} Course Add Module`}>
      {indicators.map((d, i) => {
        return (
          <Row style={{ marginTop: 20 }} key={i}>
            <Col xl={4}>{d.name}</Col>
            <Col xl={8} style={{ paddingLeft: 15 }}>
              <NumberInput
                label={`Points`}
                value={d.points}
                onChange={(v) => onIndicatorChanges(v, 'points', i)}
              />
            </Col>
          </Row>
        );
      })}
      <Button type="primary" loading={loading} onClick={_save}>
        Save and Next
      </Button>
      <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
        Cancel
      </Button>
    </Card>
  );
};

export default ModuleAchievement;
