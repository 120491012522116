import { Card, Spin, Form, Button } from 'antd';
import Input from 'components/input';
import { useCertificate } from 'hooks/certificate';
import NumberInput from 'components/input/number.input';
import { useHistory } from 'react-router';
import RichTextEditor, {
  AvailablePlugins,
} from 'components/input/ckeditor/ckeditor';
import { useEffect, useState } from 'react';
import { Certificate } from 'core.types/courses';

export const CertificateFormPage = () => {
  const history = useHistory();
  const { certificate, setCertificate, loading, save, certificateParams } =
    useCertificate();
  const [richEditorValues, setRichEditorValues] = useState<
    Partial<Certificate>
  >({
    format: '',
  });
  const certficateChange = (v: any, pName: keyof Certificate) => {
    setCertificate({
      ...certificate,
      [pName]: v,
    });
  };
  const ckEditorValueChange = (v: any, pName: keyof Certificate) => {
    setRichEditorValues({ ...richEditorValues, [pName]: v });
  };

  /**
   * value handling of CKEDITOR component is little bit different
   * due to unrecognized react state bug
   */
  useEffect(() => {
    setCertificate({
      ...certificate,
      format: richEditorValues.format as string,
    });
  }, [richEditorValues.format]);

  const _save = async () => {
    const s = await save();
    if (s && certificateParams.certificate === 'new') {
      history.push(
        `/courses/${certificateParams.course}/certificates/${s.uuid}/form`
      );
    }
  };

  const backButtonClick = () => {
    history.goBack();
  };

  return (
    <Card title={`Certificate Detail`} loading={loading}>
      {loading && <Spin />}
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Caption`}
          value={certificate.caption}
          onChange={(v) => certficateChange(v, 'caption')}
        />
        <RichTextEditor
          label={`Format`}
          value={certificate.format}
          onChange={(v) => {
            ckEditorValueChange(v, 'format');
          }}
          plugins={[AvailablePlugins.IMAGE_ASSETS]}
          imageUploadPath="/files/bucket/certificate-assets"
        />
        <NumberInput
          label={`Weight goal`}
          value={certificate.weight_goal}
          onChange={(v) => certficateChange(v, 'weight_goal')}
        />
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Next
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => backButtonClick()}>
          Back
        </Button>
      </Form>
    </Card>
  );
};

export default CertificateFormPage;
