import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Paginated } from 'core.types/core.types';
import { Course, CourseStatus, CourseType } from 'core.types/courses';

export const courseInitialData: Course = {
  name: '',
  career: [],
  description: '',
  extras: {
    price: 0,
  },
  number: 0,
  status: CourseStatus.PUBLISHED,
  open: undefined,
  tutor: '',
  cover: undefined,
  overview: '',
  type: CourseType.VIDEO_COURSE,
  start_date: undefined,
  end_date: undefined,
};

export interface CouponStoreIface {
  loading: boolean;
  course: Course;
  courses: Paginated<Course>;
  webinars: Paginated<Course>;
}

const initialState: CouponStoreIface = {
  loading: false,
  course: courseInitialData,
  courses: {
    data: [],
    pagination: {
      ...{ page: 1, size: 10 },
      total_page: 0,
      total_size: 0,
    },
  },
  webinars: {
    data: [],
    pagination: {
      ...{ page: 1, size: 10 },
      total_page: 0,
      total_size: 0,
    },
  },
};

export const slice = createSlice({
  name: 'couponn',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCourse: (state, action: PayloadAction<Course>) => {
      state.course = action.payload;
    },
    setCourses: (state, action: PayloadAction<Paginated<Course>>) => {
      state.courses = action.payload;
    },
    setWebinars: (state, action: PayloadAction<Paginated<Course>>) => {
      state.webinars = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setCourse, setCourses, setWebinars } = slice.actions;

export default slice.reducer;
