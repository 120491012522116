import { AutoComplete, Form } from 'antd';
import to from 'await-to-js';
import { FC, useState } from 'react';
import { InputProps } from './input';

export interface SelectItem {
  value: string | number;
  label: string;
}

export interface SelectProps extends InputProps {
  onSearch: (value: string) => Promise<SelectItem[]>;
  onScrollDown?: () => void;
  loading?: boolean;
}

export const SelectAutocomplete: FC<SelectProps> = ({
  value,
  label,
  onChange,
  onSearch,
}) => {
  const [items, setItems] = useState<SelectItem[]>([]);

  const _search = async (value) => {
    const [, data] = await to(onSearch(value));
    if (data) setItems(data);
  };
  return (
    <Form.Item label={label}>
      <AutoComplete
        value={value}
        onChange={(value) => {
          onChange && onChange(value);
        }}
        onSearch={_search}
        options={items}
      />
    </Form.Item>
  );
};

export default SelectAutocomplete;
