import { Benefit } from 'core.types/billing';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setLoading,
  setPackageBenefit,
  setPackageBenefits,
} from 'store/reducers/package.benefit';

export const usePackageBenefitStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.packageBenefit);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setPackageBenefit: (benefit: Benefit) =>
      dispatch(setPackageBenefit(benefit)),
    setPackageBenefits: (benefits: Benefit[]) =>
      dispatch(setPackageBenefits(benefits)),
  };

  return { ...states, action };
};
