import { InputProps } from 'components/input';
import PlainSelectAutocomplete, {
  SelectItem,
} from 'components/input/plain.select.autocomplete';
import { CourseType, useCourses } from 'hooks/course';
import { FC } from 'react';

const CourseAutocomplete: FC<InputProps> = (props) => {
  const { courses, setParam, loading } = useCourses();
  const onSearch = async (value: string): Promise<SelectItem[]> => {
    setParam({
      keyword: value,
      type: CourseType.VIDEO_COURSE,
    });
    const data = courses.data.map(
      (course): SelectItem => ({
        label: `${course.name} - ${course.tutor} `,
        value: course.uuid as string,
      })
    );
    return data;
  };
  return (
    <PlainSelectAutocomplete
      {...props}
      onSearch={onSearch}
      onChange={(v) => {
        const selectedCourse =
          courses.data && courses.data.find((d) => d.uuid === v);
        if (!selectedCourse) {
          props.onChange && props.onChange(v);
        } else {
          props.onChange && props.onChange(selectedCourse);
        }
      }}
      loading={loading}
    />
  );
};

export default CourseAutocomplete;
