import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Coupon } from 'core.types/coupon';

export const couponInitialData: Coupon = {
  creator: '',
  code: '',
  quota: 0,
  value: 0,
  expired: new Date(),
};
export const coupons: Coupon[] = [];

export interface CouponStoreIface {
  loading: boolean;
  coupon: Coupon;
  coupons: Coupon[];
}

const initialState: CouponStoreIface = {
  loading: false,
  coupon: {} as Coupon,
  coupons: [] as Coupon[],
};

export const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCoupon: (state, action: PayloadAction<Coupon>) => {
      state.coupon = action.payload;
    },
    setCoupons: (state, action: PayloadAction<Coupon[]>) => {
      state.coupons = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setCoupon, setCoupons } = slice.actions;

export default slice.reducer;
