import { Button, Card, message, Popconfirm, Table } from 'antd';
import { FC } from 'react';
import { Question } from 'core.types/courses';
import { useQuiz } from 'hooks/quiz';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { questionInitVal, useQuestion, useQuestions } from 'hooks/question';
import { useGlobalState } from 'context';

const QuizQuestions: FC = () => {
  const history = useHistory();
  const { quiz } = useQuiz();
  const { questions, fetch } = useQuestions();
  const { setQuestion, options, get, remove } = useQuestion();
  // const [, setQuestion] = useGlobalState<Question>('question.form', question);
  const [, setQuestionOptions] = useGlobalState('question.options', options);

  const moduleColumns = [
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => type.split('_').join(' ').toUpperCase(),
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: 'Action',
      render: (_question: Question) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setQuestion(_question);
              setQuestionOptions(_question.options ? _question.options : []);
              history.push(
                `/courses/${quiz.module.course.uuid}/modules/${quiz.module.uuid}/quizes/${quiz.uuid}/question/${_question.uuid}/form`
              );
            }}
          >
            Detail
          </Button>
          <Popconfirm
            title={`Are you sure to delete quiz question ?`}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onConfirm={() => onDeleteModuleConfirm(_question.uuid!)}
            onCancel={onCancelDeleteModuleConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onModuleDeletePrompt(_question.uuid!)}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const addButtonClick = () => {
    setQuestionOptions([]);
    setQuestion(questionInitVal);
    history.push(
      `/courses/${quiz.module.course.uuid}/modules/${quiz.module.uuid}/quizes/${quiz.uuid}/question/new/form`
    );
  };

  const onModuleDeletePrompt = (uuid: string) => {
    get({ question: uuid });
  };

  const onDeleteModuleConfirm = async (uuid: string) => {
    const r = await remove({ question: uuid });
    if (r) {
      message.success(`Quiz question has successfully removed!`);
      fetch();
    }
  };

  const onCancelDeleteModuleConfirm = () => {
    // unused
  };

  return (
    <Card
      title={`Question List`}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => addButtonClick()}
        >
          Add
        </Button>
      }
    >
      <Table columns={moduleColumns} dataSource={questions} />
    </Card>
  );
};

export default QuizQuestions;
