import { InputProps } from 'components/input';
import PlainSelectAutocomplete, {
  SelectItem,
} from 'components/input/plain.select.autocomplete';
import { useBootcamps } from 'hooks/bootcamp';
import { FC } from 'react';

const BootcampAutocomplete: FC<InputProps> = (props) => {
  const { bootcamps, loading, setParam } = useBootcamps();
  const onSearch = async (value: string): Promise<SelectItem[]> => {
    setParam({
      keyword: value,
    });
    const data = bootcamps.data.map(
      (bootcamp): SelectItem => ({
        label: `${bootcamp.name}`,
        value: bootcamp.uuid as string,
      })
    );
    return data;
  };
  return (
    <PlainSelectAutocomplete
      {...props}
      onSearch={onSearch}
      onChange={(v) => {
        const selectedBootcamp =
          bootcamps.data && bootcamps.data.find((d) => d.uuid === v);
        props.onChange && props.onChange(selectedBootcamp);
        if (!selectedBootcamp) {
          props.onChange && props.onChange(v);
        } else {
          props.onChange && props.onChange(selectedBootcamp);
        }
      }}
      loading={loading}
    />
  );
};

export default BootcampAutocomplete;
