import { useEffect, useState } from 'react';

export const useDocument = () => {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    setHeight(window.innerHeight);
  }, []);
  return {
    height,
  };
};
