import { Button, Card, message, Popconfirm, Table } from 'antd';
import { useLevel, useLevels } from '../../../hooks/level';
import { useState } from 'react';
import { Level } from '../../../../../src/core/careers/career';
import LevelForm from './career.level.form';
import { PlusOutlined } from '@ant-design/icons';

export const LevelList = () => {
  const { levels, fetch } = useLevels();
  const { level, setLevel, remove } = useLevel();
  const [[visible, setVisible], [isNew, setIsNew]] = [
    useState(false),
    useState(true),
  ];
  const careerColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (career: string) => career.split('-').join(' ').toUpperCase(),
    },
    {
      title: 'Action',
      render: (level: Level) => (
        <>
          <Button
            onClick={() => {
              setIsNew(false);
              setVisible(true);
              setLevel(level);
            }}
          >
            Detail
          </Button>
          <Popconfirm
            title={`Are you sure to delete career level ?`}
            onConfirm={onDeleteModuleConfirm}
            onCancel={onCancelDeleteModuleConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              onClick={() => {
                setLevel(level);
              }}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];
  const saved = () => {
    fetch();
    setVisible(false);
    setNew();
  };
  const setNew = () => {
    setLevel({} as Level);
    setIsNew(true);
  };

  const onDeleteModuleConfirm = async () => {
    const r = await remove();
    if (r) {
      message.success(`${level.name} successfully removed!`);
      setLevel({} as Level);
      fetch();
    }
  };

  const onCancelDeleteModuleConfirm = () => {
    setLevel({} as Level);
  };
  return (
    <Card
      title={`Career Level List`}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setVisible(true)}
        >
          Add
        </Button>
      }
    >
      <Table columns={careerColumns} dataSource={levels} />
      <LevelForm done={saved} isNew={isNew} visible={visible} />
    </Card>
  );
};

export default LevelList;
