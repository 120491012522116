import { Form, Select as AntdSelect } from 'antd';
import { Component, useEffect, useState } from 'react';
import { InputProps } from './input';

export interface SelectProps<T> extends InputProps {
  items: T[];
  itemKeyDisplay?: keyof T;
  itemKeyFind?: keyof T;
  renderItem?: (value: T) => Component | string;
  onChange: (value: T) => void;
}

export const Select = <T,>({
  value,
  label,
  onChange,
  items = [],
  itemKeyFind,
  itemKeyDisplay,
  renderItem,
}: SelectProps<T>) => {
  const [_value, _setValue] = useState<number>();
  const [isFirst, setIsFirst] = useState(true);
  const _onChange = (pos: number) => {
    _setValue(pos);
  };
  const getValue = (pos: number) => items[pos];
  const getDisplay = (v: any) => (!itemKeyDisplay ? v : v[itemKeyDisplay]);

  useEffect(() => {
    if (!isFirst || !value || items.length === 0) return;
    setIsFirst(false);

    _setValue(
      items.findIndex((v) => {
        return (
          (itemKeyFind && v[itemKeyFind] === value[itemKeyFind]) || v === value
        );
      })
    );
  }, [value, items]);

  useEffect(() => {
    _value !== undefined && onChange && onChange(getValue(_value));
  }, [_value]);
  return (
    <Form.Item label={label}>
      <AntdSelect value={_value} onChange={_onChange}>
        {items.map((v, pos) => (
          <AntdSelect.Option key={`k-${pos}`} value={pos}>
            {!renderItem && getDisplay(v)}
            {renderItem && renderItem(v)}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </Form.Item>
  );
};

export default Select;
