import { Options } from 'core.types/courses';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { apiInternal } from 'service/api.internal';
import { useLoading } from './common';
import { useModule } from './module';
import { useQuiz } from './quiz';

export const useOptionParam = () =>
  useParams<{
    course: string;
    module: string;
    quiz: string;
    question: string;
  }>();

export const binaryToBoolean = (num: any) => {
  /**
   * returning boolean from 0 and 1
   */
  return num === 1;
};

export const remapOptions = (options: Options[]) => {
  /**
   * is_answer from API returning number 1 and 0
   * and need to be changed to boolean
   */
  return options.map((op: Options) => ({
    ...op,
    is_answer: binaryToBoolean(op.is_answer),
  }));
};

export const useOptions = () => {
  const optionParam = useOptionParam();
  const { module } = useModule();
  const { quiz } = useQuiz();
  const [loading, setLoading] = useLoading();
  const [options, setOptions] = useState<Options[]>([]);
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${module.uuid}/quizes/${quiz.uuid}/questions/${optionParam.question}/options`
      )
      .then((_options) => {
        setOptions(remapOptions(_options));
        return _options;
      })
      .catch((e) => {
        // set empty array because if the question has no option, the API will return 404 status code
        setOptions([]);
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const save = () => {
    setLoading(true);

    const fn = apiInternal.put(
      `/tutor/${module.course.tutor}/courses/${optionParam.course}/modules/${optionParam.module}/quizes/${optionParam.quiz}/questions/${optionParam.question}/options`,
      options
    );

    return fn
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    if (module.uuid !== '' && quiz.name !== '') {
      fetch();
    }
  }, [module, quiz]);

  return {
    fetch,
    options,
    loading,
    save,
    setOptions,
  };
};
