import { FC } from 'react';
import './role.styled.radio.button.scss';
import { v4 as uuidv4 } from 'uuid';

interface RoleRadioButtonProps {
  label: string;
  name: string;
  value: any;
  checked?: boolean;
  onChange?: (v: any) => void;
  description?: string;
}

const RoleRadioButton: FC<RoleRadioButtonProps> = ({
  label,
  name,
  value,
  checked,
  onChange,
  description,
}) => {
  const id = uuidv4();
  return (
    <>
      <div className={`radio-button-card`}>
        <input
          className={`radio-button-input`}
          type="radio"
          id={id}
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
        />
        <label className={`radio-button-label`} htmlFor={id}>
          <span className={`radio-button-label-title`}>{label}</span>
          <p>{description}</p>
        </label>
      </div>
    </>
  );
};

export default RoleRadioButton;
