import { message } from 'antd';
import { Answer, Student } from 'core.types/enrollment';
import { QuizResult } from 'core.types/enrollment/enrollment.quiz.results';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { apiInternal } from 'service/api.internal';
import { useQuiz } from './quiz';

export const useStudentResultParam = () =>
  useParams<{
    course: string;
    student: string;
    module: string;
    quiz: string;
    question: string;
    result: string;
  }>();

export const useStudentResult = () => {
  const [student, setStudent] = useState<Student>();

  return {
    student,
    setStudent,
  };
};

export const useStudentResults = () => {
  const studentParams = useStudentResultParam();
  const [results, setResults] = useState<QuizResult[]>();
  const [loading, setLoading] = useState<boolean>();
  const { quiz } = useQuiz();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/students/${studentParams.student}/courses/${studentParams.course}/modules/${studentParams.module}/quizes/${quiz.uuid}`
      )
      .then((_results) => {
        setResults(
          _results.map((d: QuizResult, i: number) => ({ ...d, number: i + 1 }))
        );
        return _results;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  useEffect(() => {
    if (quiz.uuid !== '') {
      fetch();
    }
  }, [quiz]);

  return { loading, results, quiz, setResults };
};

export const useStudentResultAnswers = () => {
  const { student, course, module, result } = useStudentResultParam();
  const [loading, setLoading] = useState<boolean>();
  const [answers, setAnswer] = useState<Answer[]>([]);
  const [resultData, setResultData] = useState<QuizResult>();
  const { quiz } = useQuiz();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/students/${student}/courses/${course}/modules/${module}/quizes/${quiz.uuid}/result/${result}/answers`
      )
      .then((_answers) => {
        setAnswer(_answers);
        return _answers;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const fetchResultData = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/students/${student}/courses/${course}/modules/${module}/quizes/${quiz.uuid}/result/${result}`
      )
      .then((_resultData) => {
        setResultData(_resultData);
        return _resultData;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const savePoint = (index: number) => {
    setLoading(true);
    const pointData = {
      ...answers[index],
      student: {
        username: student,
      },
      created: undefined,
      updated: undefined,
    };

    return apiInternal
      .put(
        `/students/${student}/courses/${course}/modules/${module}/quizes/${quiz.uuid}/result/${resultData?.uuid}/questions/${pointData.question?.uuid}/answers`,
        pointData
      )
      .then((data) => {
        message.success('Answer Point Save Success');
        setLoading(false);
        return data;
      })
      .catch((e) => {
        message.error('Answer Point Save Failed');
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (quiz?.uuid !== '') {
      fetchResultData();
      fetch();
    }
  }, [quiz]);

  return { answers, loading, setAnswer, savePoint };
};
