import { Button, Card, Pagination, Table } from 'antd';
import { User, UserWithRole } from 'core.types/identity';
import { useUser, useUsers } from 'hooks/users';
import UserModalForm from './components/modal.form';

export const UserList = () => {
  const {
    users,
    pagination: { page, size },
    loading,
    setPagination,
  } = useUsers();
  const { setUser, setModalOpen, modalOpen } = useUser();
  const usersColumn = [
    {
      title: 'Name',
      render: (user: User) => user.info.fullname,
    },
    {
      title: 'Role',
      render: (user: UserWithRole) =>
        user.role && user.role.split(':')[1].toUpperCase(),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      render: (user: User) => user.info.phone_number,
    },
    {
      title: 'Action',
      render: (user: UserWithRole) => {
        return (
          <>
            <Button
              onClick={() => {
                setUser(user);
                setModalOpen(true);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const onVisibilityChange = (v) => {
    setModalOpen(v);
  };
  return (
    <>
      <Card
        loading={loading}
        extra={
          <div>
            <Button onClick={() => false} style={{ marginRight: 10 }}>
              Export
            </Button>
            <Button type="primary" onClick={() => setModalOpen(true)}>
              New User
            </Button>
          </div>
        }
      >
        <Table
          columns={usersColumn}
          dataSource={users.data}
          pagination={false}
        />
        <Pagination
          onChange={(page) => {
            setPagination({
              page,
              size,
            });
          }}
          pageSize={size}
          current={page}
          total={users.pagination.total_size}
          showSizeChanger
          onShowSizeChange={(_current, _size) => {
            setPagination({
              size: _size,
              page,
            });
          }}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pageSizeOptions={['10']}
        />
      </Card>
      <UserModalForm visible={modalOpen} setVisible={onVisibilityChange} />
    </>
  );
};

export default UserList;
