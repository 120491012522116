import { FC, ReactNode } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDocument } from '../../hooks/document';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  UserOutlined,
  PercentageOutlined,
  AuditOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import './sider.scss';
import SubMenu from 'antd/lib/menu/SubMenu';
const { Sider: AntdSider } = Layout;

export interface MenuItemProps {
  to: string;
  title: string;
  icon?: ReactNode;
}

const Sider: FC = () => {
  const history = useHistory();
  const { height } = useDocument();
  return (
    <AntdSider
      style={{
        height,
      }}
    >
      <Menu
        style={{
          height,
        }}
        className={`pz-navbar-menu`}
        mode="inline"
        defaultSelectedKeys={['course-list']}
      >
        <Menu.Item
          key={`career-list`}
          icon={<ApartmentOutlined />}
          onClick={() => history.push('/careers')}
        >
          Career
        </Menu.Item>
        <Menu.Item
          key={`course-list`}
          icon={<AppstoreOutlined />}
          onClick={() => history.push('/courses')}
        >
          Course
        </Menu.Item>
        <Menu.Item
          key={`challange-list`}
          icon={<AppstoreOutlined />}
          onClick={() => history.push('/challenges')}
        >
          Challenge
        </Menu.Item>
        <Menu.Item
          key={`webinar-list`}
          icon={<AppstoreOutlined />}
          onClick={() => history.push('/webinars')}
        >
          Werbinar
        </Menu.Item>
        <Menu.Item
          key={`bootcamp-list`}
          icon={<AppstoreOutlined />}
          onClick={() => history.push('/bootcamp')}
        >
          Bootcamp
        </Menu.Item>
        <SubMenu key={`users`} icon={<UserOutlined />} title={'Users'}>
          <Menu.Item key="user-list" onClick={() => history.push('/users')}>
            User List
          </Menu.Item>
          <Menu.Item key="user-role" onClick={() => history.push('/users')}>
            User Role
          </Menu.Item>
          <Menu.Item key="activity-log" onClick={() => history.push('/users')}>
            Activity Log
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key={`coupon-list`}
          icon={<PercentageOutlined />}
          onClick={() => history.push('/coupons')}
        >
          Coupon
        </Menu.Item>
        <Menu.Item
          key={`certificate-creation-page`}
          icon={<AuditOutlined />}
          onClick={() => history.push('/certificate')}
        >
          Certificate
        </Menu.Item>
        <SubMenu key={`packages`} icon={<FolderOutlined />} title={'Package'}>
          <Menu.Item
            key="package-benefit"
            onClick={() => history.push('/package-benefits')}
          >
            Package Benefits
          </Menu.Item>
          <Menu.Item key="packages" onClick={() => history.push('/packages')}>
            Package
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key={`coupon-list`}
          icon={<FolderOutlined />}
          onClick={() => history.push('/coupons')}
        >
          Webinar Category
        </Menu.Item>
      </Menu>
    </AntdSider>
  );
};

export default Sider;
