import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLoading, usePageSize } from './common';
import Course from '../../../src/core/courses/course';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import {
  Bootcamp,
  Material,
  Person,
  Price,
  ScheduleTime,
  SocialLinks,
  Testimonial,
} from 'core.types/bootcamp';
import { CourseStatus } from 'core.types/courses';
import { Paginated } from 'core.types/core.types';
import { message } from 'antd';
import { User } from 'core.types/identity';
import { useUser } from './users';

export enum SocialMedia {
  LINKEDIN = 'linkedin',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  GITHUB = 'github',
  GITLAB = 'gitlab',
}

export enum CourseType {
  VIDEO_COURSE = 'video_course',
  BOOTCAMP = 'bootcamp',
}

export interface InitialCourseDataType extends Course {
  type: CourseType;
}

export const useBootcampParam = () => useParams<{ bootcamp: string }>();

export const initialBootcampData: Bootcamp = {
  id: '',
  name: '',
  description: '',
  subname: '',
  cover: '',
  thumbnail: '',
  about: '',
  schedule: {
    description: '',
    schedule_times: [] as ScheduleTime[],
  },
  learning_methods: [],
  assesment_components: [],
  materials: {
    cover: '',
    caption: '',
    item: [] as Material[],
  },
  mentor: {} as Person,
  prices: [] as Price[],
  testimonials: [] as Testimonial[],
  whatsapp_link: '',
  is_enrolled: false,
  finished: false,
  tutor: '',
  status: CourseStatus.DEACTIVATED,
  is_coming_soon: true,
  start_to_end_date: '',
  is_displayed: false,
};

export const useBootcamps = () => {
  const [loading, setLoading] = useLoading();
  const [pagination, setPagination] = usePageSize();

  const { page, size } = pagination;

  const [fParam, setParam] = useState<{
    page?: number;
    size?: number;
    keyword?: string | undefined;
  }>({
    page: 1,
    size: 10,
    keyword: undefined,
  });

  const [bootcamps, setBootamps] = useState<Paginated<Bootcamp>>({
    data: [],
    pagination: {
      ...pagination,
      total_page: 0,
      total_size: 0,
    },
  });
  const fetch = () => {
    setLoading(true);
    const p = {
      page: fParam.page ? fParam.page : page,
      size: 1000, // fParam.size ? fParam.size : size,
      keyword: fParam.keyword,
    };
    return apiInternal
      .get(`/bootcamp?${qs.stringify(p)}`)
      .then((_bootcamp: Paginated<Bootcamp>) => {
        setBootamps(_bootcamp);
        return _bootcamp;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    fetch();
  }, [fParam, pagination]);
  return {
    bootcamps,
    loading,
    fetch,
    pagination: {
      page,
      size,
    },
    setPagination,
    setParam,
  };
};

export const useBootcamp = () => {
  const [bootcamp, setBootcamp] = useState<Bootcamp>(initialBootcampData);
  const [loading, setLoading] = useLoading();
  const { get: getUser } = useUser();
  const params = useBootcampParam();

  const save = async (): Promise<Bootcamp | undefined> => {
    setLoading(true);

    const courseData: InitialCourseDataType = {
      tutor: bootcamp.tutor.replace(/\s+/g, '-').toLowerCase(), // replace whitespace with (-) on tutor name,
      career: [],
      status: bootcamp.status,
      type: CourseType.BOOTCAMP,
      name: bootcamp.name,
      description: bootcamp.description,
      overview: '',
      uuid: undefined,
      sessions: bootcamp.sessions,
      extras: {
        active_price:
          bootcamp.prices.length > 0 ? bootcamp.prices[0].price : undefined,
        price:
          bootcamp.prices.length > 0
            ? bootcamp.prices[0].origin_price
            : undefined,
      },
      start_date: bootcamp.start_date,
      end_date: bootcamp.end_date,
    };

    if (!bootcamp.tutor) {
      return new Promise<undefined>((resolve, reject) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('tutor not filled');
        message.error('Tutor Not Filled');
      }).finally(() => setLoading(false));
    }

    let bootcampData: Bootcamp = {
      ...bootcamp,
      sessions: undefined,
    };

    const user: User = await getUser(bootcamp.mentor?.username);
    const mentor: Person = {
      username: user?.info ? user?.username || ('' as string) : '',
      picture: user?.info ? user?.info.avatar || ('' as string) : '',
      name: user?.info ? user?.info.fullname || ('' as string) : '',
      job: user?.info ? user?.info.job || ('' as string) : '',
      social: [] as SocialLinks[],
    };

    let fn;

    // set bootcamp end date string if it's status is comingsoon
    bootcampData.start_to_end_date = bootcampData.is_coming_soon
      ? ''
      : bootcampData.start_to_end_date;

    if (params.bootcamp === 'new') {
      fn = apiInternal
        .post(`/tutor/${courseData.tutor}/courses`, courseData)
        .then((res: Course) => {
          // set recent course uuid to bootcamp uuid
          bootcampData = {
            ...bootcampData,
            uuid: res.uuid,
            id: res.uuid,
            mentor: bootcampData.mentor?.username ? mentor : null,
          };
          const fn = apiInternal.post(`/bootcamp`, bootcampData);
          return fn;
        })
        .catch((e) => {
          console.log(e);
          return undefined;
        });
    } else {
      fn = apiInternal
        .put(`/tutor/${courseData.tutor}/courses/${bootcamp.id}`, courseData)
        .then((res: Course) => {
          // set recent course uuid to bootcamp uuid
          bootcampData = {
            ...bootcampData,
            uuid: res.uuid,
            mentor: bootcampData.mentor?.username ? mentor : null,
          };
          const fn = apiInternal.put(
            `/bootcamp/${params.bootcamp}`,
            bootcampData
          );
          return fn;
        })
        .catch((e) => {
          console.log(e);
          return undefined;
        });
    }
    return fn.finally(() => setLoading(false));
  };
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/bootcamp/${params.bootcamp}`)
      .then((_bootcamp: Bootcamp) => {
        setBootcamp(_bootcamp);
        return _bootcamp;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const remove = () => {
    setLoading(true);

    return apiInternal
      .delete(`/bootcamp/${bootcamp.id}`)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return {
    loading,
    setLoading,
    bootcamp,
    setBootcamp,
    save,
    remove,
    get,
  };
};
