import { FC } from 'react';
import { Menu } from 'antd';
import './navigation.scss';
import UserInfo from './components/user.info';

const Navigation: FC = () => {
  return (
    <>
      <Menu mode="horizontal">
        <div className={`pz-navbar-logo`}>PZ admin portal</div>
        <UserInfo />
      </Menu>
    </>
  );
};

export default Navigation;
