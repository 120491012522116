import { Card, Table } from 'antd';
import { QuizResult } from 'core.types/enrollment/enrollment.quiz.results';
import { useQuiz } from 'hooks/quiz';
import { useStudentParam } from 'hooks/student';
import { useStudentResults } from 'hooks/student.result';
import { Link } from 'react-router-dom';
export const StudentResult = () => {
  const { student: studentParam, course, module } = useStudentParam();
  const { results, loading } = useStudentResults();
  const { quiz } = useQuiz();

  const questionColumn = [
    {
      title: 'No',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Action',
      render: (q: QuizResult) => (
        <>
          <Link
            to={`/students/${studentParam}/courses/${course}/modules/${module}/quiz/${quiz.uuid}/result/${q.uuid}`}
            style={{ marginLeft: 10 }}
          >
            Show Answer
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Card
        title={`${studentParam}'s Results on Module ${quiz.module.name}`}
        loading={loading}
      >
        <Table
          columns={questionColumn}
          dataSource={results}
          pagination={false}
        />
      </Card>
    </>
  );
};

export default StudentResult;
