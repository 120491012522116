import { useEffect } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { Benefit } from 'core.types/billing';
import { usePackageBenefitStore } from 'store/hooks/package.benefit';

export const initialBenefitData: Benefit = {
  benefit: '',
};

export const usePackageBenefits = () => {
  const {
    packageBenefits,
    action: { setPackageBenefits },
  } = usePackageBenefitStore();
  const [loading, setLoading] = useLoading();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get('/billing/benefits')
      .then((_b: Benefit[]) => {
        setPackageBenefits(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetch();
  }, []);
  return { packageBenefits, loading, fetch };
};

export const usePackageBenefit = () => {
  const {
    packageBenefit,
    action: { setPackageBenefit },
  } = usePackageBenefitStore();
  const [loading, setLoading] = useLoading();
  const get = (benefitUUID: string) => {
    setLoading(true);
    return apiInternal
      .get(`/billing/benefits/${benefitUUID}`)
      .then((_b: Benefit) => {
        setPackageBenefit(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = () => {
    setLoading(true);
    const req = packageBenefit.uuid
      ? apiInternal.put(
          `/billing/benefits/${packageBenefit.uuid}`,
          packageBenefit
        )
      : apiInternal.post(`/billing/benefits`, packageBenefit);

    return req
      .then((_b: Benefit) => {
        setPackageBenefit(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const remove = (benefitUUID: string) => {
    setLoading(true);
    return apiInternal
      .delete(`/billing/benefits/${benefitUUID}`)
      .then((_b: Benefit) => {
        setPackageBenefit(_b);
        return _b;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  return { packageBenefit, loading, get, save, setPackageBenefit, remove };
};
