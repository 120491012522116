import { Form, Checkbox as AntdCheckbox } from 'antd';
import { FC } from 'react';
import { InputProps } from './input';

export const CheckBox: FC<InputProps> = ({
  value,
  onChange,
  readonly,
  label,
}) => {
  return (
    <Form.Item label={label}>
      <AntdCheckbox
        disabled={readonly}
        checked={value}
        onChange={({ target: { checked } }) => onChange && onChange(checked)}
      ></AntdCheckbox>
    </Form.Item>
  );
};

export default AntdCheckbox;
