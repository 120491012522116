import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebinarCategory } from 'core.types/webinar.category';

export const webinarCategoryInitialData: WebinarCategory = {
  name: '',
};

export const webinarCategories: WebinarCategory[] = [];

export interface CouponStoreIface {
  loading: boolean;
  webinarCategory: WebinarCategory;
  webinarCategories: WebinarCategory[];
}

const initialState: CouponStoreIface = {
  loading: false,
  webinarCategory: webinarCategoryInitialData,
  webinarCategories: webinarCategories,
};

export const slice = createSlice({
  name: 'couponn',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setWebinarCategory: (state, action: PayloadAction<WebinarCategory>) => {
      state.webinarCategory = action.payload;
    },
    setWebinarCategories: (state, action: PayloadAction<WebinarCategory[]>) => {
      state.webinarCategories = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setWebinarCategory, setWebinarCategories } =
  slice.actions;

export default slice.reducer;
