import { Button, Row, Col } from 'antd';
import Input, { TextArea } from 'components/input';
import { Person, Testimonial } from 'core.types/bootcamp';
import { FC, useEffect, useState } from 'react';

export interface BootcampTestimonialsProps {
  value: any;
  onChange?: (v) => void;
}

export const BootcampTestimonials: FC<BootcampTestimonialsProps> = ({
  value,
  onChange,
}) => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const onFieldChange = (v: any, pName: keyof Testimonial, i: number) => {
    const newArr = [...testimonials];
    const m = { ...newArr[i], [pName]: v };
    newArr[i] = m;
    setTestimonials(newArr);
  };
  useEffect(() => {
    onChange && onChange(testimonials);
  }, [testimonials]);

  useEffect(() => {
    setTestimonials(value);
  }, []);
  /**
   * => testimonials -> array of object { # unknown # }
   */

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={4} style={{ display: 'flex' }}>
          <span style={{ marginLeft: 'auto', marginRight: 10 }}>
            Testimonials:
          </span>
        </Col>
        <Col span={20} style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              setTestimonials([
                ...testimonials,
                {
                  review: '',
                  reviewer: {
                    name: '',
                    job: '',
                  } as Person,
                },
              ]);
            }}
          >
            Add Testimonial
          </Button>
        </Col>
      </Row>
      {testimonials.map((value, i) => {
        return (
          <div key={i}>
            <TextArea
              label={`Review`}
              value={value.review}
              onChange={(v) => onFieldChange(v, 'review', i)}
            />
            <Input
              label={`Reviewer`}
              value={value.reviewer.name}
              onChange={(v) =>
                onFieldChange({ ...value.reviewer, name: v }, 'reviewer', i)
              }
            />
            <Input
              label={`Reviewer Job`}
              value={value.reviewer.job}
              onChange={(v) =>
                onFieldChange({ ...value.reviewer, job: v }, 'reviewer', i)
              }
            />
            <Row>
              <Col offset={4} span={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      const m = [...testimonials];
                      m.splice(i, 1);
                      setTestimonials(m);
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default BootcampTestimonials;
