import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { Level } from '../../../src/core/careers/career';
import { apiInternal } from '../service/api.internal';
import { useCareerParam } from './career';
import { useGlobalState } from '../context';
import { message } from 'antd';
export enum CourseTypeParam {
  CourseType,
}
export const useLevels = (clearOnFailure = false) => {
  const [levels, setLevels] = useState<Level[]>([]);
  const [loading, setLoading] = useLoading();

  const params = useCareerParam();

  const fetch = (careerName?: string) => {
    setLoading(true);
    return apiInternal
      .get(`/careers/${careerName || params.career}/levels`)
      .then((_levels: Level[]) => {
        setLevels(_levels);
        return _levels;
      })
      .catch(() => {
        clearOnFailure && setLevels([]);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetch();
  }, []);
  return { levels, loading, fetch };
};

const useGlobalLevel = () =>
  useGlobalState<Level>('global.level', { number: 1 } as Level);

export const useLevel = () => {
  const [level, setLevel] = useGlobalLevel();
  const [loading, setLoading] = useLoading();
  const params = useCareerParam();
  const save = () => {
    setLoading(true);
    return apiInternal
      .post(`careers/${params.career}/levels`, level)
      .then((_level: Level) => {
        setLevel(_level);
        return _level;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const remove = () => {
    setLoading(true);
    return apiInternal
      .delete(`careers/${params.career}/levels/${level.uuid}`)
      .then(() => {
        return true;
      })
      .catch((e) => {
        if (e.code === 400) {
          message.error(e.data);
        }
        return false;
      })
      .finally(() => setLoading(false));
  };
  return { level, setLevel, loading, save, remove };
};
