import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserWithRole } from 'core.types/identity';

export const userInititialData: UserWithRole = {
  username: '',
  email: '',
  created: new Date(),
  updated: new Date(),
  info: {
    fullname: '',
    birthdate: '',
    family_name: '',
    gender: '',
    given_name: '',
    locale: '',
    middle_name: '',
    name: '',
    nickname: '',
    phone_number: '',
    phone_number_verified: false,
    picture: '',
    preferred_username: '',
    website: '',
    zoneinfo: '',
    avatar: '',
    address: {
      locality: '',
      region: '',
      postal_code: '',
      country: '',
    },
    email: '',
    email_verified: true,
    job: '',
    education: {
      grade: '',
      is_graduated: false,
      school_name: '',
      majors: '',
    },
    description: '',
    social: undefined,
  },
  role: '',
};

export interface CouponStoreIface {
  loading: boolean;
  modalOpen: boolean;
  user: UserWithRole;
  userRole: string;
  userAvatar: Buffer | undefined;
}

const initialState: CouponStoreIface = {
  loading: false,
  modalOpen: false,
  user: userInititialData,
  userRole: '',
  userAvatar: undefined,
};

export const slice = createSlice({
  name: 'couponn',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modalOpen = action.payload;
    },
    setUser: (state, action: PayloadAction<UserWithRole>) => {
      state.user = action.payload;
    },
    setUserRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },
    setUserAvatar: (state, action: PayloadAction<Buffer | undefined>) => {
      state.userAvatar = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setModalOpen, setUser, setUserRole, setUserAvatar } =
  slice.actions;

export default slice.reducer;
