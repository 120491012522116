import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Image, message, Upload } from 'antd';
import { useGlobalState } from 'context';
import { useCourse } from 'hooks/course';
import { useState } from 'react';

export const CourseStepCover = () => {
  const { loading, setCoverData, saveCover, course, get } = useCourse();
  const [, stepDispatch] = useGlobalState('course.step', 0);
  const [uploadFileList, setUploadFileList] = useState<any[]>([]);
  const _save = async () => {
    const s = await saveCover();
    if (s) {
      stepDispatch(2);
      get();
    }
  };
  const onChangeCover = ({ file, fileList }) => {
    if (file.status !== 'removed' && file.size / 1024 / 1024 >= 2) {
      message.error('File size must smaller than 2MB');
    } else {
      if (file.status !== 'removed') {
        setUploadFileList(fileList);
        setCoverData(file);
      }
    }
  };
  const onRemoveCover = (file) => {
    const l = uploadFileList.filter((d) => d.uid !== file.uid);
    setUploadFileList(l);
  };
  const prevStep = () => {
    stepDispatch(0);
  };
  return (
    <Card loading={loading}>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 24 }}>
        {course.cover && (
          <Image
            width={200}
            src={`/assets/files/courses${course.cover}?t=${Date.now()}`}
          />
        )}
        <Form.Item name="upload_cover" label="Upload Cover">
          <Upload
            name="logo"
            listType="picture"
            accept="image/*"
            maxCount={1}
            beforeUpload={() => false}
            fileList={uploadFileList}
            onChange={onChangeCover}
            onRemove={onRemoveCover}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Next
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
          Previous
        </Button>
      </Form>
    </Card>
  );
};

export default CourseStepCover;
