export enum ChallengeStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type ChallengeStatusParam = ChallengeStatus;

export const challengeStatusLabel = {
  [ChallengeStatus.ACTIVE]: 'active',
  [ChallengeStatus.INACTIVE]: 'inactive',
};

export interface Challenge {
  name: string;
  description: string;
  status: ChallengeStatus;
}

// export default Challenge;
