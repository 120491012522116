import { Course } from 'core.types/courses';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setLoading,
  setCourse,
  setCourses,
  setWebinars,
} from 'store/reducers/course';
import { Paginated } from '../../../../src/core/core.types';

export const useCourseStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.course);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setCourse: (course: Course) => dispatch(setCourse(course)),
    setCourses: (courses: Paginated<Course>) => dispatch(setCourses(courses)),
    setWebinars: (webinars: Paginated<Course>) =>
      dispatch(setWebinars(webinars)),
  };

  return { ...states, action };
};
