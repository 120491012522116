import { Button, Card, Form } from 'antd';
import Input from 'components/input';
import Datepicker from 'components/input/date.time.picker';
import { useGlobalState } from 'context';
import { CourseType, Metadata } from 'core.types/courses';
import { useCourse } from 'hooks/course';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Course } from '../../../../../src/core/courses';

export const CourseMetadata = () => {
  const { course, setCourse, loading, save } = useCourse();
  const [, stepDispatch] = useGlobalState('course.step', 0);
  const [courseType, setCourseType] = useState<CourseType>(
    CourseType.VIDEO_COURSE
  );
  const courseChange = (v: any, pName: keyof Course) => {
    setCourse({
      ...course,
      [pName]: v,
    });
  };
  const history = useHistory();

  useEffect(() => {
    const courseType = history.location.pathname
      .split('/')[1]
      .slice(0, -1) as CourseType;
    setCourseType(courseType);
  }, [history]);

  const _save = async () => {
    const c = await save();
    if (c) {
      history.push(
        `/${courseType === CourseType.WEBINAR ? 'webinars' : 'courses'}`
      );
      stepDispatch(0);
    }
  };

  const cancelButtonClick = () => {
    stepDispatch(2);
    history.goBack();
  };

  const metadataChange = (v: any, pName: keyof Metadata) => {
    const extras = {
      ...course.extras,
      metadata: { ...course.extras.metadata, [pName]: v },
    };
    courseChange(extras, 'extras');
  };

  return (
    <Card loading={loading}>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Issuer Role`}
          value={course.extras.metadata && course.extras.metadata.issuer_role}
          onChange={(v) => metadataChange(v, 'issuer_role')}
        />
        <Input
          label={`Title`}
          value={course.extras.metadata && course.extras.metadata.title}
          onChange={(v) => metadataChange(v, 'title')}
        />
        <Input
          label={`Event Label`}
          value={course.extras.metadata && course.extras.metadata.event_label}
          onChange={(v) => metadataChange(v, 'event_label')}
        />
        <Input
          label={`Event Link`}
          value={course.extras.metadata && course.extras.metadata.event_link}
          onChange={(v) => metadataChange(v, 'event_link')}
        />
        <Datepicker
          label="Start Date"
          value={course.extras.metadata && course.extras.metadata.start_date}
          onChange={(v) => metadataChange(v, 'start_date')}
        />
        <Datepicker
          label="End Date"
          value={course.extras.metadata && course.extras.metadata.end_date}
          onChange={(v) => metadataChange(v, 'end_date')}
        />
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Done
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
          Cancel
        </Button>
      </Form>
    </Card>
  );
};

export default CourseMetadata;
