import { Card, Spin } from 'antd';
import { FC } from 'react';
import QuestionFormDetail from './components/form.detail';

const QuestionForm: FC = () => {
  return (
    <Card loading={false} title={`Quiz Question`}>
      {false && <Spin />}
      <QuestionFormDetail />
    </Card>
  );
};

export default QuestionForm;
