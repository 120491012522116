import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Table, Form, message, Spin } from 'antd';
import { Bootcamp } from 'core.types/bootcamp';
import { useHomeCourses } from 'hooks/homepage';
import CourseAutocomplete from './bootcamp.autocomplete';
export const BootcampAtHome = () => {
  const { homeCourses, onDataChange, save, loading } = useHomeCourses();
  const courseColumns = [
    {
      title: 'Bootcamp Name',
      width: '80%',
      render: (bootcamp: Bootcamp, item, index) => {
        return (
          <>
            <Form.Item wrapperCol={{ span: 24 }}>
              <CourseAutocomplete
                value={bootcamp.name}
                onChange={(v) => {
                  const c = [...homeCourses.bootcamps];
                  c[index] = v;
                  onDataChange(c, 'bootcamps');
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string, item, index) => (
        <>
          <Button
            danger
            onClick={() => {
              const c = [...homeCourses.bootcamps];
              c.splice(index, 1);
              onDataChange(c, 'bootcamps');
            }}
          >
            Remove
          </Button>
        </>
      ),
    },
  ];

  const _save = async () => {
    const c = await save();
    if (c) {
      message.success('Homepage Bootcamps Saved');
    }
  };
  const addBootcamp = () => {
    const c = [...homeCourses.bootcamps];
    c.push({
      uuid: '',
      name: '',
      description: '',
    } as Bootcamp);
    onDataChange(c, 'bootcamps');
  };
  return (
    <Card
      title={`Bootcamps at Homepage`}
      style={{ marginTop: 20 }}
      loading={loading}
      extra={
        <>
          <Button style={{ marginRight: 10 }} onClick={_save}>
            Save
          </Button>
          <Button type="primary" icon={<PlusOutlined />} onClick={addBootcamp}>
            Add
          </Button>
        </>
      }
    >
      {loading && <Spin />}
      <Table columns={courseColumns} dataSource={homeCourses.bootcamps} />
    </Card>
  );
};

export default BootcampAtHome;
