import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import { Certificate, Course } from 'core.types/courses';
import { useCourse } from './course';
import { message } from 'antd';

export const initialCertificateData: Certificate = {
  course: {} as Course,
  weight_goal: 0,
  format: '',
  caption: '',
};

export const useCertificateParam = () =>
  useParams<{ course: string; certificate: string }>();

export const useCertificates = () => {
  const certificateParams = useCertificateParam();
  const [loading, setLoading] = useLoading();
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(`/courses/${certificateParams.course}/certificates`)
      .then((_certificates) => {
        setCertificates(_certificates);
        return _certificates;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  return {
    certificates,
    loading,
    fetch,
  };
};

export const useCertificate = () => {
  const { course } = useCourse();
  const [certificate, setCertificate] = useState<Certificate>(
    initialCertificateData
  );
  const [loading, setLoading] = useLoading();
  const certificateParams = useCertificateParam();

  const initParams = { courseUUID: '', certificateUUID: '' };
  const get = (params = initParams) => {
    setLoading(true);

    const url =
      params !== initParams
        ? `/courses/${params.courseUUID}/certificates/${params.certificateUUID}`
        : `/courses/${certificateParams.course}/certificates/${certificateParams.certificate}`;

    return apiInternal
      .get(url)
      .then((_certificate) => {
        setCertificate(_certificate);
        return _certificate;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = (): Promise<Certificate | undefined> => {
    setLoading(true);

    const clearCertificateData = {
      ...certificate,
    };

    const fn =
      certificateParams.certificate === 'new'
        ? apiInternal.post(
            `/tutor/${course.tutor}/courses/${certificateParams.course}/certificates`,
            { ...clearCertificateData, course: course }
          )
        : apiInternal.put(
            `/tutor/${course.tutor}/courses/${certificateParams.course}/certificates/${certificate.uuid}`,
            clearCertificateData
          );

    const messageLabel =
      certificateParams.certificate === 'new' ? 'Added' : 'Updated';
    return fn
      .then((d) => {
        message.success(`Certified ${messageLabel}`);
        return d;
      })
      .catch((e) => {
        message.error(`Certified Failed to ${messageLabel}`);
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  const remove = () => {
    setLoading(true);

    return apiInternal
      .delete(
        `tutor/${certificate.course.tutor}/courses/${certificate.course.uuid}/certificates/${certificate.uuid}`
      )
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return {
    certificate,
    loading,
    get,
    save,
    remove,
    setLoading,
    setCertificate,
    certificateParams,
  };
};
