import { Card, Form, Button } from 'antd';
import Input from 'components/input';
import { useHistory } from 'react-router';
import RichTextEditor, {
  AvailablePlugins,
} from 'components/input/ckeditor/ckeditor';
import { useEffect, useState } from 'react';
import { useManualCertificate } from 'hooks/manual.certificate';
import { ManualCertificate } from 'core.types/certificate';
import Datepicker from 'components/input/date.time.picker';
import UserAutocomplete from 'pages/coupon/components/user.autocomplete';
import GeneratedCertificateList from './components/certificate.list';

export const CertificateCreationForm = () => {
  const history = useHistory();
  const { certificate, loading, setCertificate, generate } =
    useManualCertificate();
  const [richEditorValues, setRichEditorValues] = useState<
    Partial<ManualCertificate>
  >({
    format: '',
  });
  const certficateChange = (v: any, pName: keyof ManualCertificate) => {
    setCertificate({ ...certificate, [pName]: v });
  };
  const ckEditorValueChange = (v: any, pName: keyof ManualCertificate) => {
    setRichEditorValues({ ...richEditorValues, [pName]: v });
  };

  /**
   * value handling of CKEDITOR component is little bit different
   * due to unrecognized react state bug
   */
  useEffect(() => {
    setCertificate({
      ...certificate,
      format: richEditorValues.format as string,
    });
  }, [richEditorValues.format]);

  const _save = async () => {
    await generate();
  };

  const backButtonClick = () => {
    history.goBack();
  };

  return (
    <>
      <GeneratedCertificateList />
      <Card title={`Certificate Generator`}>
        <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <RichTextEditor
            label={`Format`}
            value={null}
            onChange={(v) => {
              ckEditorValueChange(v, 'format');
            }}
            plugins={[AvailablePlugins.IMAGE_ASSETS]}
            imageUploadPath="/files/bucket/certificate-assets"
          />
          <Input
            label={`Certificate ID`}
            value={certificate.uuid}
            onChange={(v) => certficateChange(v, 'uuid')}
          />
          <Input
            label={`Name`}
            value={certificate.name}
            onChange={(v) => certficateChange(v, 'name')}
          />
          <Input
            label={`Issuer Role`}
            value={certificate.data.issuer_role}
            onChange={(v) =>
              certficateChange({ ...certificate.data, issuer_role: v }, 'data')
            }
          />
          <Input
            label={`Issuer Name`}
            value={certificate.data.issuer_name}
            onChange={(v) =>
              certficateChange({ ...certificate.data, issuer_name: v }, 'data')
            }
          />
          <Input
            label={`Issued Certificate Name`}
            value={certificate.data.issued_certificate_name}
            onChange={(v) =>
              certficateChange(
                { ...certificate.data, issued_certificate_name: v },
                'data'
              )
            }
          />
          <Datepicker
            label={`Issued Date`}
            value={certificate.data.issued_date}
            onChange={(v) =>
              certficateChange({ ...certificate.data, issued_date: v }, 'data')
            }
          />
          <Input
            label={`Title`}
            value={certificate.data.title}
            onChange={(v) =>
              certficateChange({ ...certificate.data, title: v }, 'data')
            }
          />
          <Input
            label={`Event Label`}
            value={certificate.data.event_label}
            onChange={(v) =>
              certficateChange({ ...certificate.data, event_label: v }, 'data')
            }
          />
          <Input
            label={`Event Link`}
            value={certificate.data.event_link}
            onChange={(v) =>
              certficateChange({ ...certificate.data, event_link: v }, 'data')
            }
          />
          <Datepicker
            label={`Start Date`}
            value={certificate.data.start_date}
            onChange={(v) =>
              certficateChange({ ...certificate.data, start_date: v }, 'data')
            }
          />
          <Datepicker
            label={`End Date`}
            value={certificate.data.end_date}
            onChange={(v) =>
              certficateChange({ ...certificate.data, end_date: v }, 'data')
            }
          />
          <Input
            label={`Caption`}
            value={certificate.data.caption}
            onChange={(v) =>
              certficateChange({ ...certificate.data, caption: v }, 'data')
            }
          />
          <Input
            label={`Public Domain`}
            value={certificate.data.public_domain}
            onChange={(v) =>
              certficateChange(
                { ...certificate.data, public_domain: v },
                'data'
              )
            }
          />
          <UserAutocomplete
            label={`Owner`}
            value={certificate.owner}
            onChange={(v) => certficateChange(v, 'owner')}
          />
          <Button
            style={{ margin: '0 8px 0 0' }}
            type="primary"
            loading={loading}
            onClick={_save}
          >
            Create Certificate
          </Button>
          <Button onClick={() => backButtonClick()}>Back</Button>
        </Form>
      </Card>
    </>
  );
};

export default CertificateCreationForm;
