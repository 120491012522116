import { message } from 'antd';
import { Profile } from 'core.types/identity';
import { useState } from 'react';
import { apiInternal } from '../service/api.internal';
import { useLoading } from './common';

export interface LoginField {
  username: string;
  password: string;
}

export interface ProfileData extends Profile {
  username: string;
}

const fieldInitial = {
  username: '',
  password: '',
};

const storeAuthData = (data: any) => ({
  ...data.extras,
  at_hash: undefined,
  aud: undefined,
  auth_time: undefined,
  jti: undefined,
  nonce: undefined,
  sid: undefined,
  username: data.username,
});

export const useUser = (): ProfileData => {
  // eslint-disable-next-line no-undef
  const user = localStorage.getItem('user'); // string
  return user ? JSON.parse(user) : null;
};

export const useAuth = () => {
  const [credentials, setCredentials] = useState<LoginField>(fieldInitial);
  const [loading, setLoading] = useLoading();

  const login = () => {
    setLoading(true);
    return apiInternal
      .post(`/openid/login`, credentials)
      .then((data) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem('user', JSON.stringify(storeAuthData(data)));
        message.success('Login Success');
        return data;
      })
      .catch((e) => {
        message.error('Login Failed');
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    setLoading(true);
    return apiInternal
      .get(`/openid/logout`)
      .then((data) => {
        // eslint-disable-next-line no-undef
        localStorage.removeItem('user');
        message.success('Logout Success');
        return data;
      })
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setLoading(false));
  };

  return {
    login,
    logout,
    credentials,
    loading,
    setCredentials,
  };
};
