import { message } from 'antd';
import { useGlobalState } from 'context';
import { Paginated } from 'core.types/core.types';
import { UserWithRole } from 'core.types/identity';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { apiInternal } from 'service/api.internal';
import { userInititialData } from 'store/reducers/user';
import { useUserStore } from 'store/hooks/user';
import { usePageSize } from './common';

export const useUsers = () => {
  const [pagination, setPagination] = usePageSize();
  const { page, size } = pagination;
  const [users, setUsers] = useGlobalState<Paginated<UserWithRole>>(
    'user.list',
    {
      data: [],
      pagination: {
        ...pagination,
        total_page: 0,
        total_size: 0,
      },
    }
  );
  const [loading, setLoading] = useState<boolean>();

  const fetch = () => {
    setLoading(true);
    const p = {
      page,
      size,
    };
    return apiInternal
      .get(`/users?${qs.stringify(p)}`)
      .then((_users: Paginated<UserWithRole>) => {
        setUsers(_users);
        return _users;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    fetch();
  }, [pagination]);

  return {
    loading,
    users,
    setUsers,
    fetch,
    pagination: {
      page,
      size,
    },
    setPagination,
  };
};

export const useUser = () => {
  const {
    modalOpen,
    user,
    userRole,
    loading,
    action: { setModalOpen, setUser, setUserRole, setLoading },
  } = useUserStore();
  useEffect(() => {
    setUserRole(user.role && user.role.split(':')[1]);
  }, [user]);

  const get = (username?: string) => {
    setLoading(true);
    return apiInternal
      .get(`/users/${username || user?.username}`)
      .then(async (d) => {
        return d;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const save = () => {
    setLoading(true);
    const userData = { ...user, info: { ...user.info, avatar: undefined } };

    return apiInternal
      .put(`/users/${user?.username}`, userData)
      .then(async (d) => {
        let t = null;

        if (userRole) {
          const policy = {
            policy: `roles:${userRole}`,
            extras: {},
            subject: `user:${user.username}`,
          };
          t = await apiInternal.post(`/policy/productzilla/roles`, policy);
        }

        if (t) {
          message.success('User Saved');
        }
        setUser(userInititialData);
        setUserRole('');
        return d;
      })
      .catch((e) => {
        message.error('User Failed to Saved');
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const remove = () => {
    setLoading(true);
    return apiInternal
      .put(`/users/${user?.username}`, user)
      .then((d) => {
        message.success('User Saved');
        return d;
      })
      .catch((e) => {
        message.error('User Failed to Saved');
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  const credentialsCheck = (field: string, credentials: string) => {
    return apiInternal
      .get(`/users/exists/${field}/${credentials}`)
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };

  return {
    user,
    setUser,
    save,
    remove,
    loading,
    userRole,
    setUserRole,
    credentialsCheck,
    modalOpen,
    setModalOpen,
    get,
  };
};
