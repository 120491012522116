import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Upload, message, Popconfirm } from 'antd';
import enumToArray from 'common/enumToArray';
import Select from 'components/input/select';
import { Material, Quality } from 'core.types/courses';
import FormData from 'form-data';
import { useModuleAsset } from 'hooks/module.asset';
import { FC, useEffect, useState } from 'react';
import { fileUploadPost, fileDelete } from 'service/file';
import { v4 as uuidv4 } from 'uuid';

export const MaterialForm: FC = () => {
  const { module, setModule, setLoading, loading } = useModuleAsset();
  const [material, setMaterial] = useState<Material[]>([]);
  const initData = {
    key: uuidv4(),
    data: null,
    quality: '' as Quality,
  };

  const handleChange = ({ file }, idx) => {
    const formData = new FormData();
    const quality = material[idx].quality;
    if (quality === ('' as Quality)) {
      message.error('Please select material quality');
      return;
    }

    formData.append('files', file, quality);
    setLoading(true);
    message.info('Start Uploading Material');
    const fn = fileUploadPost(
      `/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${module.uuid}/files`,
      formData
    );
    // eslint-disable-next-line promise/catch-or-return
    fn.then(() => {
      message.success('Material Uploaded');
      return true;
    })
      .catch((e) => {
        message.error('Material Upload Failed');
        console.log(e);
        // eslint-disable-next-line no-useless-return
        return false;
      })
      .finally(() => setLoading(false));
  };

  const onDeleteModuleConfirm = async (key: string) => {
    const d = material.find((d) => d.key === key);
    message.info('Removing Material');
    const fn = await fileDelete(`/files/delete/${d?.url}`)
      .then(() => {
        message.success('Material Removed');
        return true;
      })
      .catch((e) => {
        message.error('Material Remove Failed');
        console.log(e);
        return false;
      })
      .finally(() => setLoading(false));
    if (fn) {
      let m = [...material];
      m = m.filter((d) => d.key !== key);
      setMaterial(m);
      setModule({ ...module, material: m });
    }
  };

  useEffect(() => {
    const m = module.material
      ? module.material.map((d) => ({ ...d, key: uuidv4() }))
      : [];
    module.material && setMaterial(m);
  }, [module.material]);

  return (
    <>
      {material.map((val, i) => {
        return (
          <Row style={{ marginTop: 20 }} key={i}>
            <Col xl={16} style={{ paddingRight: 15 }}>
              <Form.Item>
                <Select
                  onChange={(v) => {
                    const m = material;
                    m[i].quality = v;
                    setMaterial(m);
                  }}
                  label={`Kind`}
                  items={enumToArray(Quality) as any as Quality[]}
                  value={val.quality}
                  renderItem={(item: Quality) => item}
                />
              </Form.Item>
            </Col>
            <Col xl={6} style={{ paddingLeft: 15 }}>
              <Form.Item>
                <Upload
                  name={`files`}
                  action={`/tutor/${module.course.tutor}/courses/${module.course.uuid}/modules/${module.uuid}/files`}
                  onChange={(v) => handleChange(v, i)}
                  beforeUpload={() => false}
                  multiple={false}
                  maxCount={1}
                  defaultFileList={[
                    {
                      ...val,
                      uid: uuidv4(),
                      name: val.quality,
                      url: `/assets/files/courses/${val.url}`,
                    },
                  ]}
                >
                  <Button icon={<UploadOutlined />} loading={loading}>
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xl={2} style={{ paddingLeft: 15 }}>
              <Popconfirm
                title={`Are you sure to delete module material ?`}
                onConfirm={() => onDeleteModuleConfirm(val.key as string)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type={`primary`}
                  block
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Col>
          </Row>
        );
      })}
      <Button
        onClick={() => {
          setMaterial([...material, initData]);
        }}
      >
        Add Material
      </Button>
    </>
  );
};

export default MaterialForm;
