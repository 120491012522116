import { Card, Spin, Steps } from 'antd';
import { useGlobalState } from 'context';
import { useCourse, useCourseParam } from 'hooks/course';
import { useEffect, useState } from 'react';
import CourseStepCareerLevel from './step/course.step.career.level';
import CourseStepCover from './step/course.step.cover';
import CourseStepDetail from './step/course.step.detail';
import CourseMetadata from './step/course.step.metadata';
const { Step } = Steps;
export const CourseFormPage = () => {
  const { course, loading } = useCourse();
  const [step, setStep] = useState(0);
  const param = useCourseParam();
  const [currentStep, setCurrentStep] = useGlobalState('course.step', 0);
  useEffect(() => {
    setCurrentStep(0);
  }, []);

  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  const onStepChange = (step) => {
    setCurrentStep(step);
  };

  const stepProps = {
    onChange: param.course !== 'new' ? onStepChange : undefined,
  };

  return (
    <Card
      title={`Course ${course ? course.name : param.course}`}
      loading={loading}
    >
      {loading && <Spin />}
      <>
        <Steps current={step} {...stepProps}>
          <Step title="Info" description="Course Information." />
          <Step title="Images" description="Image Thumbnails" />
          <Step title="Career Level" description="Assign Career level" />
          <Step title="Metadata" description="Course Metadata" />
        </Steps>
        {step === 0 && <CourseStepDetail />}
        {step === 1 && <CourseStepCover />}
        {step === 2 && <CourseStepCareerLevel />}
        {step === 3 && <CourseMetadata />}
      </>
    </Card>
  );
};

export default CourseFormPage;
