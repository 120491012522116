import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm, Table } from 'antd';
import {
  initialBenefitData,
  usePackageBenefit,
  usePackageBenefits,
} from 'hooks/package.benefit';
import PackageBanefitFormModal from './components/modal.form';
import { useState } from 'react';

export const PackageBenefitList = () => {
  const { packageBenefits, loading, fetch } = usePackageBenefits();
  const { packageBenefit, get, remove, setPackageBenefit } =
    usePackageBenefit();
  const [modalVisible, setModalVisible] = useState(false);

  const packageBenefitColumns = [
    {
      title: 'Benefit',
      dataIndex: 'benefit',
      key: 'benefit',
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string) => (
        <>
          <Button onClick={() => onDetailClicked(uuid)}>Detail</Button>
          <Popconfirm
            title={`Are you sure to delete benefit?`}
            onConfirm={onDeleteCertificateConfirm}
            onCancel={onCancelDeleteCertificateConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onModuleDeletePrompt(uuid)}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const addBenefitButtonClick = () => {
    setPackageBenefit(initialBenefitData);
    setModalVisible(true);
  };

  const onDetailClicked = (benefitUUID: string) => {
    get(benefitUUID);
    setModalVisible(true);
  };
  const onModuleDeletePrompt = (benefitUUID: string) => {
    get(benefitUUID);
  };

  const onDeleteCertificateConfirm = async () => {
    const r = await remove(packageBenefit?.uuid as string);
    if (r) {
      message.success(`Benefit successfully removed!`);
      setPackageBenefit(initialBenefitData);
      fetch();
    }
  };

  const onCancelDeleteCertificateConfirm = () => {
    setPackageBenefit(initialBenefitData);
  };

  return (
    <>
      <Card
        title={`Package Benefit List`}
        loading={loading}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addBenefitButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table
          columns={packageBenefitColumns}
          dataSource={packageBenefits}
          pagination={false}
        />
      </Card>
      <PackageBanefitFormModal
        visible={modalVisible}
        setVisible={(v) => setModalVisible(v)}
      />
    </>
  );
};

export default PackageBenefitList;
