import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Table } from 'antd';
import { useHistory } from 'react-router';
const challengeColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (challenge: string) => challenge.split('-').join(' ').toUpperCase(),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (description: string) => description,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => status,
  },
];
export const ChallengeList = () => {
  const history = useHistory();

  const addChallengeButtonClick = () => {
    history.push(`/challenges/new/form`);
  };

  return (
    <>
      <Card
        title={`Challenge List`}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addChallengeButtonClick}
          >
            Add
          </Button>
        }
      >
        <Table columns={challengeColumns} />
      </Card>
    </>
  );
};

export default ChallengeList;
