import { Form, Input as AntdInput } from 'antd';
import { FC } from 'react';

export enum FeedbackStatuses {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  VALIDATING = 'validating',
  EMPTY = '',
}

export interface InputProps {
  value?: any;
  name?: string;
  label?: string;
  onChange?: (value: any) => void;
  readonly?: boolean;
  placeholder?: string;
  validateStatus?: FeedbackStatuses;
  hasFeedback?: boolean;
  helpText?: string;
  rules?: any;
  defaultValue?: string;
}
export const Input: FC<InputProps> = ({
  value,
  name,
  onChange,
  readonly,
  label,
  placeholder,
  validateStatus,
  hasFeedback,
  helpText,
  rules,
  defaultValue,
}: InputProps) => {
  return (
    <Form.Item
      label={label}
      validateStatus={validateStatus}
      hasFeedback={hasFeedback}
      help={helpText}
      rules={rules}
      name={name}
    >
      <AntdInput
        readOnly={readonly}
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={({ target: { value } }) => onChange && onChange(value)}
      />
    </Form.Item>
  );
};

export default Input;
