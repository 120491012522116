import { useEffect, useState } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';
import { Session } from 'core.types/courses';
import { useCourse } from './course';

export const useModuleSessionParam = () =>
  useParams<{
    tutor: string;
    course: string;
    module: string;
  }>();

export const initialData: Session = {
  label: '',
  instructor: '',
  description: '',
  open: new Date(),
  link: '',
};

export const useModuleSessions = () => {
  const moduleParams = useModuleSessionParam();
  const { course } = useCourse();
  const [loading, setLoading] = useLoading();
  const [sessions, setSessions] = useState<Session[] | undefined>(undefined);
  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get(
        `/tutor/${course.tutor}/courses/${moduleParams.course}/modules/${moduleParams.module}/sessions`
      )
      .then((sessions) => {
        setSessions(sessions);
        return sessions;
      })
      .catch((e) => {
        console.log(e);

        // set empty session
        const courseSessions = course.sessions
          ? course.sessions.map((d, i) => ({
              ...initialData,
              label: course.sessions ? course.sessions[i] : '',
            }))
          : ([] as Session[]);
        setSessions(courseSessions);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  const save = () => {
    return apiInternal
      .put(
        `/tutor/${course.tutor}/courses/${moduleParams.course}/modules/${moduleParams.module}/sessions`,
        sessions
      )
      .then(() => true)
      .catch((e) => {
        console.log(e);
        return undefined;
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    if (course.tutor !== '') {
      fetch();
    }
  }, [course]);
  return {
    course,
    sessions,
    loading,
    fetch,
    save,
    setSessions,
  };
};
