import { Card, Spin, Steps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Course } from 'core.types/courses';
import { useModule, useModuleParam } from '../../hooks/module';
import { useCourse } from 'hooks/course';
import { useGlobalState } from 'context';
import ModuleDetail from './step/module.detail';
import ModuleMaterials from './step/module.material';
import ModuleSession from './step/module.session';
import ModuleAchievement from './step/module.achievement';
const { Step } = Steps;

const ModuleForm: FC = () => {
  const { course } = useCourse();
  const { module, setModule, loading } = useModule();
  const param = useModuleParam();
  const [step, setStep] = useState(0);
  const [currentStep, setCurrentStep] = useGlobalState('module.step', 0);

  useEffect(() => {
    setCurrentStep(0);
  }, []);

  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    setModule({ ...module, course: course as Course });
  }, []);

  const onStepChange = (step) => {
    setCurrentStep(step);
  };

  const stepProps = {
    onChange: param.module !== 'new' ? onStepChange : undefined,
  };

  return (
    <Card loading={loading} title={`Module ${course.name} Add Course Module`}>
      {loading && <Spin />}
      <>
        <Steps current={step} {...stepProps}>
          <Step title="Detail" description="Module Information." />
          <Step title="Sessions" description="Module Sessions." />
          <Step title="Achievements" description="Module Achievements." />
          <Step title="Material" description="Module Material." />
        </Steps>
        {step === 0 && <ModuleDetail />}
        {step === 1 && <ModuleSession />}
        {step === 2 && <ModuleAchievement />}
        {step === 3 && <ModuleMaterials />}
      </>
    </Card>
  );
};

export default ModuleForm;
