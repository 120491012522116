import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  initialCertificateData,
  useCertificate,
  useCertificateParam,
  useCertificates,
} from 'hooks/certificate';

export const CourseCertificateList = () => {
  const { certificates, loading, fetch } = useCertificates();
  const { certificate, get, remove, setCertificate } = useCertificate();
  const { course } = useCertificateParam();
  const history = useHistory();

  const courseCertificateColumns = [
    {
      title: 'Caption',
      dataIndex: 'caption',
      key: 'caption',
    },
    {
      title: 'Weight Goal',
      dataIndex: 'weight_goal',
      key: 'weight_goal',
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string) => (
        <>
          <Link to={`/courses/${course}/certificates/${uuid}/form`}>
            Detail
          </Link>
          <Popconfirm
            title={`Are you sure to delete certificate ${certificate.caption} ?`}
            onConfirm={onDeleteCertificateConfirm}
            onCancel={onCancelDeleteCertificateConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              danger
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onClick={() => onModuleDeletePrompt(course, uuid)}
            >
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const addCourseButtonClick = () => {
    history.push(`/courses/${course}/certificates/new/form`);
  };

  const onModuleDeletePrompt = (course: string, uuid: string) => {
    get({ courseUUID: course, certificateUUID: uuid });
  };

  const onDeleteCertificateConfirm = async () => {
    const r = await remove();
    if (r) {
      message.success(`${certificate.caption} successfully removed!`);
      setCertificate(initialCertificateData);
      fetch();
    }
  };

  const onCancelDeleteCertificateConfirm = () => {
    setCertificate(initialCertificateData);
  };

  return (
    <Card
      title={`Course Certificate List`}
      loading={loading}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={addCourseButtonClick}
        >
          Add
        </Button>
      }
    >
      <Table
        columns={courseCertificateColumns}
        dataSource={certificates}
        pagination={false}
      />
    </Card>
  );
};

export default CourseCertificateList;
