import { useEffect } from 'react';
import { useParams } from 'react-router';
import { apiInternal } from 'service/api.internal';
import { couponInitialData } from 'store/reducers/coupon';
import { useCouponStore } from 'store/hooks/coupon';
import { Coupon } from '../../../src/core/coupon';
import { useUser } from './auth';

export const useCouponParam = () =>
  useParams<{
    coupon: string;
  }>();

export const useCoupons = () => {
  const {
    loading,
    coupons,
    action: { setCoupons, setLoading },
  } = useCouponStore();
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/coupons`)
      .then((_coupons: Coupon[]) => {
        setCoupons(_coupons);
        return _coupons;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  return { setCoupons, coupons, setLoading, loading, get };
};

export const useCoupon = () => {
  const {
    loading,
    coupon,
    action: { setCoupon, setLoading },
  } = useCouponStore();
  const user = useUser();
  const { coupon: cp } = useCouponParam();
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/coupons/creator/${user.username}/code/${cp}`)
      .then((_coupon: Coupon) => {
        setCoupon(_coupon);
        return _coupon;
      })
      .catch((e) => {
        setCoupon(couponInitialData);
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = () => {
    setLoading(true);
    const newCoupon = { ...coupon };
    const code = newCoupon.code;
    delete newCoupon.code;

    const fn =
      code === '' || code === undefined
        ? apiInternal.post(`/coupons/creator/${user.username}`, newCoupon)
        : apiInternal.put(
            `/coupons/creator/${user.username}/code/${code}`,
            newCoupon
          );
    return fn
      .then((_coupon: Coupon) => {
        setCoupon(_coupon);
        return _coupon;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return { setCoupon, coupon, setLoading, loading, get, save };
};
