import { FC } from 'react';
import { Form } from 'antd';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CKEditor, CKEditorEventPayload } from 'ckeditor4-react';
import { useAssets } from 'hooks/assets';

export enum AvailablePlugins {
  IMAGE_ASSETS = 'image-assets',
}

export interface RichTextEditorProps {
  value: any;
  label?: string;
  onChange?: (value: any) => void;
  readonly?: boolean;
  plugins?: AvailablePlugins[];
  imageUploadPath?: string;
}
export const RichTextEditor: FC<RichTextEditorProps> = ({
  value,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  readonly,
  label,
  plugins,
  imageUploadPath,
}: RichTextEditorProps) => {
  const { fetchImageAsset } = useAssets();

  const onBeforeLoad = async (_CKEDITOR) => {
    let imageList = [];
    imageList = await fetchImageAsset('/fetch/bucket/certificate-assets/');

    _CKEDITOR.plugins.add('image-assets', {
      init: function (editor) {
        editor.ui.addRichCombo('image-assets', {
          label: 'Image Asset',
          title: 'image-assets',
          toolbar: 'insert',

          panel: {
            multiSelect: false,
            attributes: {
              'aria-label': 'Image Asset',
              class: 'ck-image-asset',
            },
            css: [_CKEDITOR.getUrl('skins/moono-lisa/editor.css')].concat(
              editor.config.contentsCss
            ),
          },

          init: async function () {
            this.startGroup('Image Assets');
            for (let i = 0; i < imageList.length; i++) {
              const el = imageList[i];
              const htmlDropdown = `<img src="/assets/files/bucket/certificate-assets/${el}" alt="" style="height: auto; width: 120px; marginBottom: 10px;" />`;
              const html = `<img src="/assets/files/bucket/certificate-assets/${el}" alt="" style="height: auto; width: auto;" />`;
              this.add(html, htmlDropdown);
            }
          },

          onClick: function (value) {
            editor.focus();
            editor.fire('saveSnapshot');
            editor.insertHtml(value);
            editor.fire('saveSnapshot');
          },
        });
      },
    });
  };

  const onEditorChange = (e: CKEditorEventPayload<'change'>) => {
    const data = e.editor.getData();
    onChange && onChange(data);
  };

  const ckEditorConfig = {
    extraPlugins: [
      ...((plugins && plugins) || ([] as string[])),
      'uploadimage',
      'font',
    ],
    allowedContent: true,
    filebrowserUploadUrl:
      imageUploadPath && `/api/v1${imageUploadPath}?ckEditorReq=true`,
  };

  return (
    <Form.Item label={label}>
      <CKEditor
        initData={value}
        config={ckEditorConfig}
        onChange={onEditorChange}
        onBeforeLoad={onBeforeLoad}
      />
    </Form.Item>
  );
};

export default RichTextEditor;
