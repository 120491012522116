import { Col, Row, Spin } from 'antd';
import { InputProps } from 'components/input';
import Select from 'components/input/select';
import { useCareers } from 'hooks/career';
import { useLevels } from 'hooks/level';
import { FC, useEffect, useState } from 'react';
import { Career, Level } from '../../../../../src/core/careers';

export interface CareerLevelSelectProps extends InputProps {
  value: Level;
  onChange: (value: Level) => void;
}

const CareerLevelSelect: FC<CareerLevelSelectProps> = ({ value, onChange }) => {
  const [career, setCareer] = useState<Career>();
  const [level, setLevel] = useState<Level>();
  const { careers } = useCareers();
  const { levels, loading, fetch } = useLevels();
  useEffect(() => {
    level && onChange(level);
  }, [level]);
  useEffect(() => {
    career && fetch(career.name).catch(() => setLevel);
  }, [career]);
  return (
    <Row>
      <Col xl={12} style={{ paddingRight: 15 }}>
        <Select
          items={careers}
          itemKeyDisplay="name"
          value={career || value.career}
          itemKeyFind="name"
          onChange={(value) => {
            setCareer(value);
          }}
          renderItem={(v) =>
            v.name?.split('-').join(' ').toUpperCase() as string
          }
          label={`Career`}
        />
      </Col>
      <Col xl={12} style={{ paddingLeft: 15 }}>
        {loading && <Spin />}
        {levels && (
          <Select
            items={levels}
            value={level || value}
            itemKeyDisplay="name"
            itemKeyFind="id"
            onChange={(value) => {
              setLevel(value);
            }}
            label={`Level`}
          />
        )}
      </Col>
    </Row>
  );
};

export default CareerLevelSelect;
