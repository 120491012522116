import {
  createContext,
  useReducer,
  useContext,
  ReactElement,
  Dispatch,
} from 'react';
const GlobalContext = createContext({} as any);
const DispatchContext = createContext({} as any);

export const GLOBAL_LOADER = 'globalLoader';

export interface GlobalStateProviderProps {
  children: ReactElement;
}

export const GlobalStateProvider = (props: GlobalStateProviderProps) => {
  const { children } = props;
  const [state, dispatch] = useReducer((state: any, newValue: any) => {
    return { ...state, ...newValue };
  }, {});
  return (
    <GlobalContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </GlobalContext.Provider>
  );
};

export function useGlobalState<Type>(
  k: string,
  v?: Type
): [Type, Dispatch<Type>] {
  const [state, dispatch] = [
    useContext(GlobalContext),
    useContext(DispatchContext),
  ];
  const setState = (key: string, value: Type) => {
    // tslint:disable-next-line: no-shadowed-variable
    const v: any = {};
    v[key] = value;
    // tslint:disable-next-line: no-unused-expression
    dispatch &&
      dispatch({
        ...state,
        ...v,
      });
  };
  const val: Type = state[k] || v;

  return [
    val,
    // tslint:disable-next-line: no-shadowed-variable
    (v: Type) => {
      setState(k, v);
    },
  ];
}
