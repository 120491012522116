import { Form, Select as AntdSelect } from 'antd';
import { Component } from 'react';
import { InputProps } from './input';

export interface SelectTagProps<T> extends InputProps {
  onChange: (value: string[]) => void;
  items?: T[];
  renderItem?: (value: T) => Component | string;
}

export const SelectTag = <T,>({
  value,
  label,
  items = [],
  onChange,
  renderItem,
}: SelectTagProps<T>) => {
  return (
    <Form.Item label={label}>
      <AntdSelect
        mode="tags"
        defaultValue={value}
        onChange={(value) => {
          onChange && onChange(value);
        }}
      >
        {items.map((v, pos) => (
          <AntdSelect.Option key={`k-${pos}`} value={pos}>
            {renderItem && renderItem(v)}
          </AntdSelect.Option>
        ))}
      </AntdSelect>
    </Form.Item>
  );
};

export default SelectTag;
