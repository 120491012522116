import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CertificateData, ManualCertificate } from 'core.types/certificate';
import { Paginated } from 'core.types/core.types';

export const initialCertificateData: ManualCertificate = {
  format: '',
  uuid: '',
  name: '',
  application: '',
  data: {} as CertificateData,
  owner: '',
};

export interface CouponStoreIface {
  modalOwnerOpen: boolean;
  modalCertificateOpen: boolean;
  loading: boolean;
  certificate: ManualCertificate;
  certificates: Paginated<ManualCertificate>;
}

const initialState: CouponStoreIface = {
  modalOwnerOpen: false,
  modalCertificateOpen: false,
  loading: false,
  certificate: initialCertificateData,
  certificates: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total_page: 0,
      total_size: 0,
    },
  },
};

export const slice = createSlice({
  name: 'couponn',
  initialState,
  reducers: {
    setModalOwnerOpen: (state, action: PayloadAction<boolean>) => {
      state.modalOwnerOpen = action.payload;
    },
    setModalCertificateOpen: (state, action: PayloadAction<boolean>) => {
      state.modalCertificateOpen = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCertificate: (state, action: PayloadAction<ManualCertificate>) => {
      state.certificate = action.payload;
    },
    setCertificates: (
      state,
      action: PayloadAction<Paginated<ManualCertificate>>
    ) => {
      state.certificates = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setModalOwnerOpen,
  setModalCertificateOpen,
  setLoading,
  setCertificate,
  setCertificates,
} = slice.actions;

export default slice.reducer;
