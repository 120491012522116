import { Form } from 'antd';
import Input, { TextArea } from 'components/input';
import { CheckBox } from 'components/input/check.box';
import Datepicker from 'components/input/date.time.picker';
import { Session } from 'core.types/courses';
import { FC, useEffect } from 'react';

export interface ModuleSessionFormProps {
  value: any;
  onChange?: (v: any, pName: keyof Session) => void;
  uniformValue?: boolean;
  onUniformValueChange?: (v: boolean) => void;
}

export const ModuleSessionForm: FC<ModuleSessionFormProps> = ({
  value,
  onChange,
  uniformValue,
  onUniformValueChange,
}) => {
  useEffect(() => {
    console.log(value);
  }, []);
  return (
    <>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <CheckBox
          label="Uniform Value"
          value={uniformValue}
          onChange={(v) => onUniformValueChange && onUniformValueChange(v)}
        />
        <Input
          label={`Instructor`}
          value={value && value.instructor}
          onChange={(v) => onChange && onChange(v, 'instructor')}
        />
        <Input
          label={`Label`}
          value={value && value.label}
          onChange={(v) => onChange && onChange(v, 'label')}
        />
        <TextArea
          label={`Description`}
          value={value && value.description}
          onChange={(v) => onChange && onChange(v, 'description')}
        />
        <Input
          label={`Link`}
          value={value && value.link}
          onChange={(v) => onChange && onChange(v, 'link')}
        />
        <Datepicker
          label="Module Open Time"
          value={value && value.open}
          showTime={true}
          onChange={(v) => onChange && onChange(v, 'open')}
        />
      </Form>
    </>
  );
};

export default ModuleSessionForm;
