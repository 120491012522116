/* eslint-disable camelcase */
import { General } from '../core.types';
import Course from '../courses/course';
import Quiz from './course.quiz';

export enum Quality {
  poster = 'poster',
  sd = 'sd',
  hd = 'hd',
  fhd = 'fhd',
  uhd = 'uhd',
  Q4k = '4k',
  Q8k = '8k',
  Q144p = '144p',
  Q240p = '240p',
  Q360p = '360p',
  Q480p = '480p',
  Q720p = '720p',
  Q1080p = '1080p',
  PDF = 'pdf',
}
export enum ModuleType {
  ASSESSMENT = 'assesment',
  MODULE = 'module',
  CERTIFICATE = 'certificate',
}
export interface Material {
  data: any;
  quality: Quality;
  key?: string;
  url?: string;
}
export interface Session {
  label: string;
  instructor: string;
  description: string;
  extras?: string;
  open: Date;
  link: string;
}
export interface Module extends General {
  course: Course;
  parent_module?: Module;
  sub_modules_count?: number;
  content: string;
  quiz?: Quiz;
  weight: number;
  number: number;
  type: ModuleType;
  open?: Date;
  material?: Material[];
  sessions: Session[];
  duration: number;
}
export interface ModuleHierarchy extends Module {
  sub_modules: ModuleHierarchy[];
}
export interface HerarcialModule {
  uuid: string;
  name: string;
  sub_modules: HerarcialModule[];
  type: string;
  open?: Date;
}

export default Module;
