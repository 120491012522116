import { Coupon } from 'core.types/coupon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLoading, setCoupon, setCoupons } from 'store/reducers/coupon';

export const useCouponStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.coupon);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setCoupon: (coupon: Coupon) => {
      console.log(coupon);
      dispatch(setCoupon(coupon));
    },
    setCoupons: (coupons: Coupon[]) => dispatch(setCoupons(coupons)),
  };

  return { ...states, action };
};
