import { Button, Row, Col } from 'antd';
import enumToArray from 'common/enumToArray';
import { TextArea, TimePicker } from 'components/input';
import Select from 'components/input/select';
import { Days, Schedules, ScheduleTime } from 'core.types/bootcamp';
import { FC, useEffect, useState } from 'react';

export interface BootcampScheduleProps {
  value: any;
  onChange?: (v) => void;
}

export const BootcampSchedule: FC<BootcampScheduleProps> = ({
  value,
  onChange,
}) => {
  const [schedules, setSchedules] = useState<Schedules>({
    description: '',
    schedule_times: [] as ScheduleTime[],
  });
  const onFieldChange = (v: any, pName: keyof Schedules) => {
    setSchedules({
      ...schedules,
      [pName]: v,
    });
  };
  const onScheduleTimeChange = (v: any, pName: keyof ScheduleTime, i) => {
    const time = [...schedules.schedule_times];
    time[i] = { ...time[i], [pName]: v };
    onFieldChange(time, 'schedule_times');
  };

  useEffect(() => {
    onChange && onChange(schedules);
  }, [schedules]);

  useEffect(() => {
    setSchedules(value);
  }, []);
  /**
   * => schedule ->
   *     description
   *     schedule_times -> array of object { day, end_time, start_time }
   */

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={4} style={{ display: 'flex' }}>
          <span style={{ marginLeft: 'auto' }}>Schedules :</span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            label={`Description`}
            value={schedules.description}
            onChange={(v) => onFieldChange(v, 'description')}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col offset={4} span={20}>
          <Button
            onClick={() => {
              setSchedules({
                ...schedules,
                schedule_times: [
                  ...schedules.schedule_times,
                  {
                    day: Days.SUNDAY,
                    start_time: '',
                    end_time: '',
                  },
                ],
              });
            }}
          >
            Add Schedules
          </Button>
        </Col>
      </Row>
      {schedules.schedule_times.map((value, i) => {
        return (
          <div key={i}>
            <Select
              onChange={(v) => onScheduleTimeChange(v, 'day', i)}
              label={`Day`}
              items={enumToArray(Days) as any as Days[]}
              value={value.day}
              renderItem={(item: Days) => item.toUpperCase()}
            />
            <TimePicker
              label={`Start time`}
              value={value.start_time}
              onChange={(v) => {
                if (!v) {
                  return;
                }
                onScheduleTimeChange(v.format('HH:mm:ss'), 'start_time', i);
              }}
            />
            <TimePicker
              label={`End time`}
              value={value.end_time}
              onChange={(v) => {
                if (!v) {
                  return;
                }
                onScheduleTimeChange(v.format('HH:mm:ss'), 'end_time', i);
              }}
            />
            <Row>
              <Col offset={4} span={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      const time = [...schedules.schedule_times];
                      time.splice(i, 1);
                      setSchedules({ ...schedules, schedule_times: time });
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default BootcampSchedule;
