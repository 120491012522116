import { Button, Card, Form, Image, message } from 'antd';
import { Upload } from 'components/input';
import { useGlobalState } from 'context';
import { useBootcamp, useBootcampParam } from 'hooks/bootcamp';
import { FC } from 'react';
import { useHistory } from 'react-router';

export interface BootcampThumbnailProps {
  onDone?: () => void;
}

export const BootcampThumbnail: FC<BootcampThumbnailProps> = () => {
  const history = useHistory();
  const { bootcamp, loading } = useBootcamp();
  const { bootcamp: bootcampParam } = useBootcampParam();
  const [, stepDispatch] = useGlobalState('bootcamp.step', 0);

  const _save = async () => {
    history.push(`/bootcamp`);
    stepDispatch(0);
  };

  const cancelButtonClick = () => {
    stepDispatch(1);
  };

  return (
    <Card loading={loading}>
      {bootcamp.thumbnail && (
        <Image
          width={200}
          src={`/assets/files/bootcamp${bootcamp.thumbnail}?t=${Date.now()}`}
        />
      )}
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Upload
          label={`Thumbnail`}
          name={`image`}
          action={`/api/v1/bootcamp/${bootcampParam}/thumbnail`}
          onChange={(info) => {
            if (info.file.status !== 'uploading') {
              console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
              message.success(
                `${info.file.name} thumbnail uploaded successfully`
              );
            } else if (info.file.status === 'error') {
              message.error(`${info.file.name} thumbnail upload failed.`);
            }
          }}
        />
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Done
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
          Previous
        </Button>
      </Form>
    </Card>
  );
};

export default BootcampThumbnail;
