import { useEffect, useState } from 'react';
import { useLoading } from './common';
import Career from '../../../src/core/careers/career';
import { apiInternal } from '../service/api.internal';
import { useParams } from 'react-router-dom';

export const useCareerParam = () => useParams<{ career: string }>();

export const useCareers = () => {
  const [careers, setCareers] = useState<Career[]>([]);
  const [loading, setLoading] = useLoading();

  const fetch = () => {
    setLoading(true);
    return apiInternal
      .get('/careers')
      .then((_careers: Career[]) => {
        setCareers(_careers);
        return _careers;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetch();
  }, []);
  return { careers, loading, fetch };
};

export const useCareer = () => {
  const [career, setCareer] = useState<Career>();
  const [loading, setLoading] = useLoading();
  const params = useCareerParam();
  const get = () => {
    setLoading(true);
    return apiInternal
      .get(`/careers/${params.career}`)
      .then((_careers: Career) => {
        setCareer(_careers);
        return _careers;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  const save = () => {
    setLoading(true);
    return apiInternal
      .post(`/careers`, career)
      .then((_careers: Career) => {
        setCareer(_careers);
        return _careers;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    get();
  }, []);
  return { career, loading, get, save, setCareer };
};
