import { Button, Card, Form } from 'antd';
import enumToArray from 'common/enumToArray';
import Input from 'components/input';
import { CheckBox } from 'components/input/check.box';
import RichTextEditor from 'components/input/ckeditor/ckeditor';
import Datepicker from 'components/input/date.time.picker';
import Select from 'components/input/select';
import SelectTag from 'components/input/select.tag';
import { useGlobalState } from 'context';
import { Bootcamp } from 'core.types/bootcamp';
import { CourseStatus } from 'core.types/courses';
import { useBootcamp } from 'hooks/bootcamp';
import { courseStatusLabel } from 'hooks/course';
import TutorAutocomplete from 'pages/courses/components/tutor.autocomplete';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BootcampAssessmentComponents from '../components/bootcamp.assessment.methods';
import BootcampLearningMethods from '../components/bootcamp.learning.methods';
import BootcampMaterials from '../components/bootcamp.materials';
import BootcampPrices from '../components/bootcamp.prices';
import BootcampSchedule from '../components/bootcamp.schedule';
import BootcampTestimonials from '../components/bootcamp.testimonials';

export const BootcampFormDetail: FC = () => {
  const history = useHistory();
  const { bootcamp, setBootcamp, loading, save } = useBootcamp();
  const [richEditorValues, setRichEditorValues] = useState<Partial<Bootcamp>>({
    description: '',
    about: '',
  });
  const [, stepDispatch] = useGlobalState('bootcamp.step', 0);
  const bootcampChange = (v: any, pName: keyof Bootcamp) => {
    setBootcamp({
      ...bootcamp,
      [pName]: v,
    });
  };
  const ckEditorValueChange = (v: any, pName: keyof Bootcamp) => {
    setRichEditorValues({ ...richEditorValues, [pName]: v });
  };

  /**
   * value handling of CKEDITOR component is little bit different
   * due to unrecognized react state bug
   */
  useEffect(() => {
    setBootcamp({ ...bootcamp, about: richEditorValues.about as string });
  }, [richEditorValues.about]);
  useEffect(() => {
    setBootcamp({
      ...bootcamp,
      description: richEditorValues.description as string,
    });
  }, [richEditorValues.description]);

  const _save = async () => {
    const s = await save();
    if (s) {
      history.push(`/bootcamp/${s.id}/form`);
      stepDispatch(1);
    }
  };

  const cancelButtonClick = () => {
    history.goBack();
  };

  return (
    <Card loading={loading}>
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Name`}
          value={bootcamp.name}
          onChange={(v) => bootcampChange(v, 'name')}
        />
        <Input
          label={`Sub Name`}
          value={bootcamp.subname}
          onChange={(v) => bootcampChange(v, 'subname')}
        />
        <RichTextEditor
          label={`About`}
          value={bootcamp.about}
          onChange={(v) => ckEditorValueChange(v, 'about')}
        />
        <RichTextEditor
          label={`Description`}
          value={bootcamp.description}
          onChange={(v) => ckEditorValueChange(v, 'description')}
        />
        <Select
          onChange={(v) => bootcampChange(v, 'status')}
          label={`Status`}
          items={enumToArray(CourseStatus) as any as CourseStatus[]}
          value={bootcamp.status}
          renderItem={(item: CourseStatus) => courseStatusLabel[item]}
        />
        <SelectTag
          onChange={(v) => bootcampChange(v, 'sessions')}
          label={`Clasess`}
          value={bootcamp.sessions || []}
        />
        <BootcampLearningMethods
          value={bootcamp.learning_methods}
          onChange={(v) => bootcampChange(v, 'learning_methods')}
        />
        <BootcampAssessmentComponents
          value={bootcamp.assesment_components}
          onChange={(v) => bootcampChange(v, 'assesment_components')}
        />
        <BootcampMaterials
          value={bootcamp.materials}
          onChange={(v) => bootcampChange(v, 'materials')}
        />
        <BootcampPrices
          value={bootcamp.prices}
          onChange={(v) => bootcampChange(v, 'prices')}
        />
        <BootcampSchedule
          value={bootcamp.schedule}
          onChange={(v) => bootcampChange(v, 'schedule')}
        />
        <BootcampTestimonials
          value={bootcamp.testimonials}
          onChange={(v) => bootcampChange(v, 'testimonials')}
        />
        <TutorAutocomplete
          label={`Tutor`}
          value={bootcamp.tutor}
          onChange={(v) => bootcampChange(v, 'tutor')}
        />
        <TutorAutocomplete
          label={`Mentor`}
          value={bootcamp.mentor?.username}
          onChange={(v) =>
            bootcampChange({ ...bootcamp.mentor, username: v }, 'mentor')
          }
        />
        <Input
          label={`Whatsapp Link`}
          value={bootcamp.whatsapp_link}
          onChange={(v) => bootcampChange(v, 'whatsapp_link')}
        />
        <CheckBox
          label={`Coming Soon Bootcamp`}
          value={bootcamp.is_coming_soon}
          onChange={(v) => bootcampChange(v, 'is_coming_soon')}
        />
        {!bootcamp.is_coming_soon && (
          <Input
            label={`Start to End Date`}
            value={bootcamp.start_to_end_date}
            onChange={(v) => bootcampChange(v, 'start_to_end_date')}
            placeholder={`Ex: Januari 2022 - Maret 2022`}
          />
        )}
        <Datepicker
          label="Start Date"
          value={bootcamp.start_date}
          onChange={(v) => bootcampChange(v, 'start_date')}
        />
        <Datepicker
          label="End Date"
          value={bootcamp.end_date}
          onChange={(v) => bootcampChange(v, 'end_date')}
        />
        <CheckBox
          label={`Show On Homepage`}
          value={bootcamp.is_displayed}
          onChange={(v) => bootcampChange(v, 'is_displayed')}
        />
        <Button type="primary" loading={loading} onClick={_save}>
          Save and Next
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => cancelButtonClick()}>
          Cancel
        </Button>
      </Form>
    </Card>
  );
};

export default BootcampFormDetail;
