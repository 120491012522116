import { Card, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useStudentCourseModules, useStudentParam } from 'hooks/student';
import { ModuleProcess } from 'core.types/enrollment';
export const StudentModuleList = () => {
  const { student: studentParam, course } = useStudentParam();
  const { modules, loading } = useStudentCourseModules();

  const moduleColumns = [
    {
      title: 'Name',
      render: (m: ModuleProcess) => m && m.module.name,
    },
    {
      title: 'Action',
      render: (module: ModuleProcess) => (
        <>
          <Link
            to={`/students/${studentParam}/courses/${course}/modules/${module.module.uuid}/result`}
            style={{ marginLeft: 10 }}
          >
            Answers
          </Link>
        </>
      ),
    },
  ];

  return (
    <Card title={`${studentParam}'s Subscribed Module`} loading={loading}>
      <Table columns={moduleColumns} dataSource={modules} pagination={false} />
    </Card>
  );
};

export default StudentModuleList;
