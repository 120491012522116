import { Button, Card, Spin } from 'antd';
import { useGlobalState } from 'context';
import MaterialForm from '../components/material.form';
import { useModule } from 'hooks/module';
import { useHistory } from 'react-router';

export const ModuleMaterials = () => {
  const history = useHistory();
  const [, setCurrentStep] = useGlobalState('module.step', 0);
  const { module, loading } = useModule();

  const prevStep = () => {
    setCurrentStep(1);
  };

  const _save = async () => {
    setCurrentStep(0);
    history.push(`/courses/${module.course.uuid}/modules/${module.uuid}`);
  };

  return (
    <Card loading={loading}>
      {loading && <Spin />}
      <div style={{ marginBottom: 20 }}>
        <MaterialForm />
      </div>
      <Button type="primary" loading={loading} onClick={() => _save()}>
        Save and Done
      </Button>
      <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
        Previous
      </Button>
    </Card>
  );
};

export default ModuleMaterials;
