import { Card, Steps } from 'antd';
import { useGlobalState } from 'context';
import { useBootcampParam } from 'hooks/bootcamp';
import { useEffect, useState } from 'react';
import BootcampCover from './steps/bootcamp.form.cover';
import BootcampFormDetail from './steps/bootcamp.form.detail';
import BootcampThumbnail from './steps/bootcamp.form.thumbnail';
const { Step } = Steps;
export const BootcampFormPage = () => {
  const [step, setStep] = useState(0);
  const [currentStep, setCurrentStep] = useGlobalState('bootcamp.step', 0);
  const param = useBootcampParam();

  useEffect(() => {
    setCurrentStep(0);
  }, []);

  useEffect(() => {
    setStep(currentStep);
  }, [currentStep]);

  const onStepChange = (step) => {
    setStep(step);
  };

  const stepProps = {
    onChange: param.bootcamp !== 'new' ? onStepChange : undefined,
  };

  return (
    <Card title={`Bootcamp`} loading={false}>
      <Steps current={step} {...stepProps}>
        <Step title="Detail" description="Bootcamp Detail." />
        <Step title="Cover" description="Bootcamp Cover." />
        <Step title="Thumbnail" description="Bootcamp Thumbnail." />
      </Steps>
      {step === 0 && <BootcampFormDetail />}
      {step === 1 && <BootcampCover />}
      {step === 2 && <BootcampThumbnail />}
    </Card>
  );
};

export default BootcampFormPage;
