import { Button, Card, Spin, Form, Row, Col } from 'antd';
import { useGlobalState } from 'context';
import { useCourse } from 'hooks/course';
import { Level } from '../../../../../src/core/careers';
import { Course } from '../../../../../src/core/courses';
import CareerLevelSelect from '../components/career.level';

export const CourseStepCareerLevel = () => {
  const { course, setCourse, loading, save } = useCourse();
  const [, stepDispatch] = useGlobalState('course.step', 0);
  const courseChange = (v: any, pName: keyof Course) => {
    setCourse({
      ...course,
      [pName]: v,
    });
  };
  const _save = async () => {
    const c = await save();
    if (c) {
      stepDispatch(3);
    }
  };
  const prevStep = () => {
    stepDispatch(1);
  };
  return (
    <Card loading={loading}>
      {loading && <Spin />}
      <div style={{ marginBottom: 20 }}>
        {course.career.map((c, i) => {
          return (
            <Form.Item>
              <Row>
                <Col xl={22} style={{ paddingRight: 15 }}>
                  <CareerLevelSelect
                    key={`c-l-${i}`}
                    label={``}
                    value={c.level}
                    onChange={(l) => {
                      const careerLevel = course.career;
                      careerLevel[i].level = l;
                      courseChange(careerLevel, 'career');
                    }}
                  />
                </Col>
                <Col xl={2}>
                  <Button
                    danger
                    onClick={() => {
                      const c = course.career;
                      c.splice(i, 1);
                      setCourse({
                        ...course,
                        career: c,
                      });
                    }}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          );
        })}
        <Button
          onClick={() => {
            const careerLevel = course.career;
            careerLevel.push({
              level: {} as Level,
              number: 0,
            });
            courseChange(careerLevel, 'career');
          }}
        >
          Add Career Level
        </Button>
      </div>
      <Button type="primary" loading={loading} onClick={_save}>
        Save and Next
      </Button>
      <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
        Previous
      </Button>
    </Card>
  );
};

export default CourseStepCareerLevel;
