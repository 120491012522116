import { useEffect } from 'react';
import { apiInternal } from '../service/api.internal';
import { useGeneratedCertificateStore } from 'store/hooks/generated.certificate';
import { usePageSize } from './common';
import qs from 'qs';

export const useGeneratedCertificates = () => {
  const {
    loading,
    certificates,
    action: { setLoading, setCertificates },
  } = useGeneratedCertificateStore();
  const [pagination, setPagination] = usePageSize();
  const { page, size } = pagination;

  const fetch = () => {
    setLoading(true);
    const p = {
      page,
      size,
    };
    return apiInternal
      .get(`/applications/generated-certificates?${qs.stringify(p)}`)
      .then((_certificates) => {
        setCertificates(_certificates);
        return _certificates;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    fetch();
  }, [pagination]);
  return {
    certificates,
    loading,
    fetch,
    pagination: {
      page,
      size,
    },
    setPagination,
  };
};

export const useGeneratedCertificate = () => {
  const {
    loading,
    certificate,
    action: {
      setLoading,
      setCertificate,
      setModalOwnerOpen,
      setModalCertificateOpen,
    },
  } = useGeneratedCertificateStore();
  const get = (id) => {
    setLoading(true);
    return apiInternal
      .get(`/applications/generated-certificates/${id}`)
      .then((_certificates) => {
        setCertificate(_certificates);
        return _certificates;
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  const saveOwner = () => {
    setLoading(true);
    return apiInternal
      .put(
        `/applications/generated-certificates/${certificate.uuid}/owner`,
        certificate
      )
      .then(() => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      })
      .finally(() => setTimeout(() => setLoading(false), 500));
  };
  return {
    certificate,
    loading,
    saveOwner,
    setCertificate,
    setModalOwnerOpen,
    setModalCertificateOpen,
    get,
  };
};
