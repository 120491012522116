import { Button, Row, Col } from 'antd';
import Input, { TextArea } from 'components/input';
import { Material } from 'core.types/bootcamp';
import { FC, useEffect, useState } from 'react';

export interface BootcampMaterialsProps {
  value: any;
  onChange?: (v: any) => void;
}

export interface MaterialForm {
  cover: string;
  caption: string;
  item: Material[];
}

export const BootcampMaterials: FC<BootcampMaterialsProps> = ({
  value,
  onChange,
}) => {
  const [materials, setMaterials] = useState<MaterialForm>({
    cover: '',
    caption: '',
    item: [],
  });
  const onFieldChange = (v: any, pName: keyof MaterialForm) => {
    setMaterials({
      ...materials,
      [pName]: v,
    });
  };
  const onItemChange = (v: any, pName: keyof Material, i) => {
    const item = [...materials.item];
    item[i] = { ...item[i], [pName]: v };
    onFieldChange(item, 'item');
  };

  useEffect(() => {
    onChange && onChange(materials);
  }, [materials]);

  useEffect(() => {
    setMaterials(value);
  }, [value]);
  /**
   * => materials ->
   *     caption
   *     item -> array of object { description, level, title }
   */

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={4} style={{ display: 'flex' }}>
          <span style={{ marginLeft: 'auto' }}>Material :</span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextArea
            label={`Caption`}
            value={materials.caption}
            onChange={(v) => onFieldChange(v, 'caption')}
          />
        </Col>
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <Col offset={4} span={20}>
          <Button
            onClick={() => {
              setMaterials({
                ...materials,
                item: [
                  ...materials.item,
                  {
                    level: '',
                    title: '',
                    description: '',
                    show: true,
                  },
                ],
              });
            }}
          >
            Add Material Item
          </Button>
        </Col>
      </Row>
      {materials.item.map((value, i) => {
        return (
          <div key={i}>
            <Input
              label={`Level`}
              value={value.level}
              onChange={(v) => onItemChange(v, 'level', i)}
            />
            <Input
              label={`Title`}
              value={value.title}
              onChange={(v) => onItemChange(v, 'title', i)}
            />
            <TextArea
              label={`Description`}
              value={value.description}
              onChange={(v) => onItemChange(v, 'description', i)}
            />
            <Row>
              <Col offset={4} span={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      const item = [...materials.item];
                      item.splice(i, 1);
                      setMaterials({ ...materials, item: item });
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default BootcampMaterials;
