import { Spin } from 'antd';
import { useProfile } from 'hooks/identity';
import { FC } from 'react';

export interface FullnameProps {
  username: string;
}

const Fullname: FC<FullnameProps> = ({ username }) => {
  const { loading, profile } = useProfile(username);

  return (
    <>
      {loading && <Spin />}
      {!loading && ((profile && profile.fullname) || username)}
    </>
  );
};

export default Fullname;
