import { WebinarCategory } from 'core.types/webinar.category';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setLoading,
  setWebinarCategory,
  setWebinarCategories,
} from 'store/reducers/webinar.category';

export const useWebinarCategoryStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.webinarCategory);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setWebinarCategory: (webinarCategory: WebinarCategory) =>
      dispatch(setWebinarCategory(webinarCategory)),
    setWebinarCategories: (webinarCategories: WebinarCategory[]) =>
      dispatch(setWebinarCategories(webinarCategories)),
  };

  return { ...states, action };
};
