import { useState } from 'react';
import { useLoading } from './common';
import { apiInternal } from '../service/api.internal';
import { message } from 'antd';
import { ManualCertificate } from 'core.types/certificate';

export const initialCertificateData: ManualCertificate = {
  uuid: '',
  format: '',
  name: '',
  application: '',
  owner: '',
  data: {
    issuer_role: '',
    issuer_name: '',
    issued_certificate_name: '',
    issued_date: new Date(),
    title: '',
    event_label: '',
    event_link: '',
    start_date: new Date(),
    end_date: new Date(),
    caption: '',
    public_domain: '',
  },
};

export const useManualCertificate = () => {
  const [certificate, setCertificate] = useState<ManualCertificate>(
    initialCertificateData
  );
  const [loading, setLoading] = useLoading();
  const generate = (): Promise<ManualCertificate | undefined> => {
    setLoading(true);

    const fn = apiInternal.put(`/certificate/generate`, certificate);
    return fn
      .then((d) => {
        message.success(`Certificate Generated`);
        return d;
      })
      .catch((e) => {
        if (e.code === 409) {
          message.error(e.data);
        } else {
          message.error(`Certificate Failed to be Generated`);
        }
        return undefined;
      })
      .finally(() => setLoading(false));
  };
  return {
    certificate,
    loading,
    generate,
    setLoading,
    setCertificate,
  };
};
