import { Modal } from 'antd';
import { useLevel } from '../../../hooks/level';
import { FC } from 'react';
import Input, { TextArea } from 'components/input';

export interface LevelFormProps {
  visible: boolean;
  isNew: boolean;
  done: () => void;
}
export const LevelForm: FC<LevelFormProps> = ({ visible, isNew, done }) => {
  const { level, setLevel, save } = useLevel();
  const cancel = done;
  const ok = async () => {
    isNew && (await save());
    done();
  };
  return (
    <Modal
      visible={visible}
      title={`Career Level Form`}
      cancelButtonProps={{
        onClick: cancel,
      }}
      okButtonProps={{
        onClick: ok,
      }}
    >
      <Input
        label={`Career Name`}
        readonly={!isNew}
        value={level.name}
        onChange={(v) => setLevel({ ...level, name: v })}
      />
      <TextArea
        label={`Description`}
        readonly={!isNew}
        onChange={(v) => setLevel({ ...level, description: v })}
        value={level?.description}
      />
    </Modal>
  );
};

export default LevelForm;
