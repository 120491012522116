import {
  useGeneratedCertificate,
  useGeneratedCertificates,
} from 'hooks/generated.certificate';
import Modal from 'antd/lib/modal/Modal';
import UserAutocomplete from 'pages/coupon/components/user.autocomplete';
import { initialCertificateData } from 'store/reducers/generated.certificate';
import { useGeneratedCertificateStore } from 'store/hooks/generated.certificate';

export const CertificateOwnerForm = () => {
  const { fetch } = useGeneratedCertificates();
  const { certificate, setCertificate, saveOwner, setModalOwnerOpen } =
    useGeneratedCertificate();
  const { modalOwnerOpen } = useGeneratedCertificateStore();

  const handleOk = async () => {
    const s = await saveOwner();
    if (s) {
      fetch();
    }
    setModalOwnerOpen(false);
  };

  const handleCancel = () => {
    setCertificate(initialCertificateData);
    setModalOwnerOpen(false);
  };

  return (
    <Modal
      title="Update Owner"
      visible={modalOwnerOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <UserAutocomplete
        label={`Owner`}
        value={certificate.owner}
        onChange={(v) => setCertificate({ ...certificate, owner: v })}
      />
    </Modal>
  );
};

export default CertificateOwnerForm;
