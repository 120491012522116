import { Form, Input as AntdInput } from 'antd';
import { FC } from 'react';
import { InputProps } from './index';

export const TextArea: FC<InputProps> = ({
  value,
  onChange,
  readonly,
  label,
  placeholder,
}: InputProps) => {
  return (
    <Form.Item label={label}>
      <AntdInput.TextArea
        placeholder={placeholder}
        readOnly={readonly}
        value={value}
        onChange={({ target: { value } }) => onChange && onChange(value)}
      />
    </Form.Item>
  );
};

export default TextArea;
