/* eslint-disable camelcase */
import { CourseStatus } from 'core.types/courses';
import { General } from '../core.types';

export enum Days {
  SUNDAY = 'Minggu',
  MONDAY = 'Senin',
  TUESDAY = 'Selasa',
  WEDNESDAY = 'Rabu',
  THURSDAY = 'Kamis',
  FRIDAY = 'Jumat',
  SATURDAY = 'Sabtu',
}

export interface ScheduleTime {
  day: Days;
  start_time: string;
  end_time: string;
}

export interface Schedules {
  description: string;
  schedule_times: ScheduleTime[];
}

export interface SocialLinks {
  icon?: string;
  url: string;
}

export interface Person {
  username?: string;
  name: string;
  job: string;
  picture: string;
  social: SocialLinks[];
}
export interface Testimonial {
  reviewer: Person;
  review: string;
}

export interface Price {
  name: string;
  price: number;
  origin_price?: number;
  highlighted: boolean;
  description: string;
}

export interface Material {
  level: string;
  title: string;
  description: string;
  show: boolean;
}

export interface Bootcamp extends General {
  description: string;
  subname: string;
  cover: string;
  thumbnail: string;
  about: string;
  schedule: Schedules;
  learning_methods: string[];
  assesment_components: string[];
  materials: {
    cover: string;
    caption: string;
    item: Material[];
  };
  mentor: Person | null;
  tutor: string;
  prices: Price[];
  testimonials: Testimonial[];
  whatsapp_link: string;
  is_enrolled: boolean;
  finished?: boolean;
  sessions?: string[];
  status: CourseStatus;
  is_coming_soon: boolean;
  start_to_end_date: string;
  is_displayed: boolean;
  start_date?: Date;
  end_date?: Date;
}

export interface BootcampQueryParam {
  name?: string;
  mentor?: string;
  finished?: boolean;
}
