import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Upload as AntdUpload } from 'antd';
import { FC, ReactElement } from 'react';

export interface UploadProps {
  name: string;
  action?: string;
  label?: string;
  onChange?: (value: any) => void;
  beforeUpload?: (value: any) => void;
  multiple?: boolean;
  defaultFileList?: any;
  buttonIcon?: ReactElement;
  buttonText?: string;
}
export const Upload: FC<UploadProps> = ({
  name,
  action,
  label,
  onChange,
  beforeUpload,
  multiple,
  defaultFileList,
  buttonIcon = <UploadOutlined />,
  buttonText = 'Click to Upload',
}: UploadProps) => {
  return (
    <Form.Item label={label}>
      <AntdUpload
        name={name}
        action={action}
        onChange={onChange}
        beforeUpload={beforeUpload}
        multiple={multiple}
        defaultFileList={defaultFileList}
      >
        <Button icon={buttonIcon}>{buttonText}</Button>
      </AntdUpload>
    </Form.Item>
  );
};

export default Upload;
