import { Button, Card, message, Popconfirm } from 'antd';
import { useCourse } from '../../hooks/course';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { useModuleHierarchies } from 'hooks/module.hierarchy';
import { ModuleHierarchy as Hierarchy } from 'core.types/courses';
import { Link, useHistory } from 'react-router-dom';
import { initialModuleData, useModule, useModules } from 'hooks/module';
import { PlusOutlined } from '@ant-design/icons';

export interface TreeHierarchy {
  uuid: string;
  title: string;
  subtitle?: string;
  expanded: boolean;
  children?: TreeHierarchy[];
}

export const ModuleHierarchy = () => {
  const { course } = useCourse();
  const { loading, hierarchy, setHierarchy, save } = useModuleHierarchies();
  const { fetch } = useModules();
  const { module, setModule, remove, get } = useModule();
  const history = useHistory();

  const mapData = (data: Hierarchy) => {
    const newData = {
      uuid: data.uuid,
      name: data.name,
      sub_modules:
        data.sub_modules &&
        data.sub_modules.map((childData) => mapData(childData)),
      type: data.type,
      title: data.name,
      expanded: true,
      children:
        data.sub_modules &&
        data.sub_modules.map((childData) => mapData(childData)),
    };

    return newData;
  };

  /**
   * this method below nearly the same with the above one but had different function.
   * it's only used to set sub_modules property by children property
   * which children property was needed by the react-sortable-tree library.
   */
  const mapRearrangeData = (data) => {
    const newData = {
      uuid: data.uuid,
      name: data.name,
      sub_modules:
        data.children &&
        data.children.map((childData) => mapRearrangeData(childData)),
      type: data.type,
      title: data.name,
      expanded: true,
      children:
        data.children &&
        data.children.map((childData) => mapRearrangeData(childData)),
    };

    return newData;
  };

  const fetchTreeData = () => {
    return hierarchy.map((data) => mapData(data));
  };

  const onListChange = (v) => {
    const rearranged = v.map((data) => mapRearrangeData(data));

    setHierarchy(rearranged);
  };

  const onNodeMoved = () => {
    save();
  };

  const onModuleDeletePrompt = (course: string, uuid: string) => {
    get({ courseUUID: course, moduleUUID: uuid });
  };

  const onDeleteModuleConfirm = async () => {
    const r = await remove();
    if (r) {
      message.success(`${module.name} successfully removed!`);
      setModule(initialModuleData);
      fetch();
    }
  };

  const onCancelDeleteModuleConfirm = () => {
    setModule(initialModuleData);
  };

  const renderButton = (node) => {
    return (
      <>
        <Link to={`/courses/${course.uuid}/modules/${node.uuid}/form`}>
          Detail
        </Link>
        <Link
          to={`/courses/${course.uuid}/modules/${node.uuid}/quizes`}
          style={{ marginLeft: 10 }}
        >
          Quizes
        </Link>
        <Popconfirm
          title={`Are you sure to delete module ${node.name.toUpperCase()} ?`}
          onConfirm={onDeleteModuleConfirm}
          onCancel={onCancelDeleteModuleConfirm}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="link"
            danger
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onClick={() => onModuleDeletePrompt(course.uuid!, node.uuid!)}
          >
            Delete
          </Button>
        </Popconfirm>
      </>
    );
  };

  return (
    <Card
      title={`Module ${course.name} Course Hierarchy`}
      loading={loading}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            history.push(`/courses/${course.uuid}/modules/new/form`);
          }}
        >
          Add
        </Button>
      }
    >
      <div style={{ minHeight: 400 }}>
        <SortableTree
          treeData={fetchTreeData()}
          onChange={onListChange}
          onMoveNode={onNodeMoved}
          isVirtualized={false}
          generateNodeProps={({ node }) => {
            return {
              title: (
                <div style={{ width: 800, display: 'flex' }}>
                  <div>{node.title}</div>
                  <div style={{ marginLeft: 'auto' }}>{renderButton(node)}</div>
                </div>
              ),
            };
          }}
        />
      </div>
    </Card>
  );
};

export default ModuleHierarchy;
