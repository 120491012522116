import { Row, Col, Avatar, Card, Form } from 'antd';
import Input from 'components/input';
import { useUser } from 'hooks/auth';

export const Profile = () => {
  const user = useUser();

  const avatar =
    user && user.avatar ? (
      <Avatar src={user.avatar} alt={user.fullname} size={128} />
    ) : (
      <Avatar size={128}>{user && user.fullname}</Avatar>
    );

  return (
    <Card>
      <Row>
        <Col span={24} style={{ marginBottom: 30 }}>
          <div
            style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            {avatar}
          </div>
        </Col>
        <Col span={24}>
          <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Input readonly={true} value={user.fullname} label={`Full Name`} />
            <Input readonly={true} value={user.nickname} label={`Nickname`} />
            <Input readonly={true} value={user.email} label={`Email`} />
            <Input
              readonly={true}
              value={user.birthdate}
              label={`Birth Date`}
            />
          </Form>
        </Col>
      </Row>
    </Card>
  );
};

export default Profile;
