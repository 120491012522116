import { Button, Card, Spin } from 'antd';
import { useGlobalState } from 'context';
import { Session } from 'core.types/courses';
import { useModuleSessions, initialData } from 'hooks/module.session';
import { useEffect, useState } from 'react';
import ModuleSessionForm from '../components/module.session.form';

export const ModuleSession = () => {
  const { course, sessions, setSessions, save } = useModuleSessions();
  const [uniformValues, setUniformValues] = useState<boolean[]>([]);
  const [, stepDispatch] = useGlobalState('module.step', 0);

  useEffect(() => {
    setUniformValues(Array(course.sessions?.length).fill(false));
  }, [course.sessions]);

  useEffect(() => {
    /**
     * use to make inputs automatically follow the first uniform value field
     */
    if (sessions) {
      const d = sessions.map((d: any, i: number) => {
        const uniformValIdx = uniformValues.findIndex((x) => x === true);
        const mustUnform = uniformValIdx > -1 && uniformValues[i];
        const data = mustUnform
          ? { ...sessions[uniformValIdx], label: d.label }
          : d;

        return data;
      });
      setSessions(d);
    }
  }, [uniformValues]);

  const renderRemoveButton = (i) => {
    if (course && course.sessions && i < course.sessions?.length) return;
    return (
      <Button
        type="link"
        danger
        onClick={() => {
          const m = sessions ? [...sessions] : [];
          const n = [...uniformValues];

          m.splice(i, 1);
          n.splice(i, 1);

          setSessions(m);
          setUniformValues(n);
        }}
      >
        Remove
      </Button>
    );
  };

  const _save = async () => {
    const s = await save();

    if (s) {
      stepDispatch(2);
    }
  };

  const prevStep = () => {
    stepDispatch(0);
  };

  const onFieldChange = (v: any, pName: keyof Session, i) => {
    const uniformValIdx = uniformValues.findIndex((x) => x === true);
    let newArr = sessions ? [...sessions] : [];
    newArr[i] = { ...newArr[i], [pName]: v };
    // todo
    newArr = newArr.map((d, j) => {
      if (j !== i && uniformValues[j]) {
        return { ...newArr[uniformValIdx], label: d.label };
      } else {
        return d;
      }
    });
    // end todo
    setSessions(newArr);
  };

  const onUniformValueChange = (v: boolean, i) => {
    const arr = [...uniformValues];
    arr[i] = v;
    setUniformValues(arr);
  };

  const renderSessionForm = () => {
    return (
      sessions &&
      sessions.map((d: any, i: number) => {
        const uniformValIdx = uniformValues.findIndex((x) => x === true);
        const mustUnform = uniformValIdx > -1 && uniformValues[i];
        let data = d;

        if (mustUnform) {
          data = { ...sessions[uniformValIdx], label: d.label };
        }

        return (
          <Card
            key={i}
            title={`Session ${d.label}`}
            style={{ marginBottom: 20 }}
            extra={renderRemoveButton(i)}
          >
            <ModuleSessionForm
              value={data}
              onChange={(v, pName) => onFieldChange(v, pName, i)}
              uniformValue={uniformValues[i]}
              onUniformValueChange={(v) => onUniformValueChange(v, i)}
            />
          </Card>
        );
      })
    );
  };
  return (
    <Card loading={false}>
      {false && <Spin />}
      <div style={{ marginBottom: 20 }}>{renderSessionForm()}</div>
      <div style={{ display: 'flex' }}>
        <Button type="primary" loading={false} onClick={_save}>
          Save and Next
        </Button>
        <Button style={{ margin: '0 8px' }} onClick={() => prevStep()}>
          Previous
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            const arr = sessions ? [...sessions] : [];
            arr.push(initialData);

            setUniformValues([...uniformValues, false]);
            setSessions(arr);
          }}
        >
          Add Session
        </Button>
      </div>
    </Card>
  );
};

export default ModuleSession;
