import { Button, Row, Col } from 'antd';
import Input from 'components/input';
import { FC, useEffect, useState } from 'react';

export interface BootcampAssessmentComponentsProps {
  value: any;
  onChange?: (v: any) => void;
}

export const BootcampAssessmentComponents: FC<
  BootcampAssessmentComponentsProps
> = ({ value, onChange }) => {
  const [assessmentComponents, setAssessmentComponents] = useState<string[]>(
    []
  );
  const onFieldChange = (v: any, i: number) => {
    const newArr = [...assessmentComponents];
    newArr[i] = v;
    setAssessmentComponents(newArr);
  };

  useEffect(() => {
    onChange && onChange(assessmentComponents);
  }, [assessmentComponents]);

  useEffect(() => {
    setAssessmentComponents(value);
  }, [value]);

  return (
    <>
      <Row style={{ marginBottom: 20 }}>
        <Col span={4} style={{ display: 'flex' }}>
          <span style={{ marginLeft: 'auto', marginRight: 10 }}>
            Assessment Components:
          </span>
        </Col>
        <Col span={20} style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              setAssessmentComponents([...assessmentComponents, '']);
            }}
          >
            Add Components
          </Button>
        </Col>
      </Row>
      {assessmentComponents.map((value, i) => {
        return (
          <div key={i}>
            <Input
              label={`Component`}
              value={value}
              onChange={(v) => onFieldChange(v, i)}
            />
            <Row>
              <Col offset={4} span={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    width: '100%',
                    marginBottom: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      const m = [...assessmentComponents];
                      m.splice(i, 1);
                      setAssessmentComponents(m);
                    }}
                    danger
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export default BootcampAssessmentComponents;
