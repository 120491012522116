export enum CouponType {
  EQUAL_PRICE = '=',
  DECREASE_PRICE = '-',
  DISCOUNT_PRICE = '*',
}

export const couponTypeLabel = {
  [CouponType.EQUAL_PRICE]: 'Equal Price',
  [CouponType.DECREASE_PRICE]: 'Decrease Price',
  [CouponType.DISCOUNT_PRICE]: 'Discount Price',
};

export interface Conditions {
  username?: string;
  course?: string;
}

export interface Coupon {
  creator: string;
  code?: string;
  quota: number;
  value: number;
  type?: CouponType;
  conditions?: Conditions;
  expired: Date;
}

export default Coupon;
