import { Button, Card, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useCareers } from '../../hooks/career';
import { useHistory } from 'react-router';
import { PlusOutlined } from '@ant-design/icons';

const careerColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (career: string) => career.split('-').join(' ').toUpperCase(),
  },
  {
    title: 'Action',
    dataIndex: 'name',
    key: 'name',
    render: (career: string) => (
      <>
        <Link to={`/careers/${career}/edit`}>Detail</Link>
      </>
    ),
  },
];

export const CareerList = () => {
  const { careers } = useCareers();
  const history = useHistory();
  const addCourseButtonClick = () => {
    history.push(`/careers/new/form`);
  };

  return (
    <Card
      title={`Career List`}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={addCourseButtonClick}
        >
          Add
        </Button>
      }
    >
      <Table columns={careerColumns} dataSource={careers} />
    </Card>
  );
};

export default CareerList;
