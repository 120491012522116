import CareerList from '../pages/careers/career.list';
import { FC } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import DashboardLayout from '../layout/dashboard';
import RouteWithLayout from '../layout/route.layout';
import CoursePage from '../pages/courses/course.list';
import ChallengeForm from '../pages/challenges/challenge.form';
import ChallengeList from '../pages/challenges/challenge.list';
import ModuleForm from 'pages/course.modules/course.module.form';
import CareerForm from '../pages/careers/career.form';
import CourseFormPage from 'pages/courses/course.form';
import ModuleHierarchy from 'pages/course.modules/course.module.hierarchy';
import BootcampList from 'pages/bootcamps/bootcamp.list';
import BootcampFormPage from 'pages/bootcamps/bootcamp.form';
import QuizPage from 'pages/quizes/quiz.form';
import QuizQuestionForm from 'pages/questions/question.form';
import StudentList from 'pages/students/student.list';
import StudentModuleList from 'pages/students/student.module.list';
import StudentAnswerList from 'pages/students/student.answers.list';
import StudentAnswer from 'pages/students/student.answers';
import StudentResult from 'pages/students/student.results';
import FullLayout from 'layout/full';
import Login from 'pages/Auth/login';
import Profile from 'pages/Auth/profile';
import CourseCertificateList from 'pages/courses.certificates/course.certificate.list';
import CertificateFormPage from 'pages/courses.certificates/course.certificate.form';
import UserList from 'pages/users/user.list';
import CouponForm from '../pages/coupon/coupon.form';
import CouponList from '../pages/coupon/coupon.list';
import CertificateCreationForm from 'pages/certificate/certificate.form';
import PackageBenefitList from 'pages/package.benefits/package.benefits.list';
import PackageList from 'pages/package/package';
import WebinarCategoryList from 'pages/webinar.categories/webinar.category.list';
import WebinarList from 'pages/webinars/webinar.list';

export const routeList = [
  {
    path: '/webinar-category',
    name: 'Webinar Category',
    component: WebinarCategoryList,
    Layout: DashboardLayout,
  },
  {
    path: '/package-benefits',
    name: 'Package Benefits',
    component: PackageBenefitList,
    Layout: DashboardLayout,
  },
  {
    path: '/packages',
    name: 'Package',
    component: PackageList,
    Layout: DashboardLayout,
  },
  {
    path: '/certificate',
    name: 'Certificate',
    component: CertificateCreationForm,
    Layout: DashboardLayout,
  },
  {
    path: '/careers/:career',
    name: 'Career Detail',
    component: CareerForm,
    Layout: DashboardLayout,
  },
  {
    path: '/careers',
    name: 'Career',
    component: CareerList,
    Layout: DashboardLayout,
  },
  {
    path: '/coupons/:coupon',
    name: 'Coupon Detail',
    component: CouponForm,
    Layout: DashboardLayout,
  },
  {
    path: '/coupons',
    name: 'Coupon',
    component: CouponList,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/modules/:module/quizes/:quiz/question/:question/form',
    name: 'Quiz Question Detail',
    component: QuizQuestionForm,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/modules/:module/quizes',
    name: 'Module Quiz',
    component: QuizPage,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/modules/:module/form',
    name: 'Module Detail',
    component: ModuleForm,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/certificates/:certificate/form',
    name: 'Course Certificate Detail',
    component: CertificateFormPage,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/module-hierarchy',
    name: 'Module Hierarchy',
    component: ModuleHierarchy,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/certificates',
    name: 'Course Certificates',
    component: CourseCertificateList,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/modules',
    name: 'Modules',
    component: ModuleHierarchy,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/students',
    name: 'Course Student List',
    component: StudentList,
    Layout: DashboardLayout,
  },
  {
    path: '/courses/:course/form',
    name: 'Course Detail',
    component: CourseFormPage,
    Layout: DashboardLayout,
  },
  {
    path: '/courses',
    name: 'Courses',
    component: CoursePage,
    Layout: DashboardLayout,
  },
  {
    path: '/webinars/:course/form',
    name: 'Course Detail',
    component: CourseFormPage,
    Layout: DashboardLayout,
  },
  {
    path: '/challenges/:challenge',
    name: 'Challenges Detail',
    component: ChallengeForm,
    Layout: DashboardLayout,
  },
  {
    path: '/challenges',
    name: 'Challenges',
    component: ChallengeList,
    Layout: DashboardLayout,
  },
  {
    path: '/webinars',
    name: 'Webinar List',
    component: WebinarList,
    Layout: DashboardLayout,
  },
  {
    path: '/bootcamp/:bootcamp/form',
    name: 'Bootcamp Detail',
    component: BootcampFormPage,
    Layout: DashboardLayout,
  },
  {
    path: '/bootcamp',
    name: 'Bootcamps',
    component: BootcampList,
    Layout: DashboardLayout,
  },
  {
    path: '/students/:student/courses/:course/modules/:module/quiz/:quiz/result/:result',
    name: 'Student Answer',
    component: StudentAnswer,
    Layout: DashboardLayout,
  },
  {
    path: '/students/:student/courses/:course/modules/:module/result',
    name: 'Student Results',
    component: StudentResult,
    Layout: DashboardLayout,
  },
  {
    path: '/students/:student/courses/:course/modules/:module/questions',
    name: 'Student Answer List',
    component: StudentAnswerList,
    Layout: DashboardLayout,
  },
  {
    path: '/students/:student/courses/:course/modules',
    name: 'Student Module List',
    component: StudentModuleList,
    Layout: DashboardLayout,
  },
  {
    path: '/users',
    name: 'User List',
    component: UserList,
    Layout: DashboardLayout,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    Layout: FullLayout,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    Layout: DashboardLayout,
  },
];

const Routes: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routeList.map(({ path, component, Layout }) => (
          <RouteWithLayout path={path} layout={Layout} component={component} />
        ))}
        <Redirect from="/" to="/courses" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
