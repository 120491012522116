import { Button, Form, Modal } from 'antd';
import Input from 'components/input';
import { usePackageBenefit, usePackageBenefits } from 'hooks/package.benefit';
import { FC } from 'react';
import { packageBenefitInitialData } from 'store/reducers/package.benefit';

interface PackageBenefitModalFormProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const PackageBanefitFormModal: FC<PackageBenefitModalFormProps> = ({
  visible,
  setVisible,
}) => {
  const { save, setPackageBenefit, packageBenefit, loading } =
    usePackageBenefit();
  const { fetch } = usePackageBenefits();
  const handleOk = async () => {
    const s = await save();
    if (s) {
      fetch();
      setVisible(false);
    }
  };
  const handleCancel = () => {
    setPackageBenefit(packageBenefitInitialData);
    setVisible(false);
  };

  return (
    <Modal
      title="Create Package Benefit"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={640}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Submit
        </Button>,
      ]}
    >
      <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Input
          label={`Benefit`}
          value={packageBenefit.benefit}
          onChange={(v) => setPackageBenefit({ ...packageBenefit, benefit: v })}
        />
      </Form>
    </Modal>
  );
};

export default PackageBanefitFormModal;
