import { UserWithRole } from 'core.types/identity';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setLoading,
  setModalOpen,
  setUser,
  setUserRole,
  setUserAvatar,
} from 'store/reducers/user';

export const useUserStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.user);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setModalOpen: (open: boolean) => dispatch(setModalOpen(open)),
    setUser: (user: UserWithRole) => dispatch(setUser(user)),
    setUserRole: (userRole: string) => dispatch(setUserRole(userRole)),
    setUserAvatar: (userAvatar: Buffer | undefined) =>
      dispatch(setUserAvatar(userAvatar)),
  };

  return { ...states, action };
};
