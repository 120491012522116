import { FC } from 'react';
import { Dropdown, Menu } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import './user.info.scss';
import { CustomAvatar } from './custom.avatar';
import { useAuth, useUser } from 'hooks/auth';
import { useHistory } from 'react-router';

const UserInfo: FC = () => {
  const history = useHistory();
  const user = useUser();
  const { logout } = useAuth();
  const logoutOnClick = async () => {
    const l = await logout();
    if (l) {
      history.push('/login');
    }
  };
  const menu = (
    <Menu>
      <Menu.Item key="profile" onClick={() => history.push('/profile')}>
        <UserOutlined /> Profile
      </Menu.Item>
      <Menu.Item key="signout" onClick={() => logoutOnClick()}>
        <LogoutOutlined /> SignOut
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={`pz-navbar`}>
        <CustomAvatar user={user} />
      </div>
    </Dropdown>
  );
};

export default UserInfo;
