import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Coupon } from '../../../../src/core/coupon';
import { couponTypeLabel } from 'core.types/coupon';
import { useCoupons } from 'hooks/coupon';
import { useEffect } from 'react';

const couponColumns = [
  {
    title: 'Creator',
    dataIndex: 'creator',
  },
  {
    title: 'Code',
    dataIndex: 'code',
  },
  {
    title: 'Quota',
    dataIndex: 'quota',
  },
  {
    title: 'Value',
    dataIndex: 'value',
  },
  {
    title: 'Type',
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    render: (coupon: Coupon) => couponTypeLabel[coupon.type!],
  },
  {
    title: 'Action',
    dataIndex: 'code',
    key: 'code',
    render: (code: string) => (
      <>
        <Link to={`/coupons/${code}/form`}>Detail</Link>
      </>
    ),
  },
];

export const CouponList = () => {
  const { coupons, get } = useCoupons();
  const history = useHistory();

  const addCouponButtonClick = () => {
    history.push(`/coupons/new/form`);
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <Card
      title={`Coupon List`}
      extra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={addCouponButtonClick}
        >
          Add
        </Button>
      }
    >
      <Table columns={couponColumns} dataSource={coupons} />
    </Card>
  );
};

export default CouponList;
