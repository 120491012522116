import { Button, Form, Card, Spin, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useModule } from '../../hooks/module';
import { useQuiz } from 'hooks/quiz';
import { useHistory } from 'react-router';
import { Quiz } from 'core.types/courses';
import Input, { TextArea } from 'components/input';
import NumberInput from 'components/input/number.input';
import { CheckBox } from 'components/input/check.box';
import Datepicker from 'components/input/date.time.picker';
import QuizQuestions from 'pages/questions/question.list';

const QuizForm: FC = () => {
  const { module } = useModule();
  const history = useHistory();
  const { quiz, loading, setQuiz, save } = useQuiz();
  const [enableTime, setEnableTime] = useState(false);
  const quizChange = (v: any, pName: keyof Quiz) => {
    setQuiz({
      ...quiz,
      [pName]: v,
    });
  };

  useEffect(() => {
    if (quiz.open) {
      setEnableTime(true);
    }
  }, [quiz]);

  const _save = async () => {
    const q = await save();
    if (q) {
      message.success('Quiz Saved');
    }
  };

  const cancelButtonClick = () => {
    history.goBack();
  };

  return (
    <>
      <Card title={`Module ${module.name} Quiz`} style={{ marginBottom: 20 }}>
        {loading && <Spin />}
        <Form name="basic" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Input
            label={`Name`}
            value={quiz.name}
            onChange={(v) => quizChange(v, 'name')}
          />
          <TextArea
            label={`Description`}
            value={quiz.description}
            onChange={(v) => quizChange(v, 'description')}
          />
          <NumberInput
            label={`Duration`}
            value={quiz.duration}
            onChange={(v) => quizChange(v, 'duration')}
          />
          <NumberInput
            label={`Min Weight`}
            value={quiz.min_weight}
            onChange={(v) => quizChange(v, 'min_weight')}
          />
          <NumberInput
            label={`Max Attempt`}
            value={quiz.max_attempt}
            onChange={(v) => quizChange(v, 'max_attempt')}
          />
          <CheckBox
            label="Enable Open Time"
            value={enableTime}
            onChange={() => setEnableTime(!enableTime)}
          />
          {enableTime && (
            <Datepicker
              label="Enable Open Time"
              value={quiz.open}
              onChange={(open) => quizChange(open, 'open')}
            />
          )}
          <Button type="primary" loading={loading} onClick={_save}>
            Save
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => cancelButtonClick()}
          >
            Cancel
          </Button>
        </Form>
      </Card>
      {quiz.uuid && <QuizQuestions />}
    </>
  );
};

export default QuizForm;
