import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Benefit } from 'core.types/billing';

export const packageBenefitInitialData: Benefit = {
  benefit: '',
};

export const packageBenefits: Benefit[] = [];

export interface CouponStoreIface {
  loading: boolean;
  packageBenefit: Benefit;
  packageBenefits: Benefit[];
}

const initialState: CouponStoreIface = {
  loading: false,
  packageBenefit: packageBenefitInitialData,
  packageBenefits: packageBenefits,
};

export const slice = createSlice({
  name: 'couponn',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPackageBenefit: (state, action: PayloadAction<Benefit>) => {
      state.packageBenefit = action.payload;
    },
    setPackageBenefits: (state, action: PayloadAction<Benefit[]>) => {
      state.packageBenefits = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setPackageBenefit, setPackageBenefits } =
  slice.actions;

export default slice.reducer;
