import { Button, Modal, Tabs } from 'antd';
import { useUser, useUsers } from 'hooks/users';
import { FC } from 'react';
import { userInititialData } from 'store/reducers/user';
import UserInfoForm from './user.info';
import UserRoleOptions from './user.role';

const { TabPane } = Tabs;

interface UserModalFormProps {
  visible: boolean;
  setVisible: (v: boolean) => void;
}

const UserModalForm: FC<UserModalFormProps> = ({ visible, setVisible }) => {
  const { setUser, save } = useUser();
  const { fetch } = useUsers();
  const handleOk = async () => {
    const s = await save();
    if (s) {
      fetch();
      setVisible(false);
    }
  };
  const handleCancel = () => {
    setUser(userInititialData);
    setVisible(false);
  };

  return (
    <Modal
      title="Create User"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={640}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Account Role" key="1">
          <UserRoleOptions />
        </TabPane>
        <TabPane tab="Account Info" key="2">
          <UserInfoForm />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default UserModalForm;
