import { Button, Card, Modal, Table } from 'antd';
import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import {
  SessionStudent,
  useStudent,
  useStudentParam,
  useStudents,
} from 'hooks/student';
import { useCourse } from 'hooks/course';
import Select from 'components/input/select';

const StudentList: FC = () => {
  const history = useHistory();
  const studentParam = useStudentParam();
  const { course } = useCourse();
  const { students, fetch } = useStudents();
  const { student, session, setSession, saveSession, setStudent } =
    useStudent();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const studentColumns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Grade',
      dataIndex: 'progress',
      key: 'progress',
    },
    {
      title: 'Action',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (_student: SessionStudent) => (
        <>
          <Button
            type="link"
            onClick={() => {
              history.push(
                `/students/${_student.username}/courses/${studentParam.course}/modules`
              );
            }}
          >
            Module
          </Button>
          <Button
            type="link"
            onClick={() => {
              setIsModalVisible(true);
              setStudent(_student);
              setSession(_student.session);
            }}
          >
            Assign To Session
          </Button>
        </>
      ),
    },
  ];

  const handleOk = async () => {
    const s = await saveSession();
    if (s) {
      fetch();
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setSession(undefined);
    setIsModalVisible(false);
  };

  const SessionModal = () => {
    return (
      <Modal
        title="Set Student To Session"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          items={course.sessions ? course.sessions : []}
          value={session || student?.session}
          onChange={(v) => setSession(v)}
          label={`Session`}
        />
      </Modal>
    );
  };

  return (
    <>
      <Card title={`Student List`}>
        <Table columns={studentColumns} dataSource={students} />
      </Card>
      <SessionModal />
    </>
  );
};

export default StudentList;
