import { ManualCertificate } from 'core.types/certificate';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setLoading,
  setModalOwnerOpen,
  setModalCertificateOpen,
  setCertificate,
  setCertificates,
} from 'store/reducers/generated.certificate';
import { Paginated } from '../../../../src/core/core.types';

export const useGeneratedCertificateStore = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.generatedCertificate);
  const action = {
    setLoading: (loading: boolean) => dispatch(setLoading(loading)),
    setModalOwnerOpen: (open: boolean) => dispatch(setModalOwnerOpen(open)),
    setModalCertificateOpen: (open: boolean) =>
      dispatch(setModalCertificateOpen(open)),
    setCertificate: (certificate: ManualCertificate) =>
      dispatch(setCertificate(certificate)),
    setCertificates: (certificates: Paginated<ManualCertificate>) =>
      dispatch(setCertificates(certificates)),
  };

  return { ...states, action };
};
