import { Card, Button } from 'antd';
import Input, { TextArea } from 'components/input';
import { useCareer, useCareerParam } from '../../hooks/career';
import LevelList from './components/career.level.list';

export const CareerForm = () => {
  const { career, loading, setCareer, save } = useCareer();
  const param = useCareerParam();
  return (
    <>
      <Card title={`Career Detail`} loading={loading}>
        <Input
          label="Career Name"
          value={career?.name}
          onChange={(v) => setCareer({ ...career, name: v })}
          readonly={param.career !== 'new'}
        />

        <TextArea
          label="Description"
          value={career?.description}
          onChange={(v) => setCareer({ ...career, description: v })}
          readonly={param.career !== 'new'}
        />
        {param.career === 'new' && (
          <Button type="primary" onClick={() => save()} loading={loading}>
            Save
          </Button>
        )}
      </Card>
      <LevelList />
    </>
  );
};

export default CareerForm;
