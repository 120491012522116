import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Table, Form, Spin, message } from 'antd';
import { Course } from 'core.types/courses';
import { useHomeCourses } from 'hooks/homepage';
import CourseAutocomplete from './course.autocomplete';
export const CourseAtHome = () => {
  const { homeCourses, onDataChange, save, loading } = useHomeCourses();
  const courseColumns = [
    {
      title: 'Course Name',
      width: '80%',
      render: (course: Course, item, index) => {
        return (
          <>
            <Form.Item wrapperCol={{ span: 24 }}>
              <CourseAutocomplete
                value={course.name}
                onChange={(v) => {
                  const c = [...homeCourses.courses];
                  c[index] = v;
                  onDataChange(c, 'courses');
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid: string, item, index) => (
        <>
          <Button
            danger
            onClick={() => {
              const c = [...homeCourses.courses];
              c.splice(index, 1);
              onDataChange(c, 'courses');
            }}
          >
            Remove
          </Button>
        </>
      ),
    },
  ];

  const _save = async () => {
    const c = await save();
    if (c) {
      message.success('Homepage Courses Saved');
    }
  };
  const addCourse = () => {
    const c = [...homeCourses.courses];
    c.push({
      uuid: '',
      name: '',
      description: '',
    } as Course);
    onDataChange(c, 'courses');
  };
  return (
    <Card
      title={`Courses at Homepage`}
      style={{ marginTop: 20 }}
      loading={loading}
      extra={
        <>
          <Button style={{ marginRight: 10 }} onClick={_save}>
            Save
          </Button>
          <Button type="primary" icon={<PlusOutlined />} onClick={addCourse}>
            Add
          </Button>
        </>
      }
    >
      {loading && <Spin />}
      <Table columns={courseColumns} dataSource={homeCourses.courses} />
    </Card>
  );
};

export default CourseAtHome;
