/* eslint-disable camelcase */
import { Generic } from '../core.types';
import { Answer } from '../enrollment';

export enum QuestionType {
  MULTIPLE_CHOISE = 'multiple_choise',
  ESSAY = 'essay',
  FILE = 'file',
}

export interface Options {
  label: string;
  value: string;
  is_answer: boolean;
}

export interface Question extends Generic {
  type: QuestionType;
  question: string;
  weight: number;
  answer?: Answer;
  options?: Options[];
}
